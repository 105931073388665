import { HTMLInputAttributes, Union } from "../../../../types";
import { Chevron } from "../../../icons";
import FilterField, { FilterFieldProps } from "../FilterField";
import { Option, OptionProps } from "./Option";
import { OptionGroup } from "./OptionGroup";
import * as Select from "@radix-ui/react-select";
import { cva, VariantProps } from "class-variance-authority";
import * as React from "react";
import { Fragment, ReactNode, useCallback, useMemo } from "react";
import { cn, useTailwindViewport } from "../../../../lib/utils";
import { Virtuoso } from "react-virtuoso";
import { useDebounceValue } from "../../../../util";
import { getOptionsFiltered } from "../utility";

const useStyles = cva(
  [
    "min-w-[var(--radix-select-trigger-width)]",
    "bg-white",
    "rounded-lg",
    "z-[999] overflow-hidden border border-black",
  ],
  {
    variants: {
      open: {
        true: ["shadow"],
        false: ["hidden"],
      },
    },
  },
);
type StyleProps = VariantProps<typeof useStyles>;

type Props = {
  searchable?: boolean;
  options: Option[] | OptionGroup[];
  renderOption: (option: Option) => ReactNode;
  renderAsStandalone?: (
    filterField: (className?: HTMLInputAttributes["className"]) => JSX.Element,
    options: Option[],
  ) => JSX.Element;
  value: OptionProps["option"]["value"];

  portalContainerId?: string;
  onPointerDownOutside?: () => void;
  virtualize: boolean;
};

export type BaseProps = Union<
  StyleProps &
    Props &
    Pick<Select.SelectContentProps, "position" | "className"> & {
      filterFieldI18n?: true extends Props["searchable"]
        ? FilterFieldProps["i18n"]
        : never;
    } & {
      maxContentHeight?: string;
      children: ReactNode;
    }
>;
const Base = ({
  value,
  searchable,
  options,
  renderOption,
  position = "popper",
  portalContainerId,
  onPointerDownOutside,
  className,
  renderAsStandalone,
  filterFieldI18n,
  maxContentHeight = "18rem",
  children,
  virtualize = true,
  ...props
}: BaseProps) => {
  const [searchValue, setSearchValue] = React.useState<string | undefined>("");
  const debouncedSearchValue = useDebounceValue(searchValue);
  const { isMobile } = useTailwindViewport();

  React.useEffect(() => {
    if (!!value) setSearchValue(undefined);
  }, [value]);

  const contentStyles = useStyles(props);

  const containerRef = React.useRef<HTMLElement>();

  React.useEffect(() => {
    const containerElement = document.getElementById("" + portalContainerId);

    if (!containerElement) return;

    containerRef.current = containerElement;

    return () => (containerRef.current = undefined);
  }, [portalContainerId]);

  const filterFieldRef = React.useRef<HTMLInputElement>(null);

  const handlePointerDownOutside = useCallback(() => {
    filterFieldRef.current?.blur();

    onPointerDownOutside && onPointerDownOutside();
  }, [onPointerDownOutside]);

  const filteredOptions = useMemo(() => {
    let opts: Option[] = [];
    if (options[0] && "options" in options[0]) {
      if (!debouncedSearchValue) {
        return options; // return options grouped.
      }
      opts = options.flatMap((group: OptionGroup) => group.options);
    } else {
      opts = options as Option[];
    }
    return getOptionsFiltered(debouncedSearchValue, opts);
  }, [debouncedSearchValue, options]);

  if (!!renderAsStandalone) {
    return (
      <>
        {renderAsStandalone(
          () => (
            <FilterField
              ref={filterFieldRef}
              value={searchValue}
              onChange={setSearchValue}
              data-testid="options-grouped-filter-input"
              className={cn(
                "rounded-b-none border-x-0 border-b border-t-0 border-black px-4",
                className,
              )}
              i18n={filterFieldI18n!}
            />
          ),
          filteredOptions,
        )}
      </>
    );
  }

  return (
    <Select.Portal
      container={containerRef.current}
      className={"backdrop-blur-none"}
    >
      <Select.Content
        avoidCollisions={false}
        className={contentStyles}
        position={position || "popper"}
        sideOffset={5}
        onPointerDownOutside={handlePointerDownOutside}
        data-testid="select-content"
      >
        {searchable && (
          <FilterField
            ref={filterFieldRef}
            value={searchValue}
            onChange={setSearchValue}
            data-testid="options-grouped-filter-input"
            className={cn(
              "rounded-b-none border-x-0 border-b border-t-0 border-black px-4",
              className,
            )}
            i18n={filterFieldI18n!}
            autofocus
          />
        )}

        <Select.Viewport
          style={{ flex: "none", height: maxContentHeight }}
          tabIndex={0}
          className={cn(
            "min-w-full border-none",
            searchable ? "" : "border-none",
          )}
        >
          {(() => {
            if (filteredOptions.length === 0)
              return (
                <Option
                  key="no-items-available"
                  option={{ label: "No items available", value: "" }}
                  disabled
                />
              );

            return virtualize ? (
              <Virtuoso
                style={{ height: "100%" }}
                data={filteredOptions}
                atTopThreshold={24}
                atBottomThreshold={24}
                itemContent={(index, option) => renderOption(option)}
              />
            ) : (
              <>
                {filteredOptions.map((option, index) => (
                  <Fragment key={index}>{renderOption(option)}</Fragment>
                ))}
              </>
            );
          })()}
        </Select.Viewport>

        <Select.ScrollDownButton
          className={"flex items-center justify-center p-1"}
        >
          <Chevron
            direction={"down"}
            className={"w-2 fill-transparent stroke-ms-night-dark"}
          />
        </Select.ScrollDownButton>
      </Select.Content>
    </Select.Portal>
  );
};

export type OptionsProps = Union<
  Omit<BaseProps, "renderOption"> & {
    options: OptionProps["option"][];
  }
>;
export const Options = ({ options, ...rootProps }: OptionsProps) => {
  const maxContentHeight = useMemo(() => {
    return `${3 * Math.min(6, options?.length)}rem`;
  }, [options]);

  return (
    <Base
      options={options}
      renderOption={(option) => (
        <Option option={option} selected={option.value === rootProps.value} />
      )}
      maxContentHeight={maxContentHeight}
      {...rootProps}
    />
  );
};
Options.displayName = "Options";

type OptionGroup = {
  label?: string;
  options: OptionProps["option"][];
};
export type OptionsGroupedProps = Union<
  Omit<
    BaseProps,
    "renderOptions" | "renderOption" | "options" | "renderAsStandalone"
  > & {
    groups: OptionGroup[];
    renderAsStandalone?: (
      filterField: (
        className?: HTMLInputAttributes["className"],
      ) => JSX.Element,
      options: OptionGroup[] | Option[],
    ) => JSX.Element;
  }
>;

const OptionsGrouped = ({ groups, ...rootProps }: OptionsGroupedProps) => {
  const maxContentHeight = useMemo(() => {
    const totalOptionsCount = groups?.reduce(
      (accumulator: number, currentGroupArr) =>
        accumulator + currentGroupArr?.options?.length,
      0,
    );

    return `${Math.min(18, 3 * totalOptionsCount + 4 * groups?.length)}rem`;
  }, [groups]);

  return (
    <Base
      options={groups}
      maxContentHeight={maxContentHeight}
      renderOption={(option) => {
        if (option.value === undefined)
          return (
            <OptionGroup label={option.label}>
              {(option as unknown as OptionGroup).options.map((opt) => (
                <Option
                  key={opt.value}
                  option={opt}
                  selected={opt.value === rootProps.value}
                />
              ))}
            </OptionGroup>
          );
        return (
          <Option option={option} selected={option.value === rootProps.value} />
        );
      }}
      {...rootProps}
    />
  );
};
OptionsGrouped.displayName = "Options.Grouped";

Options.Grouped = OptionsGrouped;

Options.containerStyles = useStyles;
