import { cn } from "../../lib/utils";
import { useIconStyles, type IconProps } from "./useIconStyles";
import { HTMLAttributes } from "react";

export const CreditCardIcon = ({
  className,
  size,
}: IconProps<HTMLAttributes<HTMLOrSVGImageElement>>) => {
  return (
    <svg
      className={cn(useIconStyles({ size }), className)}
      viewBox="0 0 53 41"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="card 1" clipPath="url(#clip0_3239_11110)">
        <path
          id="Vector"
          d="M13.5128 27.092H21.3928C22.0928 27.092 22.7528 26.812 23.2528 26.322C23.7528 25.832 24.0228 25.162 24.0228 24.462C24.0228 23.762 23.7428 23.102 23.2528 22.602C22.7628 22.112 22.0928 21.832 21.3928 21.832H13.5128C12.8128 21.832 12.1528 22.112 11.6528 22.602C11.1628 23.092 10.8828 23.762 10.8828 24.462C10.8828 25.162 11.1628 25.822 11.6528 26.322C12.1428 26.822 12.8128 27.092 13.5128 27.092Z"
          fill="#208AFC"
        />
        <path
          id="Vector_2"
          d="M50.5828 3.15375C49.1028 1.67375 47.1028 0.84375 45.0128 0.84375H8.26281C6.17281 0.84375 4.16281 1.67375 2.69281 3.15375C1.21281 4.62375 0.382812 6.63375 0.382812 8.72375V32.3538C0.382812 34.4437 1.21281 36.4438 2.69281 37.9238C4.17281 39.4038 6.17281 40.2337 8.26281 40.2337H45.0128C47.1028 40.2337 49.1028 39.4038 50.5828 37.9238C52.0628 36.4438 52.8928 34.4437 52.8928 32.3538V8.72375C52.8928 6.63375 52.0628 4.63375 50.5828 3.15375ZM47.6428 32.3538C47.6428 33.0537 47.3628 33.7137 46.8728 34.2137C46.3828 34.7137 45.7128 34.9837 45.0128 34.9837H8.26281C7.56281 34.9837 6.90281 34.7038 6.40281 34.2137C5.91281 33.7237 5.63281 33.0537 5.63281 32.3538V16.6038H47.6328V32.3538H47.6428ZM47.6428 11.3538H5.63281V8.72375C5.63281 8.02375 5.91281 7.36375 6.40281 6.86375C6.89281 6.36375 7.56281 6.09375 8.26281 6.09375H45.0128C45.7128 6.09375 46.3728 6.37375 46.8728 6.86375C47.3628 7.35375 47.6428 8.02375 47.6428 8.72375V11.3538Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_3239_11110">
          <rect
            width="52.5"
            height="39.38"
            fill="white"
            transform="translate(0.382812 0.84375)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
