import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./locales/en/common.json";
import fr from "./locales/fr/common.json";
import it from "./locales/it/common.json";
import de from "./locales/de/common.json";
import nl from "./locales/nl/common.json";

const translationKeys = { ...en } as const;
export type TranslationKeys = keyof typeof translationKeys;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    defaultNS: "common",
    fallbackLng: "en",
    supportedLngs: ["en", "fr", "it", "de", "nl"],
    debug: import.meta.env.DEV,
    resources: {
      en: { common: en },
      fr: { common: fr },
      it: { common: it },
      de: { common: de },
      nl: { common: nl },
    },
    detection: {
      lookupQuerystring: "locale",
      cookieMinutes: 259200,
    },
  });

export default i18n;
