import { cn } from "../../../lib/utils";
import { HTMLTableSectionAttributes } from "../../../types";

export const TableBody = ({
  className,
  ...props
}: HTMLTableSectionAttributes) => (
  <tbody className={cn("[&_tr:last-child]:border-0", className)} {...props} />
);
TableBody.displayName = "Table.Body";
