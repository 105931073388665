import { cn } from "../../lib/utils";
import { IconProps, useIconStyles } from "./useIconStyles";

export const MaisonSportMonotoneIcon = ({ className, ...props }: IconProps) => {
  return (
    <svg
      className={cn("w-4", useIconStyles(props), className)}
      viewBox="0 0 35 50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M34.9924 20.0805C34.9924 17.4442 34.0835 14.9022 32.4408 12.8618C32.1708 12.5299 31.8856 12.2094 31.5776 11.9039L21.0441 1.45675C19.0857 -0.485583 15.9067 -0.485583 13.9483 1.45675L3.41482 11.9039C1.22827 14.0725 0 17.0143 0 20.0805V45.0216C0 45.0291 0 45.0329 0 45.0404C0.0114081 47.7748 2.24359 49.9887 5.00054 50H5.01956H29.9804C32.7526 50 35 47.771 35 45.0216L34.9924 20.0805Z" />
    </svg>
  );
};
