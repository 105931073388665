export const LoadingSpinner = () => (
  <div
    id="react-loading"
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: "2rem",
      position: "fixed",
      top: "0px",
      left: "0px",
      right: "0px",
      bottom: "0px",
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="128"
      width="128"
      viewBox="0 0 170 170"
      data-name="Layer 1"
    >
      <g transform="translate(1 1)">
        <title>Layer 1</title>
        <g id="svg_12">
          <path
            id="svg_13"
            strokeWidth="0px"
            d="m73,5.025l-35.5,35.5c-7.4,7.4 -11.5,17.4 -11.5,27.8l0,84.8l109.4,-109.4c-0.9,-1.1 -1.9,-2.2 -2.9,-3.3l-35.6,-35.4c-6.6,-6.6 -17.3,-6.6 -23.9,0z"
            className="cls-1"
          />
          <path
            id="svg_14"
            strokeWidth="0px"
            d="m42.9,169.925l84.2,0c9.3,0 16.9,-7.6 16.9,-16.9l0,0l0,-84.2l-101.1,101.1z"
            className="cls-1"
          />
        </g>
      </g>
    </svg>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 38 38"
      stroke="#1e1e1e"
    >
      <g
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(1 1)"
          strokeWidth="2"
        >
          <circle
            strokeOpacity=".25"
            cx="18"
            cy="18"
            r="18"
          />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
  </div>
);
