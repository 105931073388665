import { cn } from "../../lib/utils";
import { useIconStyles, type IconProps } from "./useIconStyles";
import { HTMLAttributes } from "react";

export const PadlockIcon = ({
  className,
  size,
}: IconProps<HTMLAttributes<HTMLOrSVGImageElement>>) => {
  return (
    <svg
      className={cn(useIconStyles({ size }), className)}
      viewBox="0 0 26 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20.9479 11.497V8.42422C20.9479 6.38682 20.1386 4.43287 18.6979 2.9922C17.2572 1.55154 15.3033 0.742188 13.2659 0.742188C11.2285 0.742188 9.27451 1.55154 7.83385 2.9922C6.39319 4.43287 5.58383 6.38682 5.58383 8.42422V11.497C4.36139 11.497 3.18902 11.9827 2.32462 12.847C1.46022 13.7114 0.974609 14.8838 0.974609 16.1063V26.8611C0.974609 28.0836 1.46022 29.2559 2.32462 30.1203C3.18902 30.9847 4.36139 31.4703 5.58383 31.4703H20.9479C22.1703 31.4703 23.3427 30.9847 24.2071 30.1203C25.0715 29.2559 25.5571 28.0836 25.5571 26.8611V16.1063C25.5571 14.8838 25.0715 13.7114 24.2071 12.847C23.3427 11.9827 22.1703 11.497 20.9479 11.497ZM8.65665 8.42422C8.65665 7.20178 9.14226 6.02941 10.0067 5.16501C10.8711 4.30062 12.0434 3.815 13.2659 3.815C14.4883 3.815 15.6607 4.30062 16.5251 5.16501C17.3895 6.02941 17.8751 7.20178 17.8751 8.42422V11.497H8.65665V8.42422ZM22.4843 26.8611C22.4843 27.2686 22.3224 27.6594 22.0343 27.9475C21.7462 28.2357 21.3554 28.3975 20.9479 28.3975H5.58383C5.17635 28.3975 4.78556 28.2357 4.49743 27.9475C4.2093 27.6594 4.04742 27.2686 4.04742 26.8611V16.1063C4.04742 15.6988 4.2093 15.308 4.49743 15.0199C4.78556 14.7317 5.17635 14.5699 5.58383 14.5699H20.9479C21.3554 14.5699 21.7462 14.7317 22.0343 15.0199C22.3224 15.308 22.4843 15.6988 22.4843 16.1063V26.8611Z" />
    </svg>
  );
};
