import { cn } from "../../lib/utils";
import { HTMLDivAttributes } from "../../types";
import { Text, TextProps, useTextStyles } from "./Text";
import { cva, VariantProps } from "class-variance-authority";
import { createContext, FC, HTMLAttributes, useContext } from "react";

const CardContext = createContext<Partial<CardProps> | null>(null);

function useCardContext() {
  const context = useContext(CardContext);

  if (!context) throw new Error("Card.* must be a child of Card component");

  return context;
}

CardContext.displayName = "CardContext";

const useStyles = cva(["border border-black rounded-xl flex items-center"], {
  variants: {
    flex: {
      row: "flex-row",
      col: "flex-col",
      rowReverse: "flex-row-reverse",
      colReverse: "flex-col-reverse",
    },
    justify: {
      start: "justify-start",
      center: "justify-center items-center",
      end: "justify-end",
    },
    extraRounded: {
      true: "rounded-3xl",
    },
    color: {},
  },
  defaultVariants: {
    flex: "col",
  },
});

type StyleProps = VariantProps<typeof useStyles>;

export type CardProps = StyleProps &
  HTMLDivAttributes & {
    backgroundColor?: string;
  };

const Root: FC<CardProps> = ({
  className,
  children,
  flex = "col",
  ...props
}) => {
  return (
    <CardContext.Provider value={{ flex }}>
      <div className={cn(useStyles({ flex, ...props }), className)} {...props}>
        {children}
      </div>
    </CardContext.Provider>
  );
};
Root.displayName = "Card.Root";

const useIconStyles = cva(
  ["flex justify-center box-content items-center p-6"],
  {
    variants: {
      flex: {
        row: "pr-0",
        col: "pb-0",
        rowReverse: "pl-0",
        colReverse: "pt-0",
      },
    },
  },
);

const Icon = ({ className, children, ...props }: HTMLDivAttributes) => {
  const context = useCardContext();

  return (
    <div
      className={cn(
        useIconStyles(context as VariantProps<typeof useIconStyles>),
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};
Icon.displayName = "Card.Icon";

const useHeaderStyles = cva(["flex flex-col p-6 gap-4"], {
  variants: {
    flex: {
      row: "text-left h-full",
      col: "text-center w-full",
      rowReverse: "text-left",
      colReverse: "text-center",
    },
  },
});

const Header = ({ children, className, ...props }: HTMLDivAttributes) => {
  const context = useCardContext();

  return (
    <div
      className={cn(
        useHeaderStyles(context as VariantProps<typeof useHeaderStyles>),
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};
Header.displayName = "Card.Header";

const Title = ({ children, className, ...props }: TextProps) => {
  return (
    <Text
      className={cn(
        // useTextStyles({ bold: true }),
        "whitespace-nowrap",
        className,
      )}
      {...props}
    >
      {children}
    </Text>
  );
};
Title.displayName = "Card.Title";

const Subtitle = ({
  children,
  className,
}: HTMLAttributes<HTMLParagraphElement>) => {
  return <p className={cn(useTextStyles({}), className)}>{children}</p>;
};
Subtitle.displayName = "Card.Subtitle";

const Content = ({ children, className, ...props }: HTMLDivAttributes) => {
  return (
    <div className={cn("w-full p-6 pt-0 only:pt-6", className)} {...props}>
      {children}
    </div>
  );
};
Content.displayName = "Card.Content";

// Root.Icon = Icon;
// Root.Header = Header;
// Root.Title = Title;
// Root.Subtitle = Subtitle;
// Root.Content = Content;

export const Card = {
  Root,
  Icon,
  Header,
  Title,
  Subtitle,
  Content,
};
