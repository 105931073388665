import { cx } from "class-variance-authority";
import { twMerge } from "tailwind-merge";
import moment from "moment";
import { Slot } from "../pages/instructor-profile/types";
import { PrivateBookingPricingBreakdown } from "../pages/instructorProfilePageTyps";
import { Union } from "../types";
import { format } from "date-fns";
export { useTailwindViewport } from "./hooks/useTailwindViewport";

type ClassNameValue = string | undefined;

/**
 * Concatenate and merge tailwind class names
 *
 * @param {...string} inputs
 * @returns
 */
export const cn = (...inputs: ClassNameValue[]) => {
  return twMerge(cx(inputs));
};

export const calcRoundedValue = (
  value: number | null,
  steppingValue: number = 0.5,
  maxValue: number = 5,
): number => {
  if (!value || value < 0) return 0;

  if (value >= maxValue - steppingValue / 2) return 100;

  if (steppingValue < 0 || steppingValue > 1) steppingValue = 0.5;
  if (maxValue < 0) maxValue = 5;

  const rounded = Math.round(value / steppingValue) * steppingValue;

  const decimal = rounded / maxValue;

  return Math.round(decimal * 100);
};

export const throwBadForm = (e?: string) => {
  throw Error(e ?? "Form is v naughty boi");
};

export const convertSlugToReadable = (slug: string): string => {
  // Remove file extension if present
  const fileName = slug.replace(/\.[^/.]+$/, "");

  // Replace hyphens and underscores with spaces
  const slugWithoutHyphens = fileName.replace(/[-_]/g, " ");

  // Insert spaces between numbers and words
  const slugWithSpaces = slugWithoutHyphens.replace(
    /(\d+)([a-zA-Z])/g,
    "$1 $2",
  );

  // Insert spaces before capital letters followed by lowercase letters
  const slugWithCamelCaseSpaces = slugWithSpaces.replace(
    /([a-z])([A-Z])/g,
    "$1 $2",
  );

  // Capitalize the first letter of each word
  const words = slugWithCamelCaseSpaces.split(" ");
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1),
  );

  // Join the words back together
  return capitalizedWords.join(" ");
};

export const debounce = <T extends (...args: any[]) => void>(
  func: T,
  delay: number = 200,
) => {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;

  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

/**
 *
 * @param dateString
 * @returns dateString
 *
 * @description There are issues with the Date string that gets
 * passed from the backend, The issue causes Safari to crash.
 * to fix this we replace all whitespace in the Date string with 'T'
 */
export function fixDateString(dateString: string): string {
  return dateString.toLocaleString().replace(" ", "T");
}

/**
 * @description *`new Date()`* doesn't ignore browser location when converting dates to UTC but *`moment`* does.
 *
 *
 * For the most part we want everything to be UTC in the App so use this instead of *`new Date()`* to keep everything kushty 🤝
 *
 * @usage toUTCDate('2023-01-10')
 * */
export const toUTCDate = (input: moment.MomentInput): Date => {
  const _input = typeof input === "string" ? fixDateString(input) : input;
  return moment(_input).utc().toDate();
};

export default function getDaysBetweenAndIncludingTwoDates(
  start: Date,
  end: Date,
): Date[] {
  for (
    var arr = [], dt = toUTCDate(start);
    dt <= toUTCDate(end);
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
}

export const isSameTime = (slotA: Slot, slotB: Slot): boolean => {
  if (!slotA || !slotB) return false;
  const toTime = (dateString: string) =>
    format(toUTCDate(dateString), "kk:mm:ss");
  return (
    toTime(slotA.start) === toTime(slotB.start) &&
    toTime(slotA.end) === toTime(slotB.end)
  );
};

type PricingItems = Union<
  Omit<
    PrivateBookingPricingBreakdown["calculation"]["items"][number],
    "subItems"
  > & {
    subItems?: PrivateBookingPricingBreakdown["calculation"]["items"][number]["subItems"];
  }
>[];
type PricingFilters = CalculationItems[number]["type"][];
type CalculationItems = PrivateBookingPricingBreakdown["calculation"]["items"];
/**
 * @name recursivelyFlattenAllPricingBreakdownItems
 * @description Takes a pricing breakdown from the `/pricing` endpoint and returns all items within the breakdown.
 * */
export const recursivelyFlattenAllPricingBreakdownItems = (
  items: CalculationItems,
  filters: PricingFilters | undefined = [],
  includeSubItems?: boolean,
): PricingItems => {
  if (!items) return [];

  const flattenedItems: PricingItems = [];

  function flatten({ subItems, ...item }: (typeof items)[number]) {
    const _item = item;
    if (includeSubItems) {
      _item.subItems = subItems;
    }
    flattenedItems.push(_item);
    subItems?.forEach((subItem) => {
      flatten(subItem);
    });
  }

  items.forEach((item) => {
    flatten(item);
  });

  return filterPricingBreakdownItems(flattenedItems, filters);
};

/**
 * @name filterPricingBreakdownItems
 * @description Filters the output from recursivelyFlattenAllPricingBreakdownItems
 * */
export const filterPricingBreakdownItems = (
  items: PricingItems,
  filters: PricingFilters,
): ReturnType<typeof recursivelyFlattenAllPricingBreakdownItems> => {
  if (!filters.length) return items;
  return items.filter((item) => filters.includes(item.type));
};

interface FormatOptions extends Intl.NumberFormatOptions {
  isNotPennies?: boolean;
}
interface UseCurrencyFormatterReturn {
  format: (amount: number, options: FormatOptions) => string;
  penniesToPounds: (pennies: number) => number;
}
export function useCurrencyFormatter(): UseCurrencyFormatterReturn {
  function penniesToPounds(pennies: number) {
    return pennies / 100;
  }

  function format(amount: number, options: FormatOptions = {}): string {
    const { isNotPennies, ...opts } = options;
    opts.currency = opts.currency ?? "EUR";

    const amt = isNotPennies ? amount : penniesToPounds(amount);

    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      ...opts,
    }).format(amt);
  }

  return {
    format,
    penniesToPounds,
  };
}

/**
 * @name formatDateForBackend
 * @description The backend, when being passed date values; expects the format to be specifically yyyy-MM-dd
 * */
export const formatDateForBackend = (input: Date): string =>
  format(input, "yyyy-MM-dd");

/**
 * @name formatDatetimeForBackend
 * @description The backend, when being passed datetime values; expects the format to be specifically yyyy-MM-dd kk:mm:ss
 * */
export const formatDatetimeForBackend = (input: Date): string =>
  format(input, "yyyy-MM-dd kk:mm:ss");

/**
 * @name formatTimeForUser
 * @description In the spirit of all users experiencing times the same, we will use this function to format the times in a way we can all agree on.
 * */
export const formatTimeForUser = (input?: Date): string => {
  if (!input) return "No time data";
  return format(input, "kk:mm");
};

/**
 * @name formatTimeRangeForUser
 * @description In the spirit of all users experiencing time ranges the same, we will use this function to format time ranges in a way we can all agree on.
 * */
export const formatTimeRangeForUser = (start?: Date, end?: Date): string => {
  return `${formatTimeForUser(start)} - ${formatTimeForUser(end)}`;
};

/**
 * @name formatDateRangeForUser
 * @description In the spirit of all users experiencing date ranges the same, we will use this function to format date ranges in a way we can all agree on.
 * */
export const formatDateRangeForUser = (
  start?: Date,
  end?: Date,
  noDateFallback?: string,
): string => {
  return `${formatDateForUser(start, noDateFallback)} - ${formatDateForUser(
    end,
    noDateFallback,
  )}`;
};

/**
 * @name formatDateForUser
 * @description In the spirit of all users experiencing dates the same, we will use this function to format the dates in a way we can all agree on.
 * */
export const formatDateForUser = (
  input?: Date,
  noDateFallback?: string,
): string => {
  if (!input) return noDateFallback ?? "No date data";
  return format(input, "dd/MM/yyyy");
};

/**
 * @name formatDatetimeForUser
 * @description In the spirit of all users experiencing datetimes the same, we will use this function to format the datetimes in a way we can all agree on.
 * */
export const formatDatetimeForUser = (input?: Date): string => {
  return `${formatTimeForUser(input)} ${formatDateForUser(input)}`;
};

/**
 * @name handleImageSrcIfFromServer
 * @description some image src sent from the backend come are sent as a path to a bucket without the prepended URL.
 * We check if the src is a path and prepend the backend url to it.
 * */
export const handleImageSrcFromServer = (
  src: HTMLImageElement["src"],
): string => {
  if (src?.startsWith("/") && process?.env?.NEXT_PUBLIC_BACKEND_URL)
    return process.env.NEXT_PUBLIC_BACKEND_URL + src;

  return src;
};
