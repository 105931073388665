import { cn } from "../../lib/utils";
import { cva, VariantProps } from "class-variance-authority";
import { createElement, HTMLAttributes, ReactNode } from "react";

export const useTextStyles = cva(["m-0"], {
  variants: {
    variant: {
      title1: [
        "md:text-[4.053rem] text-[3.04rem]",
        "md:leading-[120%] leading-[127%]",
        "tracking-[0.84px] sm:tracking-[0.11rem]",
        "font-figtree",
        "font-black",
      ],
      title2: [
        "md:text-[2.631rem] text-[1.973rem]",
        "leading-[36px]",
        "font-figtree",
        "font-extrabold",
      ],
      title3: [
        "md:text-[1.684rem] text-[1.263rem]",
        "leading-[30.966px] sm:leading-normal",
        "tracking-[0.75px]",
        "font-figtree",
        "font-extrabold",
      ],

      sub1: [
        "md:text-[1.316rem] text-base",
        "leading-[24px]",
        "font-figtree",
        "font-medium",
      ],

      copy: [
        "md:leading-[1.5789rem] leading-[1.184rem]",
        "font-ll-brown font-light",
      ],

      cabron: [
        // "text-base",
        // "placeholder:text-base",
        "leading-[18px] sm:leading-[22px]",
        "font-figtree",
      ],
    },

    underlined: {
      true: "underline",
    },
    bold: {
      true: "!font-figtree font-extrabold",
    },

    disabled: {
      true: "cursor-not-allowed opacity-75 text-ms-grey-dark",
    },
  },
  compoundVariants: [
    {
      variant: "sub1",
      underlined: true,
      className: "leading-[25px]",
    },
    {
      variant: "sub1",
      bold: true,
      className: "text-[1.210rem]",
    },
  ],
  defaultVariants: {
    variant: "copy",
  },
});

type Props<T> = VariantProps<typeof useTextStyles> &
  HTMLAttributes<T> & {
    className?: string;
    children: ReactNode;

    as?: keyof HTMLElementTagNameMap;
  };

export type TextProps = Omit<Props<HTMLDivElement>, "type">;
export const Text = ({
  className = "",
  children,
  as,
  variant,
  underlined,
  bold,
  disabled,
  ...props
}: Omit<TextProps, "type">) => {
  let el: TextProps["as"] = as || "div";
  if (!as) {
    switch (variant) {
      case "title1":
        el = "h1";
        break;
      case "title2":
        el = "h2";
        break;
      case "title3":
        el = "h3";
        break;
      case "sub1":
      case "cabron":
        el = "span";
        break;
    }
  }

  return createElement(
    el,
    {
      className: cn(
        useTextStyles({ variant, underlined, bold, disabled }),
        className,
      ),
      ...props,
    },
    children,
  );
};
Text.displayName = "Text";

export type TextLabelProps = Props<HTMLLabelElement>;
const Label = ({
  className,
  children,
  underlined,
  bold,
  disabled,
  ...props
}: TextLabelProps) => {
  return (
    <label
      className={cn(
        useTextStyles({ variant: "copy", underlined, bold, disabled }),
        "flex flex-col gap-2 text-left text-sm font-bold",
        className,
      )}
      {...props}
    >
      {children}
    </label>
  );
};
Label.displayName = "Label";
Text.Label = Label;
