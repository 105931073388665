import { createApiClient } from "./lib/output";
import {
  QueryClient,
  QueryClientConfig,
  QueryClientProvider,
} from "@tanstack/react-query";

type CreateApiParams = Parameters<typeof createApiClient>;

export {
  endpointDefinitions,
  type EndpointDefinitions,
  type EndpointDefinition,
  type EndpointPath,
  type EndpointMethod,
} from "./lib/output";

export type ZodiosInstance = ReturnType<typeof createApiClient>["zodios"];
export type ZodiosHooksInstance = ReturnType<typeof createApiClient>["api"];

export function createApi(
  baseUrl: CreateApiParams[0],
  options?: CreateApiParams[1]
) {
  const { api, zodios } = createApiClient(baseUrl, {
    axiosConfig: {
      withCredentials: true,
      ...options?.axiosConfig,
    },
    validate: false,
    transform: "request",
    ...options,
  });

  return { api, zodios };
}

export { createUseAuthHook } from "./lib/hooks/createUseAuth";

export function createQueryClientProvider(options?: QueryClientConfig) {
  const queryClient = new QueryClient(options);

  return {
    queryClient,
    QueryClientProvider,
  };
}
