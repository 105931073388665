import { HTMLAttributes } from "react";

const Pondering = ({
  className,
}: Pick<HTMLAttributes<HTMLOrSVGImageElement>, "className">) => {
  return (
    <svg
      className={className}
      viewBox="0 0 683 851"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5100_73952)">
        <path
          d="M359.207 733.987C520.198 733.987 650.707 603.478 650.707 442.487C650.707 281.496 520.198 150.987 359.207 150.987C198.216 150.987 67.707 281.496 67.707 442.487C67.707 603.478 198.216 733.987 359.207 733.987Z"
          fill="#208AFC"
        />
        <mask
          id="mask0_5100_73952"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="67"
          y="150"
          width="584"
          height="584"
        >
          <path
            d="M359.207 733.987C520.198 733.987 650.707 603.478 650.707 442.487C650.707 281.496 520.198 150.987 359.207 150.987C198.216 150.987 67.707 281.496 67.707 442.487C67.707 603.478 198.216 733.987 359.207 733.987Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_5100_73952)">
          <path
            d="M350.697 371.767C350.697 371.767 148.997 407.357 130.227 737.927H234.527C234.527 737.927 267.637 544.227 296.887 501.737C296.887 501.737 246.117 700.957 245.017 737.927H489.487V698.197C489.487 698.197 513.217 700.957 525.907 692.127C538.597 683.297 559.017 694.337 553.497 576.237C547.977 458.137 537.997 419.507 466.007 386.947C394.017 354.387 350.697 371.767 350.697 371.767Z"
            fill="#A1D9D5"
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M385.468 343.518C385.468 343.518 379.068 368.988 364.168 374.228C364.168 374.228 382.018 378.048 414.388 379.998C422.158 380.468 412.878 357.388 415.728 342.228C415.728 342.228 402.018 346.178 385.468 343.518Z"
          fill="white"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M351.496 371.478C351.496 371.478 403.536 384.188 427.676 378.048"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M364.856 333.087C364.856 333.087 316.206 346.677 277.576 273.407C238.946 200.137 302.136 137.777 313.166 131.157C324.196 124.537 349.866 115.427 380.766 125.637C380.766 125.637 400.906 95.8374 424.916 104.387C448.926 112.937 499.136 145.777 535.286 126.737C535.286 126.737 557.916 173.367 491.136 210.897C491.136 210.897 506.036 304.157 447.266 321.817C388.496 339.477 364.856 333.077 364.856 333.077V333.087Z"
          fill="#231F20"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M349.597 221.487C349.597 221.487 308.758 366.497 424.648 341.267C480.658 329.617 463.557 214.277 463.557 214.277C463.557 214.277 443.688 217.607 407.817 199.857C407.817 199.857 389.327 221.487 349.597 221.487Z"
          fill="white"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M395.677 225.777C395.677 225.777 377.737 217.947 372.227 233.227"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M434.656 221.297C434.656 221.297 439.526 211.047 450.106 218.447"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M424.367 236.288C424.367 236.288 460.237 307.198 426.297 300.578"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M396.856 261.393C399.13 261.157 400.686 258.199 400.331 254.788C399.976 251.377 397.845 248.803 395.571 249.04C393.297 249.276 391.741 252.234 392.096 255.645C392.451 259.056 394.582 261.63 396.856 261.393Z"
          fill="#231F20"
        />
        <path
          d="M446.29 256.255C448.564 256.019 450.12 253.062 449.765 249.65C449.41 246.239 447.279 243.666 445.004 243.902C442.73 244.139 441.174 247.096 441.529 250.507C441.884 253.918 444.016 256.492 446.29 256.255Z"
          fill="#231F20"
        />
        <path
          d="M404.458 314.098C406.037 313.526 406.727 311.437 406 309.433C405.274 307.429 403.405 306.268 401.827 306.84C400.248 307.413 399.558 309.501 400.285 311.506C401.011 313.51 402.88 314.67 404.458 314.098Z"
          fill="#231F20"
        />
        <path
          d="M441.477 431.308C441.477 431.308 438.027 433.718 436.237 435.508C434.447 437.298 442.477 435.508 442.477 435.508L441.477 431.308Z"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M492.388 421.438C492.248 420.338 486.598 420.428 486.598 420.428L487.008 424.468L492.388 421.428V421.438Z"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M543.568 567.948C543.568 567.948 538.598 442.678 502.458 451.508C466.308 460.338 424.558 458.678 417.568 474.688C410.578 490.688 416.098 624.788 427.958 649.078C439.818 673.358 443.688 698.198 489.488 698.198"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M453.35 342.247C453.35 342.247 422.45 321.278 420.24 316.308C418.03 311.338 422.72 304.168 436.8 310.518C450.87 316.868 475.15 333.968 475.98 345.838C475.98 345.838 484.26 307.177 492.54 308.027C500.82 308.867 503.58 319.627 484.81 367.917C482.05 377.577 487.02 424.488 487.02 424.488L488.95 442.697L448.94 447.937C448.94 447.937 445.58 451.247 440.91 428.627C436.24 405.997 430.51 388.898 419.72 384.208C408.93 379.518 408.1 362.688 418.58 357.447C418.58 357.447 413.61 352.208 427.96 345.588C427.96 345.588 429.14 334.797 438.35 334.947C447.56 335.097 453.35 342.277 453.35 342.277V342.247Z"
          fill="white"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M427.957 345.558C427.957 345.558 434.857 355.768 439.267 358.248"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M418.578 357.417C418.578 357.417 423.818 366.797 430.718 369.837"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M436.238 435.507L439.278 464.207C439.278 464.207 477.078 482.967 502.468 451.517L492.538 422.547C492.538 422.547 478.738 434.687 436.248 435.517L436.238 435.507Z"
          fill="#2A439B"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M431.058 321.407C431.058 321.407 422.328 315.617 424.368 313.747C426.398 311.887 433.338 311.887 436.228 314.677L431.058 321.407Z"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M496.356 311.478C496.356 311.478 490.256 319.238 489.836 322.858C489.836 322.858 493.876 326.268 497.386 325.958C497.386 325.958 501.606 314.688 496.356 311.468V311.478Z"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M282.848 166.057C294.518 146.717 308.508 133.957 313.178 131.157C324.218 124.537 349.878 115.427 380.778 125.637C380.778 125.637 400.918 95.8374 424.928 104.387C433.658 107.497 445.848 113.817 459.568 119.687"
          fill="#231F20"
        />
        <path
          d="M282.848 166.057C294.518 146.717 308.508 133.957 313.178 131.157C324.218 124.537 349.878 115.427 380.778 125.637C380.778 125.637 400.918 95.8374 424.928 104.387C433.658 107.497 445.848 113.817 459.568 119.687"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M249.298 116.828C241.528 119.648 234.098 116.738 231.388 109.258C228.998 102.658 232.028 97.3776 237.298 92.8276C243.818 87.1676 245.248 84.4476 244.088 81.2476C242.858 77.8476 239.508 77.4176 236.018 78.6776C231.938 80.1576 229.868 83.5376 230.118 88.1776L218.898 90.2675C217.398 81.5775 221.478 72.5175 232.058 68.6875C242.938 64.7475 252.208 68.1975 255.588 77.5175C258.408 85.2875 254.578 90.1875 247.328 96.5575C241.838 101.408 240.448 103.338 241.398 105.958C242.168 108.098 244.188 108.798 246.128 108.088C248.848 107.098 250.058 104.688 250.048 101.618L259.758 101.728C259.838 108.618 256.298 114.298 249.298 116.838V116.828ZM251.168 121.978C255.638 120.358 260.568 122.748 262.188 127.218C263.808 131.688 261.478 136.488 257.018 138.098C252.558 139.708 247.688 137.528 246.068 133.058C244.448 128.588 246.708 123.598 251.168 121.978Z"
          fill="#231F20"
        />
        <path
          d="M543.767 296.707C536.757 292.327 534.477 284.687 538.687 277.937C542.407 271.977 548.437 271.117 555.267 272.467C563.737 274.107 566.767 273.567 568.567 270.677C570.487 267.607 568.767 264.707 565.617 262.737C561.937 260.437 557.997 260.897 554.507 263.947L545.947 256.397C551.857 249.867 561.507 247.487 571.057 253.457C580.867 259.587 583.867 269.017 578.607 277.427C574.227 284.437 568.007 284.447 558.527 282.657C551.327 281.317 548.947 281.417 547.467 283.787C546.257 285.717 546.947 287.727 548.707 288.827C551.157 290.357 553.807 289.827 556.217 287.917L562.117 295.627C556.737 299.937 550.087 300.647 543.777 296.707H543.767ZM540.867 301.347C544.897 303.867 546.057 309.217 543.537 313.247C541.017 317.277 535.807 318.407 531.777 315.887C527.747 313.367 526.477 308.187 528.997 304.157C531.517 300.127 536.837 298.827 540.867 301.347Z"
          fill="#231F20"
        />
        <path
          d="M415.149 52.8175C414.619 38.6775 414.519 22.8975 425.689 12.4875C430.519 7.98746 436.829 4.97746 443.419 4.28746C450.379 3.56746 457.959 5.37746 462.879 10.5875C467.469 15.4475 469.429 23.0475 465.909 29.0575C464.319 31.7775 461.779 34.0175 458.779 35.0575C455.579 36.1675 451.469 35.9375 449.099 33.2375C444.979 28.5375 448.819 20.9575 452.069 16.9475C455.779 12.3675 460.519 8.70746 465.789 6.08746C476.369 0.837459 489.129 -0.192541 500.269 3.90746C501.719 4.44746 503.139 5.07746 504.509 5.77746C505.219 6.14746 505.859 5.06746 505.139 4.69746C493.549 -1.24254 479.549 -1.19254 467.649 3.85746C461.729 6.36746 456.309 10.1375 452.069 15.0075C448.319 19.3175 444.499 25.9275 446.809 31.7975C449.419 38.4575 458.259 37.8075 463.059 34.1275C468.579 29.8975 470.299 22.6575 467.869 16.2375C465.229 9.27746 458.869 4.73746 451.679 3.37746C444.489 2.01746 437.169 3.52746 430.939 7.08746C424.729 10.6375 419.859 16.1375 417.109 22.7475C413.969 30.3075 413.579 38.6775 413.719 46.7575C413.759 48.7775 413.829 50.7975 413.899 52.8175C413.929 53.6175 415.179 53.6275 415.149 52.8175Z"
          fill="#231F20"
        />
        <path
          d="M210.498 387.457C221.028 388.897 233.088 385.687 237.898 375.297C239.878 371.007 240.118 366.177 238.138 361.847C236.288 357.787 232.568 354.387 228.088 353.637C225.978 353.287 223.478 353.537 221.908 355.137C220.338 356.737 220.198 359.367 220.618 361.497C221.568 366.207 225.718 369.757 229.898 371.667C239.038 375.857 250.538 374.367 258.698 368.667C268.098 362.107 272.798 350.097 270.788 338.877C270.648 338.087 269.438 338.417 269.578 339.207C271.178 348.117 268.278 357.667 261.998 364.207C255.638 370.817 245.958 373.877 236.928 372.437C232.468 371.727 228.058 369.967 224.888 366.667C223.408 365.137 222.248 363.257 221.828 361.157C221.338 358.687 221.858 355.807 224.568 354.997C228.848 353.717 233.448 356.797 235.778 360.187C238.238 363.777 238.908 368.197 237.688 372.367C236.218 377.367 232.468 381.487 227.868 383.857C222.668 386.537 216.568 387.047 210.838 386.257C210.048 386.147 209.708 387.357 210.508 387.467L210.498 387.457Z"
          fill="#231F20"
        />
        <path
          d="M159.537 236.457C151.917 244.497 145.357 254.087 143.177 265.127C142.127 270.437 142.077 275.967 143.337 281.247C144.597 286.527 147.217 291.167 150.657 295.267C154.587 299.957 159.397 303.817 164.157 307.617C164.777 308.117 165.667 307.237 165.037 306.737C157.057 300.367 148.527 293.457 145.237 283.387C141.777 272.797 144.227 261.247 149.557 251.727C152.507 246.457 156.267 241.707 160.417 237.337C160.967 236.757 160.087 235.867 159.537 236.457Z"
          fill="#231F20"
        />
        <path
          d="M135.098 243.997C130.958 250.727 124.498 255.697 120.498 262.527C116.088 270.057 116.698 278.587 119.928 286.447C121.898 291.237 124.578 295.677 127.428 299.987C127.868 300.657 128.958 300.027 128.508 299.357C123.758 292.187 118.898 284.097 118.518 275.277C118.338 271.057 119.378 266.977 121.488 263.317C123.378 260.047 125.888 257.197 128.398 254.397C131.178 251.287 133.988 248.187 136.178 244.627C136.598 243.937 135.518 243.307 135.098 243.997Z"
          fill="#231F20"
        />
        <path
          d="M617.159 96.3173C629.919 103.197 638.779 115.737 642.669 129.547C647.059 145.127 644.739 161.837 638.599 176.617C636.769 181.017 634.589 185.277 632.129 189.357C629.669 193.437 627.169 197.167 623.509 200.027C622.879 200.517 623.769 201.397 624.389 200.907C627.659 198.347 630.099 194.967 632.289 191.477C634.479 187.987 636.629 184.037 638.419 180.117C641.939 172.427 644.439 164.267 645.559 155.877C647.599 140.507 644.549 124.607 635.759 111.697C631.139 104.897 625.039 99.1573 617.789 95.2473C617.079 94.8673 616.449 95.9473 617.159 96.3273V96.3173Z"
          fill="#231F20"
        />
        <path
          d="M656.727 92.9174C671.907 104.917 679.027 125.577 675.747 144.507C674.817 149.897 673.037 155.097 670.407 159.897C670.017 160.607 671.097 161.237 671.487 160.527C680.977 143.157 679.397 120.857 668.747 104.357C665.727 99.6774 661.977 95.4874 657.607 92.0374C656.987 91.5474 656.097 92.4174 656.727 92.9174Z"
          fill="#231F20"
        />
      </g>
      <defs>
        <clipPath id="clip0_5100_73952">
          <rect
            width="682.12"
            height="850.66"
            fill="white"
            transform="translate(0.507812 0.16748)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Pondering;
