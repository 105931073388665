import { HTMLAttributes } from "react";

const DesktopMan = ({
  className,
}: Pick<HTMLAttributes<HTMLOrSVGImageElement>, "className">) => {
  return (
    <svg
      className={className}
      viewBox="0 0 532 517"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="266" cy="455.627" rx="265.328" ry="60.6855" fill="#D2E8FE" />
      <g clip-path="url(#clip0_9198_10290)">
        <mask
          id="mask0_9198_10290"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="76"
          y="0"
          width="371"
          height="483"
        >
          <path
            d="M446.162 0.913086H76.3037V482.74H446.162V0.913086Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_9198_10290)">
          <path
            d="M181.958 362.043V405.006L272.389 423.739L338.148 407.728L333.39 350.894L181.958 362.043Z"
            fill="white"
            stroke="#1E1E1E"
            stroke-width="1.99394"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M229.086 155.682C229.086 155.682 230.842 168.973 225.931 177.477L225.092 188.015L258.023 186.46C258.023 186.46 250.878 167.318 249.72 155.392C248.562 143.467 229.083 155.682 229.083 155.682H229.086Z"
            fill="white"
            stroke="#1E1E1E"
            stroke-width="2.40788"
            stroke-miterlimit="10"
          />
          <path
            d="M202.785 104.852C202.785 104.852 204.464 149.906 232.169 156.624C259.872 163.339 272.186 132.838 271.907 98.9759L270.605 66.7943L202.785 55.8804V104.852Z"
            fill="white"
            stroke="#1E1E1E"
            stroke-width="2.40788"
            stroke-miterlimit="10"
          />
          <path
            d="M227.84 173.006C227.84 173.006 187.325 179.004 159.426 195.391C131.527 211.777 126.655 249.641 127.54 266.912C128.424 284.185 137.282 323.322 161.418 338.075C185.553 352.829 353.621 344.636 353.621 344.636C353.621 344.636 371.336 312.309 371.999 280.421C372.664 248.534 366.574 176.975 253.588 173.009C253.588 173.009 260.397 182.769 239.141 183.213C217.884 183.657 227.84 173.009 227.84 173.009V173.006Z"
            fill="white"
            stroke="#1E1E1E"
            stroke-width="2.40788"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M187.767 234.807C187.767 234.807 187.323 249.421 187.767 250.75"
            stroke="#1E1E1E"
            stroke-width="1.99394"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M306.012 228.607C306.012 228.607 307.563 241.894 307.563 245.657"
            stroke="#1E1E1E"
            stroke-width="1.99394"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M161.418 338.075C161.418 338.075 119.738 330.042 106.213 334.939C92.6881 339.836 77.2993 351.029 77.2993 366.883C77.2993 382.737 85.2264 414.458 126.266 432.875C167.303 451.291 241.22 475.075 254.976 472.277C268.734 469.48 271.618 460.852 272.392 445.464C273.165 430.075 366.94 443.6 366.94 443.6C366.94 443.6 386.72 440.802 398.61 431.592C410.501 422.383 453.172 399.765 443.846 354.53C434.52 309.294 357.574 336.563 357.574 336.563L161.421 338.081L161.418 338.075Z"
            fill="white"
            stroke="#1E1E1E"
            stroke-width="1.99394"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M150.05 364.552C150.05 364.552 194.818 375.977 206.478 381.572C218.136 387.166 264.145 410.32 268.269 414.449C272.392 418.577 277.952 424.871 272.392 445.462"
            stroke="#1E1E1E"
            stroke-width="1.99394"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M204.744 124.827C204.744 124.827 190.621 126.269 196.602 69.1533L208.68 72.8653L204.744 102.701L204.533 118.277L204.744 124.829V124.827Z"
            fill="#1E1E1E"
            stroke="#1E1E1E"
            stroke-width="1.99394"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M263.083 71.5343C263.083 71.5343 225.412 85.0509 187.408 64.7761C149.404 44.5012 178.766 0.293559 198.821 1.95472C218.875 3.61589 230.255 27.8516 239.085 33.9119C248.562 40.4159 256.487 31.6771 277.324 43.8599C293.032 53.0424 295.086 78.9934 285.018 105.141C283.447 109.224 280.844 112.82 277.487 115.596C276.621 116.313 275.653 116.894 274.632 117.36L271.908 102.729C271.908 102.729 262.417 90.259 263.083 71.5343Z"
            fill="#1E1E1E"
            stroke="#1E1E1E"
            stroke-width="1.99394"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M271.907 102.728C271.907 102.728 283.947 101.666 283.189 111.878C282.432 122.092 271.42 117.011 271.42 117.011L271.91 104.902L271.905 102.728H271.907Z"
            fill="white"
            stroke="#1E1E1E"
            stroke-width="2.40788"
            stroke-miterlimit="10"
          />
          <path
            d="M204.744 102.702C204.744 102.702 194.163 102.325 194.163 109.892C194.163 117.46 197.293 119.754 204.744 119.3C204.167 110.428 204.744 102.702 204.744 102.702Z"
            fill="white"
            stroke="#1E1E1E"
            stroke-width="2.40788"
            stroke-miterlimit="10"
          />
          <path
            d="M296.387 460.929L301.373 463.819L308.096 451.26L306.982 430.89L296.281 432.878C292.827 432.806 275.192 430.306 275.192 430.306L272.532 444.702C272.438 445.211 272.362 445.721 272.3 446.236L271.666 451.592C271.572 452.396 271.445 453.194 271.291 453.987L270 460.526C270 460.526 288.16 465.153 296.008 460.967"
            fill="white"
          />
          <path
            d="M296.387 460.929L301.373 463.819L308.096 451.26L306.982 430.89L296.281 432.878C292.827 432.806 275.192 430.306 275.192 430.306L272.532 444.702C272.438 445.211 272.362 445.721 272.3 446.236L271.666 451.592C271.572 452.396 271.445 453.194 271.291 453.987L270 460.526C270 460.526 288.16 465.153 296.008 460.967"
            stroke="#1E1E1E"
            stroke-width="1.99394"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M286.652 431.591C286.652 431.591 287.312 429.791 293.413 428.615C299.514 427.437 343.676 420.089 348.599 420.825C353.523 421.561 366.163 422.662 369.837 433.097C373.511 443.532 380.272 477.334 366.383 481.154C352.492 484.975 340.956 468.956 328.833 468.223C316.71 467.489 300.791 471.234 291.224 462.416C291.224 462.416 310.365 460.368 304.032 436.301C303.758 435.265 303.274 434.288 302.56 433.487C296.289 426.444 286.655 431.591 286.655 431.591H286.652Z"
            fill="white"
            stroke="#1E1E1E"
            stroke-width="1.99394"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M332.875 436.991C332.875 436.991 303.997 434.932 299.219 436.477C294.441 438.022 293.635 446.325 294.149 449.117C294.663 451.91 293.489 461.903 335.007 457.421C335.007 457.421 335.448 442.283 332.875 436.991Z"
            stroke="#1E1E1E"
            stroke-width="1.99394"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M331.479 433.757C331.479 433.757 332.926 435.685 333.99 440.658C335.069 445.698 336.121 453.98 334.344 461.761"
            stroke="#1E1E1E"
            stroke-width="1.99394"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M323.079 432.846C323.079 432.846 326.996 437.897 327.426 447.231C327.856 456.564 325.919 462.503 325.919 462.503"
            stroke="#1E1E1E"
            stroke-width="1.99394"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M315.761 432.632C315.761 432.632 319.248 443.394 319.248 450.107C319.248 456.819 318.731 462.503 318.731 462.503"
            stroke="#1E1E1E"
            stroke-width="1.99394"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M309.175 432.632C309.175 432.632 315.073 455.358 306.98 460.222"
            stroke="#1E1E1E"
            stroke-width="1.99394"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M333.389 422.265C333.389 422.265 344.81 423.098 352.569 427.644C360.328 432.189 365.649 435.181 366.424 445.821C367.2 456.463 367.755 463.667 362.987 480.96"
            stroke="#1E1E1E"
            stroke-width="1.99394"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M375.366 350.894C375.366 350.894 342.37 360.804 312.517 384.493C282.665 408.182 278.312 417.005 272.389 418.576"
            stroke="#1E1E1E"
            stroke-width="1.99394"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M220.094 97.6662C220.094 97.6662 219.318 99.1216 219.429 100.453C219.54 101.784 221.423 104.392 222.532 102.699C223.642 101.005 223.531 94.214 220.094 97.6662Z"
            fill="#1E1E1E"
          />
          <path
            d="M247.238 97.6661C247.238 97.6661 246.424 100.407 246.646 101.657C246.868 102.907 250.009 104.551 250.449 101.226C250.891 97.9016 249.011 93.4372 247.236 97.6661H247.238Z"
            fill="#1E1E1E"
          />
          <path
            d="M214.473 91.0649C214.641 91.2894 214.962 91.3192 215.165 91.127C215.947 90.3831 217.598 88.9681 218.634 88.5271C219.429 88.1889 221.256 88.3594 222.075 88.3837C222.454 88.3946 222.692 87.9698 222.473 87.6586C222.067 87.077 221.201 86.4249 219.416 86.7848C216.423 87.3881 214.251 89.1954 214.191 90.1017C214.161 90.5291 214.31 90.8484 214.473 91.0676V91.0649Z"
            fill="#1E1E1E"
          />
          <path
            d="M245.543 88.494C245.632 88.5995 245.775 88.6373 245.908 88.6022C246.66 88.3993 249.568 87.7148 251.392 88.6535C253.502 89.7385 254.709 90.7044 255.009 90.0415C255.31 89.3786 252.583 87.0979 250.58 86.8328C248.578 86.5677 246.446 86.9059 245.724 87.5687C245.264 87.9907 245.388 88.3153 245.54 88.494H245.543Z"
            fill="#1E1E1E"
          />
          <path
            d="M234.068 100.296C234.068 100.296 232.613 113.856 233.007 121.651C233.402 129.445 234.403 134.117 240.591 134.058C241.197 134.058 241.621 133.116 240.075 132.876C238.527 132.635 234.311 130.389 234.522 118.65C234.888 101.784 234.825 100.51 234.825 100.51C234.825 100.51 234.645 97.8102 234.068 100.299V100.296Z"
            fill="#1E1E1E"
          />
          <path
            d="M238.979 143.094C238.979 143.094 243.733 143.012 247.147 139.241C249.111 137.071 249.354 134.596 249.387 134.06C249.39 134.003 249.39 133.947 249.387 133.889C249.376 133.587 249.381 132.309 250.196 132.512C251.116 132.743 251.633 135.102 251.002 136.712C250.369 138.321 248.127 143.613 243.755 144.476C239.383 145.339 238.003 144.706 237.946 143.96C237.889 143.213 238.981 143.096 238.981 143.096L238.979 143.094Z"
            fill="#1E1E1E"
          />
          <path
            d="M320.528 378.295H197.16C197.16 378.295 175.471 370.663 160.309 367.111V258.169C160.309 251.993 165.317 246.985 171.494 246.985H342.877C349.054 246.985 354.062 251.993 354.062 258.169V358.583L320.528 378.292V378.295Z"
            fill="#D2E8FE"
            stroke="#1E1E1E"
            stroke-width="1.99394"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_9198_10290">
          <rect
            width="371.165"
            height="482.326"
            fill="white"
            transform="translate(75.4067 0.913086)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DesktopMan;
