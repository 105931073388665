import { HTMLAttributes } from "react";
import { cn } from "../../../lib/utils";

export const TableRow = ({
  className,
  ...props
}: HTMLAttributes<HTMLTableRowElement>) => (
  <tr className={cn("border-none", className)} {...props} />
);

TableRow.displayName = "Table.Row";
