import { cn } from "../../lib/utils";
import { AsyncEventHandler, Union } from "../../types";
import {
  MaisonSportMonotoneIcon,
  CheckIcon,
  ExclamationCircleIcon,
} from "../icons";
import { cva, VariantProps } from "class-variance-authority";
import React, { forwardRef, useCallback, type MouseEvent, useRef } from "react";
import { useTextStyles } from "./Text";

const useStyles = cva(
  [
    "group/btn",
    "relative overflow-hidden",
    "transition-all ease-in-out duration-300",
    "min-h-12 h-12 px-8 w-min",
    "text-normal text-sm md:text-base whitespace-nowrap leading-none",
    "box-border rounded-md",
    "disabled:cursor-not-allowed disabled:grayscale-[30%]",
    "active:scale-95 ",
    "focus:ring-2 focus:ring-ms-primary focus:ring-offset-2",
  ],
  {
    variants: {
      color: {
        primary: "from-ms-primary via-ms-night-light to-ms-primary/90",
        success: "from-ms-success via-ms-success-accent to-ms-success/90",
        error: "from-ms-error via-ms-error-accent to-ms-error/90",
        info: "from-ms-info via-ms-info-accent to-ms-info/90 !text-ms-night-dark !fill-ms-night-dark",
      },
      variant: {
        gradient: [
          "bg-gradient-to-br !bg-200% bg-pos-0% text-white fill-white border-0",
          "enabled:hover:bg-pos-100%",
          "active:!bg-pos-50%",
        ],
        outlined: [
          "text-ms-night-dark fill-ms-night-dark leading-none bg-white box-border border border-ms-night-dark",
          "enabled:hover:text-white enabled:hover:fill-white enabled:hover:bg-ms-night-dark enabled:group-hover/btn:text-white enabled:group-hover/btn:fill-white enabled:group-hover/btn:bg-ms-night-dark",
          "disabled:border-ms-night-dark/50",
          "enabled:active:border-ms-night-dark/85",
        ],
        transparent: [
          "border-none",
          "hover:underline hover:bg-ms-primary-light",
        ],
        icon: [
          "rounded-full h-12 w-12 !bg-transparent p-2 fill-ms-dark",
          "focus:ring-offset-0",
        ],
      },
      busy: {
        true: ["enabled:hover:cursor-wait enabled:hover:bg-pos-0%"],
      },
      width: {
        full: "w-full text-center flex items-center justify-center",
      },
    },
    defaultVariants: {
      busy: false,
      color: "primary",
      variant: "gradient",
    },
    compoundVariants: [
      {
        color: "primary",
        busy: true,
        class: [],
      },
      {
        busy: true,
        variant: "outlined",
        class: [
          "enabled:hover:text-ms-night-dark enabled:hover:fill-ms-dark-night enabled:hover:!bg-transparent",
        ],
      },
    ],
  },
);

type StyleProps = VariantProps<typeof useStyles>;

type ButtonAttributes = React.ButtonHTMLAttributes<HTMLButtonElement>;
export type ButtonProps = Union<
  StyleProps &
    Omit<ButtonAttributes, "children"> & {
      onClickAsync?: AsyncEventHandler<HTMLButtonElement>;
      onError?: () => void;
      onSuccess?: () => void;
      disabled?: boolean;
      children?: ButtonAttributes["children"];
    }
>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      onClick = undefined,
      onClickAsync,
      onError,
      onSuccess,
      color,
      // busy: _busy = false,
      variant = undefined,
      width,
      children,
      onMouseEnter,
      disabled,
      ...props
    },
    forwardedRef,
  ) => {
    const [isBusy, setIsBusy] = React.useState<boolean>(false);
    const [showSuccess, setShowSuccess] = React.useState<boolean>(false);
    const [showError, setShowError] = React.useState<boolean>(false);

    const statefulColor = React.useMemo(() => {
      if (props.busy || isBusy) return color;
      if (showSuccess && !showError) return "success";
      if (showError) return "error";

      return color;
    }, [color, props.busy, isBusy, showSuccess, showError]);

    const shouldHideChildren = React.useMemo(() => {
      return !!(props.busy || isBusy || showSuccess || showError);
    }, [props.busy, isBusy, showSuccess, showError]);

    const handleOnClickSync = React.useCallback(
      (e) => {
        onClick && onClick(e);
      },
      [onClick],
    );

    const handleOnClickAsync = async (e) => {
      if (isBusy) return;

      setIsBusy(true);

      try {
        onClickAsync && (await onClickAsync(e));

        setShowSuccess(true);
        onSuccess && onSuccess();
      } catch (e) {
        setShowError(true);
        onError && onError();
      }

      setIsBusy(false);

      await new Promise((resolve) =>
        setTimeout(() => {
          setShowSuccess(false);
          setShowError(false);
        }, 2000),
      );
    };

    const handleOnClick = (e: MouseEvent<HTMLButtonElement>) => {
      if (!!onClick && onClickAsync) return handleOnClickSync(e);
      if (onClickAsync) return handleOnClickAsync(e);

      return handleOnClickSync(e);
    };

    const handleOnMouseEnter = useCallback(
      (e) => {
        onMouseEnter && onMouseEnter(e);
      },
      [onMouseEnter],
    );

    const isTouchStarted = useRef(false);

    return (
      <button
        className={cn(
          useStyles({
            variant,
            color: statefulColor,
            busy: props.busy || isBusy,
            width,
          }),
          className,
          shouldHideChildren
            ? "active:scale-100 active:opacity-100 enabled:hover:!bg-pos-0%"
            : "",
          showError || showSuccess
            ? `enabled:hover:cursor-default ${
                variant === "outlined"
                  ? "enabled:hover:fill-ms-dark-night enabled:hover:bg-transparent"
                  : ""
              }`
            : "",
        )}
        onTouchStart={() => {
          isTouchStarted.current = true;
        }}
        onTouchMove={() => {
          if (isTouchStarted.current === true) isTouchStarted.current = false;
        }}
        onTouchEnd={(e) => {
          if (isTouchStarted.current === false) return;

          handleOnClick(e);
          e.preventDefault();
        }}
        onClick={(e) => {
          handleOnClick(e);
        }}
        disabled={disabled || props.busy || isBusy}
        aria-disabled={disabled || props.busy || isBusy}
        onMouseEnter={handleOnMouseEnter}
        ref={forwardedRef}
        {...props}
      >
        <span className="absolute bottom-0 left-0 right-0 top-0 z-10 bg-white opacity-0 transition duration-300 ease-in-out group-active/btn:opacity-30 group-disabled/btn:opacity-50"></span>
        {shouldHideChildren && (
          <span className="absolute inset-0 flex items-center justify-center">
            {props.busy || isBusy ? (
              <MaisonSportMonotoneIcon className="h-6 w-6 animate-inflate fill-inherit" />
            ) : showSuccess ? (
              <CheckIcon className="h-6 w-6 animate-plop  fill-inherit" />
            ) : showError ? (
              <ExclamationCircleIcon className="h-6 w-6 animate-shake fill-inherit " />
            ) : undefined}
          </span>
        )}
        <span
          className={cn(
            useTextStyles({ variant: "cabron" }),
            shouldHideChildren ? "invisible" : "",
            "z-20",
          )}
        >
          {children}
        </span>
      </button>
    );
  },
);
Button.displayName = "Button";
