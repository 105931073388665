import { debounce } from "../utils";
import * as React from "react";

const viewports = {
  xs: 420,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
} as const;

type Viewport = keyof typeof viewports;

const viewportsToEntries = Object.entries(viewports);
function deriveViewportFromWindowWidth(windowInnerWidth: number): Viewport {
  let rtn: Viewport = "xs";

  for (const [key, value] of viewportsToEntries) {
    const i = viewportsToEntries.findIndex(([k]) => k === key);

    if (i === undefined) break;

    const nextI = i + 1;
    const [nextK, nextV] = viewportsToEntries[nextI] || [undefined, undefined];

    const evaluations = [windowInnerWidth >= value];
    if (nextK && nextV) {
      evaluations.push(windowInnerWidth < nextV);
    }

    if (evaluations.every(Boolean)) {
      rtn = key as Viewport;
      break;
    }
  }

  return rtn;
}

const viewportRanking: Record<Viewport, number> = {
  xs: 10,
  sm: 20,
  md: 30,
  lg: 40,
  xl: 50,
  "2xl": 60,
};

type RTN = {
  currentViewport: Viewport;
  isMobile: boolean;
  show: (v?: Viewport, v2?: Viewport) => boolean;
};
export const useTailwindViewport = (): RTN => {
  const [currentViewport, setCurrentViewport] = React.useState<Viewport>("lg");
  const mounted = React.useRef<boolean>(false);

  React.useEffect(() => {
    const handleSizeChange = () => {
      setCurrentViewport(deriveViewportFromWindowWidth(window.innerWidth));
    };

    const debouncedHandler = debounce(handleSizeChange);

    if (!mounted.current) {
      handleSizeChange();
      mounted.current = true;
    }

    window.addEventListener("resize", handleSizeChange);
    return () => {
      window.removeEventListener("resize", handleSizeChange);
    };
  }, []);

  const memoizedCurrentViewport = React.useMemo(
    () => currentViewport,
    [currentViewport],
  );
  const memoizedIsMobile = React.useMemo(
    () => (["xs", "sm"] as Viewport[]).includes(currentViewport),
    [currentViewport],
  );

  const show = React.useCallback(
    (v?: Viewport, v2?: Viewport) => {
      if (typeof v !== "string" && typeof v2 !== "string") return true;

      if (v && v2) {
        return (
          viewportRanking[currentViewport] >= viewportRanking[v] &&
          viewportRanking[currentViewport] < viewportRanking[v2]
        );
      } else if (!v && v2) {
        return viewportRanking[currentViewport] < viewportRanking[v2];
      } else if (v && !v2) {
        return viewportRanking[currentViewport] >= viewportRanking[v];
      } else {
        return true;
      }
    },
    [currentViewport],
  );

  return {
    currentViewport: memoizedCurrentViewport,
    isMobile: memoizedIsMobile,
    show,
  };
};
