import { cn } from "../../../lib/utils";
import { HTMLTableSectionAttributes } from "../../../types";
import { useTextStyles } from "../Text";

export const TableHeader = ({
  className,
  ...props
}: HTMLTableSectionAttributes) => (
  <thead
    className={cn(
      useTextStyles({ variant: "copy", bold: true }),
      "[&_tr]:border-b",
      className
    )}
    {...props}
  />
);

TableHeader.displayName = "Table.Header";
