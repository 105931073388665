import { HTMLAttributes } from "react";

const HighFive = ({
  className,
}: Pick<HTMLAttributes<HTMLOrSVGImageElement>, "className">) => {
  return (
    <svg
      className={className}
      viewBox="0 0 957 533"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M955.662 532.737C676.634 532.737 755.02 532.737 491.691 532.737C228.361 532.737 197.463 532.737 2.0625 532.737C2.0625 269.408 215.533 55.9375 478.862 55.9375C742.191 55.9375 955.662 269.408 955.662 532.737Z"
        fill="white"
      />
      <g clipPath="url(#clip0_2060_52951)">
        <mask
          id="mask0_2060_52951"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="1"
          y="54"
          width="955"
          height="480"
        >
          <path
            d="M955.909 532.117C570.447 533.529 231.003 533.529 1.08203 532.117C1.08203 268.452 214.831 54.7031 478.496 54.7031C742.161 54.7031 955.909 268.452 955.909 532.117Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_2060_52951)">
          <path
            d="M532.641 377.03C532.641 377.03 536.991 374.121 538.757 374.417C540.522 374.714 541.144 373.118 540.946 376.903C540.748 380.688 535.225 386.409 535.225 386.409L532.641 377.03Z"
            fill="#2FCB71"
            stroke="#1E1E1E"
            strokeWidth="2.82493"
            strokeMiterlimit="10"
          />
          <path
            d="M439.908 437.103C439.908 437.103 438.708 430.492 444.81 429.447C450.911 428.402 455.686 436.1 455.686 436.1C455.686 436.1 445.233 446.58 439.908 437.103Z"
            fill="#2FCB71"
            stroke="#1E1E1E"
            strokeWidth="2.82493"
            strokeMiterlimit="10"
          />
          <path
            d="M541 396.564C541 396.564 529.064 379.587 535.957 357.015C542.85 334.458 573.091 312.622 573.091 312.622C573.091 312.622 588.303 299.669 588.586 290.757C588.854 281.844 579.8 277.437 568.627 284.542C557.469 291.632 546.72 300.983 545.067 302.155C543.415 303.328 538.245 306.831 540.901 292.268C543.556 277.705 550.407 232.153 549.856 226.744C549.305 221.334 546.833 212.93 537.92 212.661C529.008 212.393 522.44 247.592 522.129 252.38C521.818 257.168 514.05 300.333 514.05 300.333C514.05 300.333 519.742 249.329 519.7 239.399C519.657 229.47 521.211 208.438 518.033 201.376C514.855 194.313 505.547 193.494 503.47 200.161C501.394 206.828 485.137 271.152 483.498 302.805C483.498 302.805 490.264 240.303 485.786 229.399C481.309 218.495 473.625 225.444 472.17 231.249C470.715 237.055 460.588 312.551 460.588 312.551C460.588 312.551 462.509 265.149 458.229 257.422C453.949 249.696 449.316 254.244 447.085 261.858C444.853 269.471 435.404 327.227 434.853 342.142C434.316 357.058 424.81 431.678 463.893 455.606L540.971 396.564H541Z"
            fill="white"
            stroke="#1E1E1E"
            strokeWidth="2.82493"
            strokeMiterlimit="10"
          />
          <path
            d="M661.146 485.622C661.146 485.622 603.673 480.961 540.959 376.904C540.959 376.904 531.99 394.108 489.701 415.62C447.412 437.132 449.573 444.519 439.926 437.104C439.926 437.104 451 472.331 475.421 501.554C499.843 530.778 541.171 592.079 621.456 601.077C701.741 610.074 976.776 610.23 976.776 610.23L994.22 492.162L661.16 485.622H661.146Z"
            fill="#2FCB71"
            stroke="#1E1E1E"
            strokeWidth="2.82493"
            strokeMiterlimit="10"
          />
          <path
            d="M412.269 374.372C412.269 374.372 408.455 370.798 406.661 370.798C404.867 370.798 404.514 369.131 404.091 372.888C403.667 376.646 408.173 383.214 408.173 383.214L412.255 374.386L412.269 374.372Z"
            fill="#2FCB71"
            stroke="#1E1E1E"
            strokeWidth="2.82493"
            strokeMiterlimit="10"
          />
          <path
            d="M475.406 501.552C475.406 501.552 551.75 477.668 578.559 429.347C578.559 429.347 571.694 387.764 540.945 376.902C540.945 376.902 532.526 388.725 518.769 398.231L491.876 414.954"
            fill="#2FCB71"
          />
          <path
            d="M475.406 501.552C475.406 501.552 551.75 477.668 578.559 429.347C578.559 429.347 571.694 387.764 540.945 376.902C540.945 376.902 532.526 388.725 518.769 398.231L491.876 414.954"
            stroke="#1E1E1E"
            strokeWidth="2.82493"
            strokeMiterlimit="10"
          />
          <path
            d="M493.893 448.836C493.893 448.836 496.168 442.508 490.32 440.488C484.472 438.469 478.512 445.263 478.512 445.263C478.512 445.263 487.1 457.311 493.908 448.836H493.893Z"
            fill="#2FCB71"
            stroke="#1E1E1E"
            strokeWidth="2.82493"
            strokeMiterlimit="10"
          />
          <path
            d="M400.813 392.269C400.813 392.269 415.375 377.466 412.268 354.09C409.161 330.7 382.917 304.216 382.917 304.216C382.917 304.216 370.035 288.947 371.222 280.105C372.408 271.277 382.084 268.41 391.928 277.238C401.773 286.066 410.841 297.041 412.282 298.481C413.723 299.922 418.243 304.216 418.017 289.413C417.777 274.611 418.497 228.55 419.924 223.31C421.35 218.07 425.178 210.188 434.006 211.374C442.834 212.561 443.554 248.367 443.074 253.141C442.594 257.915 443.187 301.772 443.187 301.772C443.187 301.772 445.941 250.514 447.608 240.74C449.275 230.965 451.181 209.948 455.489 203.507C459.797 197.066 469.092 197.772 470.052 204.694C471.012 211.615 476.493 277.718 472.919 309.23C472.919 309.23 476.493 246.46 482.708 236.432C488.923 226.403 495.363 234.525 495.829 240.485C496.31 246.446 493.923 322.578 493.923 322.578C493.923 322.578 499.799 275.5 505.293 268.579C510.788 261.658 514.601 266.913 515.562 274.78C516.522 282.662 516.367 341.194 514.46 355.983C512.553 370.785 509.686 445.957 467.199 463.147L400.855 392.269H400.813Z"
            fill="white"
            stroke="#1E1E1E"
            strokeWidth="2.82493"
            strokeMiterlimit="10"
          />
          <path
            d="M267.702 460.406C267.702 460.406 325.161 465.236 404.09 372.875C404.09 372.875 410.121 391.308 448.3 419.472C486.479 447.637 483.145 454.558 493.88 448.823C493.88 448.823 477.171 481.762 448.3 506.579C419.415 531.396 368.58 585.098 287.914 580.804C207.248 576.51 -32.207 577.697 -32.207 577.697L-30.0601 458.372L267.688 460.406H267.702Z"
            fill="#2FCB71"
            stroke="#1E1E1E"
            strokeWidth="2.82493"
            strokeMiterlimit="10"
          />
          <path
            d="M390.797 234.509C390.797 234.509 367.887 224.72 368.127 220.186C368.367 215.652 390.797 234.509 390.797 234.509Z"
            stroke="#1E1E1E"
            strokeWidth="2.82493"
            strokeMiterlimit="10"
          />
          <path
            d="M418.708 189.651C418.708 189.651 403.906 171.275 406.053 168.888C408.2 166.5 418.708 189.651 418.708 189.651Z"
            stroke="#1E1E1E"
            strokeWidth="2.82493"
            strokeMiterlimit="10"
          />
          <path
            d="M481.962 182.729C481.962 182.729 478.148 152.418 483.148 152.658C488.148 152.898 481.962 182.729 481.962 182.729Z"
            stroke="#1E1E1E"
            strokeWidth="2.82493"
            strokeMiterlimit="10"
          />
          <path
            d="M543.305 197.276C543.305 197.276 564.068 179.917 568.602 179.169C573.136 178.42 557.627 191.062 543.305 197.276Z"
            stroke="#1E1E1E"
            strokeWidth="2.82493"
            strokeMiterlimit="10"
          />
          <path
            d="M575.52 238.507C575.52 238.507 597.243 223.775 601.297 224.721C605.351 225.668 575.52 238.507 575.52 238.507Z"
            stroke="#1E1E1E"
            strokeWidth="2.82493"
            strokeMiterlimit="10"
          />
          <path
            d="M391.449 285.334L382.441 293.867C378.175 289.837 373.671 278.46 379.597 277.038C384.337 275.9 389.473 282.094 391.449 285.334Z"
            fill="#1E1E1E"
          />
          <path
            d="M436.879 229.873L424.534 231.119C423.621 225.322 427.012 213.565 432.571 216.062C437.018 218.06 437.296 226.102 436.879 229.873Z"
            fill="#1E1E1E"
          />
          <path
            d="M467.929 219.916L455.585 221.162C454.672 215.365 458.063 203.608 463.622 206.105C468.069 208.103 468.347 216.144 467.929 219.916Z"
            fill="#1E1E1E"
          />
          <path
            d="M492.345 248.204L482.863 247.784C482.812 243.295 486.677 234.77 490.609 237.274C493.755 239.278 493.077 245.396 492.345 248.204Z"
            fill="#1E1E1E"
          />
          <path
            d="M511.777 277.47L504.559 277.363C504.621 273.95 507.759 267.379 510.699 269.195C513.052 270.648 512.398 275.317 511.777 277.47Z"
            fill="#1E1E1E"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2060_52951">
          <rect
            width="955.703"
            height="531.906"
            fill="white"
            transform="translate(0.648438 0.802734)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HighFive;
