import { cn } from "../../../lib/utils";
import { HTMLAttributes } from "react";
import { useTextStyles } from "../Text";

export const TableHead = ({
  className,
  ...props
}: HTMLAttributes<HTMLTableHeaderCellElement>) => (
  <th
    className={cn(
      "px-4 text-left [&:has([role=checkbox])]:pr-0",
      useTextStyles({ variant: "sub1" }),
      className
    )}
    {...props}
  />
);

TableHead.displayName = "Table.Head";
