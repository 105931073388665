import { cn } from "../../lib/utils";
import { IconProps } from "./useIconStyles";

export const CheckIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={cn("w-4", className)}
      viewBox="0 0 46 34"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M44.4614 1.55156C44.1631 1.25085 43.8083 1.01216 43.4173 0.849281C43.0263 0.686398 42.607 0.602539 42.1835 0.602539C41.7599 0.602539 41.3406 0.686398 40.9496 0.849281C40.5586 1.01216 40.2038 1.25085 39.9055 1.55156L16.0035 25.4857L5.96137 15.4116C5.6517 15.1124 5.28613 14.8772 4.88556 14.7193C4.48498 14.5615 4.05723 14.4841 3.62674 14.4915C3.19624 14.499 2.77143 14.5911 2.37655 14.7627C1.98167 14.9344 1.62447 15.1821 1.32533 15.4918C1.02619 15.8014 0.790972 16.167 0.633109 16.5676C0.475246 16.9682 0.397828 17.3959 0.405276 17.8264C0.412724 18.2569 0.504892 18.6817 0.676517 19.0766C0.848142 19.4715 1.09586 19.8287 1.40554 20.1278L13.7255 32.4478C14.0238 32.7485 14.3786 32.9872 14.7696 33.1501C15.1606 33.313 15.5799 33.3968 16.0035 33.3968C16.427 33.3968 16.8463 33.313 17.2373 33.1501C17.6283 32.9872 17.9831 32.7485 18.2814 32.4478L44.4614 6.26781C44.787 5.96737 45.0469 5.60274 45.2247 5.19689C45.4025 4.79104 45.4942 4.35276 45.4942 3.90968C45.4942 3.46661 45.4025 3.02833 45.2247 2.62248C45.0469 2.21663 44.787 1.85199 44.4614 1.55156Z" />
    </svg>
  );
};
