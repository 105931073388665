import { cn } from "../../../lib/utils";
import { HTMLDivAttributes, HTMLTableAttributes } from "../../../types";
import { useTextStyles } from "../Text";
import { TableBody } from "./TableBody";
import { TableCaption } from "./TableCaption";
import { TableCell } from "./TableCell";
import { TableFooter } from "./TableFooter";
import { TableHead } from "./TableHead";
import { TableHeader } from "./TableHeader";
import { TableRow } from "./TableRow";

export const Table = ({
  className,
  containerClassName,
  ...props
}: HTMLTableAttributes & {
  containerClassName?: HTMLDivAttributes["className"];
}) => {
  return (
    <div className={cn("w-full overflow-auto", containerClassName)}>
      <table
        className={cn(
          useTextStyles({ variant: "copy" }),
          "w-full caption-bottom",
          className,
        )}
        {...props}
      />
    </div>
  );
};
Table.displayName = "Table";

Table.Head = TableHead;
Table.Header = TableHeader;
Table.Body = TableBody;
Table.Footer = TableFooter;
Table.Row = TableRow;
Table.Cell = TableCell;
Table.Caption = TableCaption;
