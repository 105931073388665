import { cn } from "../../../lib/utils";
import { TextInput, TextInputProps } from "../TextInput";
import * as React from "react";
import { TranslationMap, Union } from "../../../types";

export type FilterFieldProps = Union<
  { i18n: TranslationMap<{ Filter: string }> } & Pick<
    TextInputProps,
    "onChange" | "value" | "className" | "autofocus"
  >
>;
const FilterField = React.forwardRef<HTMLInputElement, FilterFieldProps>(
  ({ onChange, value, className, ...props }, forwardedRef) => {
    return (
      <TextInput
        placeholder={"Filter"}
        containerClassName={cn("h-auto py-0", className)}
        value={value}
        onChange={onChange}
        ref={forwardedRef}
        onBlur={(e) => {
          e.preventDefault();
        }}
        {...props}
      />
    );
  },
);

export default FilterField;
FilterField.displayName = "SelectBox.FilterField";
