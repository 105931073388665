import Amex from "./providers/Amex";
import Visa from "./providers/Visa";
import Mastercard from "./providers/Mastercard";
import { Union } from "../../../types";
import { IconProps } from "../useIconStyles";
import { HTMLAttributes } from "react";

export const paymentProviderIcons = {
  Amex,
  Visa,
  Mastercard,
} as const;

export type PaymentProviderIconProps = Union<
  {
    provider: keyof typeof paymentProviderIcons;
  } & IconProps<HTMLAttributes<HTMLOrSVGImageElement>>
>;
export const PaymentProviderIcon = ({
  provider,
  ...props
}: PaymentProviderIconProps) => {
  let Icon;
  if (provider in paymentProviderIcons) Icon = paymentProviderIcons[provider];
  else {
    Object.entries(paymentProviderIcons).forEach(([key, Component]) => {
      if (key.toUpperCase() === provider.toUpperCase()) {
        Icon = Component;
        return;
      }
    });
  }

  if (Icon) {
    return <Icon {...props} />;
  }

  return null;
};
PaymentProviderIcon.displayName = "PaymentProviderIcon";

PaymentProviderIcon.Amex = paymentProviderIcons["Amex"];
PaymentProviderIcon.Visa = paymentProviderIcons["Visa"];
PaymentProviderIcon.Mastercard = paymentProviderIcons["Mastercard"];
