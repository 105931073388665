import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { ZodiosHooksInstance, type ZodiosInstance } from "../../index";
import { AxiosError } from "axios";
import { ZodiosPlugin } from "@zodios/core";

type AuthUser = Awaited<ReturnType<ZodiosInstance["getUser"]>>["data"];

const ApiAuthContext = createContext<ReturnType<typeof provideAuth> | null>(
  null,
);

type AuthOptions = {
  plugins?: ((context: any) => ZodiosPlugin)[];
};

type AuthProviderConfig = {
  onAuthUserChange?: (
    prevUser: AuthUser | null,
    newUser: AuthUser | null,
  ) => void;
};

export const createUseAuthHook = (
  api: ZodiosHooksInstance,
  zodios: ZodiosInstance,
  options?: AuthOptions,
) => {
  return {
    useAuth,
    ApiAuthProvider: ({
      children,
      ...config
    }: {
      children: ReactNode;
    } & AuthProviderConfig) => {
      const authContext = provideAuth(api, zodios, options, config);

      return (
        <ApiAuthContext.Provider value={authContext}>
          {children}
        </ApiAuthContext.Provider>
      );
    },
  };
};

const provideAuth = (
  api: ZodiosHooksInstance,
  zodios: ZodiosInstance,
  options: AuthOptions = {},
  config?: AuthProviderConfig = {},
) => {
  const queryClient = useQueryClient();

  const [authUser, setAuthUser] = useState<AuthUser | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const isAuthenticated = useMemo(
    () => authUser !== null,
    [JSON.stringify(authUser)],
  );

  const handleSetAuthUser = (newUser) => {
    setAuthUser((prev) => {
      config?.onAuthUserChange && config.onAuthUserChange(prev, newUser);

      return newUser as AuthUser;
    });
  };

  const fetchAuthUser = () =>
    zodios.getUser({
      queries: {
        withs: [
          "instructorProfile.resorts",
          "instructorProfile.sports",
          "instructorProfile.bookingRules",
          "userInteractions",
        ],
        withCounts: ["orders"],
      },
      params: { id: "me" as unknown as number },
    });

  const refetchUser = async () => {
    try {
      const data = await fetchAuthUser();

      handleSetAuthUser(data);
      setIsLoading(false);

      return data;
    } catch (e) {
      handleSetAuthUser(null);
      setIsLoading(false);

      return null;
    }
  };

  useEffect(() => {
    const { plugins } = options;

    if (Array.isArray(plugins) && plugins.length > 0)
      plugins.forEach((registerPlugin) =>
        zodios.use(
          registerPlugin({
            queryClient,
            isAuthenticated,
            authUser,
            setAuthUser,
            isLoading,
            fetchAuthUser,
          }),
        ),
      );

    refetchUser();
  }, []);

  const useLoginMutation = (
    mutationOptions?: Omit<
      UseMutationOptions<
        AuthUser,
        unknown,
        {
          email: string;
          password: string;
          remember?: boolean;
          options?: Parameters<
            ZodiosInstance["handleALoginRequestToTheApplication"]
          >[1];
        }
      >,
      "mutationFn"
    >,
  ) => {
    const { mutate, mutateAsync } = useMutation({
      ...mutationOptions,
      mutationFn: async ({ options, ...variables }) => {
        setIsLoading(true);
        await zodios.get("/api/v1/csrf");
        await zodios.handleALoginRequestToTheApplication(variables, options);
        return (await fetchAuthUser()) as AuthUser;
      },
      onSettled: async (data, error, variables, context) => {
        const axiosError = error as AxiosError<any, any>;

        if (
          axiosError?.response?.status === 422 &&
          axiosError?.response?.data?.message ===
            "These credentials do not match our records."
        ) {
          setIsLoading(false);
          return;
        }

        handleSetAuthUser(data);
        setIsLoading(false);

        mutationOptions?.onSettled &&
          mutationOptions.onSettled(data, error, variables, context);
      },
    });

    return {
      handleLogin: useCallback(mutate, [JSON.stringify(mutationOptions)]),
      handleLoginAsync: useCallback(mutateAsync, [
        JSON.stringify(mutationOptions),
      ]),
    };
  };

  const useLogoutMutation = (
    mutationOptions?: UseMutationOptions<
      void,
      unknown,
      {
        options: Parameters<ZodiosInstance["logTheUserOutOfTheApplication"]>[1];
      } | void,
      unknown
    >,
  ) => {
    const { mutate, mutateAsync } = useMutation({
      ...mutationOptions,
      mutationFn: async (variables) => {
        setIsLoading(true);
        return await zodios.logTheUserOutOfTheApplication(
          undefined,
          variables?.options,
        );
      },
      onSettled: async (data, error, variables, context) => {
        queryClient.clear();

        handleSetAuthUser(null);
        setIsLoading(false);

        mutationOptions?.onSettled &&
          mutationOptions.onSettled(data, error, variables, context);
      },
    });

    return {
      handleLogout: useCallback(mutate, [JSON.stringify(mutationOptions)]),
      handleLogoutAsync: useCallback(mutateAsync, [
        JSON.stringify(mutationOptions),
      ]),
    };
  };

  const useRegisterMutation = (
    mutationOptions?: Omit<
      UseMutationOptions<
        AuthUser,
        unknown,
        {
          type: string;
          first_name: string;
          last_name?: string;
          email: string;
          password: string;
          password_confirmation: string;
          phone: string;
          "g-recaptcha-response": string;
          title: string;
          nonce: string;
          idempotency_key: string;
          options?: Parameters<
            ZodiosInstance["handleARegistrationRequestForTheApplication"]
          >[1];
        }
      >,
      "mutationFn"
    >,
  ) => {
    const { mutate, mutateAsync } = useMutation({
      ...mutationOptions,
      mutationFn: async ({ options, ...variables }) => {
        setIsLoading(true);
        await zodios.get("/api/v1/csrf");
        await zodios.handleARegistrationRequestForTheApplication(
          variables,
          options,
        );
        return (await fetchAuthUser()) as AuthUser;
      },
      onSettled: async (data, error, variables, context) => {
        const axiosError = error as AxiosError<any, any>;

        if (
          axiosError?.response?.status === 422 &&
          axiosError?.response?.data?.message ===
            "The email has already been taken."
        ) {
          setIsLoading(false);
          return;
        }

        handleSetAuthUser(data);
        setIsLoading(false);

        mutationOptions?.onSettled &&
          mutationOptions.onSettled(data, error, variables, context);
      },
    });

    return {
      handleRegister: useCallback(mutate, [JSON.stringify(mutationOptions)]),
      handleRegisterAsync: useCallback(mutateAsync, [
        JSON.stringify(mutationOptions),
      ]),
    };
  };

  return {
    isAuthenticated,
    isLoading,
    authUser,
    useLoginMutation,
    useLogoutMutation,
    useRegisterMutation,
    fetchAuthUser,
    refetchUser,
  };
};

const useAuth = () => {
  const context = useContext(ApiAuthContext);

  if (!context) throw Error("Must be a child of ApiAuthProvider");

  return context;
};
