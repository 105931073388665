import { cn } from "../../lib/utils";
import { HTMLInputAttributes, Union } from "../../types";
import { Text, TextLabelProps, useTextStyles } from "../core/Text";
import { ErrorList, Props as ErrorListProps } from "./shared/ErrorList";
import TextStyleInputContainer, {
  Props as TextStyleInputContainerProps,
} from "./shared/TextStyleInputContainer";
import { cva, VariantProps } from "class-variance-authority";
import * as React from "react";
import { FormEventHandler, useImperativeHandle } from "react";

const Label = ({
  children,
  ...styleProps
}: TextLabelProps & { error?: boolean; focussed?: boolean }) => {
  return <Text.Label {...styleProps}>{children}</Text.Label>;
};

export const useStyles = cva([
  "appearance-none",
  "bg-transparent",
  "flex w-full",
  "py-3",
  "font-normal text-base",
  "disabled:text-ms-grey-dark disabled:cursor-not-allowed",
  "focus:outline-none",
]);
type StyleProps = VariantProps<typeof useStyles>;
export type GenericTextInputProps = {
  name?: string;
  label?: string;
  value?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  onChange?: (value: TextInputProps["value"]) => void;
  containerClassName?: HTMLInputAttributes["className"];
  outmostClassName?: HTMLInputAttributes["className"];
  autofocus?: boolean;
  autocomplete?: React.InputHTMLAttributes<HTMLInputElement>["autoComplete"];

  type?: string;
};
export type TextInputProps = Union<
  StyleProps &
    Pick<
      HTMLInputAttributes,
      "className" | "onBlur" | "onKeyPress" | "onFocus"
    > &
    GenericTextInputProps &
    Pick<ErrorListProps, "errors"> &
    Pick<TextStyleInputContainerProps, "Icon"> & {
      id?: string;
      autofocus?: boolean;
    }
>;

export const TextInputContainer = ({
  className,
  containerClassName,
  outmostClassName,
  autofocus,
  type,
  label,
  error,
  errors,
  disabled,
  Icon,
  id,
  children,
  focussed = false,
  ...styleProps
}: React.PropsWithChildren<Omit<TextInputProps, "value" | "onChange">> & {
  focussed?: boolean;
  outmostClassName?: string;
}) => {
  // const hasErrorMessages = !!errors || !!errors?.length;

  return (
    <div className={cn("flex flex-1 flex-col gap-1", outmostClassName)}>
      <Label
        className={className}
        disabled={disabled}
        htmlFor={id}
        error={error || errors?.length > 0}
        focussed={focussed}
      >
        {(!!label || !!styleProps.required) && (
          <span className=" font-normal text-gray-500">
            {label}
            {styleProps.required && (
              <span className={"ml-1 text-ms-error"}>*</span>
            )}
          </span>
        )}
        <TextStyleInputContainer
          Icon={Icon}
          disabled={disabled}
          error={error || errors?.length > 0}
          focussed={focussed}
          className={containerClassName}
          // {...styleProps}
        >
          {children}
        </TextStyleInputContainer>
      </Label>
      <ErrorList errors={errors} />
    </div>
  );
};

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className,
      containerClassName,
      autofocus,
      type = "text",
      value,
      placeholder,
      onChange,
      onBlur,
      onKeyPress,
      label,
      error,
      errors,
      disabled,
      Icon,
      autocomplete,
      id,
      ...props
    },
    forwardedRef,
  ) => {
    const [focussed, setFocussed] = React.useState<boolean>(false);
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const alreadyAutoFocussed = React.useRef(false);

    const hasErrorMessages = !!errors || !!errors?.length;

    const handleChange = React.useCallback<FormEventHandler<HTMLInputElement>>(
      (e) => {
        onChange && onChange(e?.currentTarget?.value);
      },
      [onChange],
    );

    useImperativeHandle<Partial<HTMLInputElement>, Partial<HTMLInputElement>>(
      forwardedRef,
      () => inputRef.current!,
      [],
    );

    React.useEffect(() => {
      if (!alreadyAutoFocussed.current && inputRef.current && autofocus) {
        setTimeout(() => {
          inputRef.current?.focus();
          alreadyAutoFocussed.current = true;
        }, 300);
      }
    }, [alreadyAutoFocussed, inputRef, autofocus]);

    return (
      <div className={"flex flex-col gap-micro"}>
        <Label
          className={className}
          disabled={disabled}
          htmlFor={id}
          error={error || errors?.length > 0}
          focussed={focussed}
        >
          {(!!label || !!props.required) && (
            <span>
              {label}
              {props.required && (
                <span className={"ml-1 text-ms-error"}>*</span>
              )}
            </span>
          )}
          <TextStyleInputContainer
            Icon={Icon}
            disabled={disabled}
            error={error || hasErrorMessages}
            focussed={focussed}
            className={containerClassName}
          >
            <input
              id={id}
              ref={inputRef}
              type={type}
              className={cn(
                useTextStyles({ variant: "cabron" }),
                useStyles({}),
                className,
              )}
              value={value}
              onChange={handleChange}
              onKeyUp={onKeyPress}
              disabled={disabled}
              placeholder={placeholder}
              onFocus={() => setFocussed(true)}
              onBlur={(e) => {
                setFocussed(false);
                onBlur && onBlur(e);
              }}
              autoFocus={autofocus}
              autoComplete={autocomplete}
              data-testid="text-input"
              {...props}
            />
          </TextStyleInputContainer>
        </Label>
        <ErrorList errors={errors} />
      </div>
    );
  },
);
TextInput.displayName = "TextInput";
