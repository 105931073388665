import { cn } from "../../../../lib/utils";
import { Union } from "../../../../types";
import { useTextStyles as getTextStyles } from "../../../core/Text";
import * as Select from "@radix-ui/react-select";
import { useEnvironmentContext } from "../../../../lib/environment";
import { forwardRef } from "react";

type Props = {
  label?: string;
};

export type OptionGroupProps = Union<
  Props & Pick<Select.SelectGroupProps, "children">
>;

export const optionGroupTextStyles = cn(
  "text-sm text-ms-grey-dark/60 font-bold border-t border-b first:border-t-0 border-black",
  getTextStyles({ variant: "copy", bold: true }),
  "px-4 pb-1 h-16 text-left inline-flex items-end w-full",
);
export const OptionGroup = forwardRef<HTMLDivElement, OptionGroupProps>(
  ({ label, children }, forwardedRef) => {
    const { t } = useEnvironmentContext();
    return (
      <Select.Group className={" bg-white"} ref={forwardedRef}>
        {!!label && (
          <Select.Label className={optionGroupTextStyles + ""}>
            {t(label)}
          </Select.Label>
        )}
        {children}
      </Select.Group>
    );
  },
);
OptionGroup.displayName = "SelectBox.OptionGroup";
OptionGroup.textStyles = optionGroupTextStyles;
