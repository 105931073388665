import { cn } from "../../../lib/utils";
import { HTMLDivAttributes, Union } from "../../../types";
import { IconProps } from "../../icons/useIconStyles";
import { cva, VariantProps } from "class-variance-authority";
import * as React from "react";
import { Text } from "../../core/Text";

export const useStyles = cva(
  [
    "relative",
    "overflow-hidden",
    "flex items-center gap-2",
    "whitespace-nowrap",
    "px-2 h-12",
    "border border-black rounded-md",
    "bg-white",
    "fill-black",
  ],
  {
    variants: {
      error: {
        true: [
          "fill-ms-error stroke-ms-error",
          "border-ms-error",
          "drop-shadow",
        ],
      },
      disabled: {
        true: [
          "bg-ms-grey opacity-75",
          "fill-ms-grey-dark",
          "cursor-not-allowed",
        ],
      },
      focussed: {
        true: ["border-ms-primary drop-shadow fill-ms-primary"],
      },
    },
  },
);
type StyleProps = VariantProps<typeof useStyles>;

export type Props = Union<
  StyleProps &
    Pick<HTMLDivAttributes, "children" | "className"> & {
      Icon?: React.FunctionComponent<IconProps>;
    }
>;
const TextStyleInputContainer = ({
  children,
  className,
  Icon,
  ...styleProps
}: Props) => {
  return (
    <div className={cn(useStyles(styleProps), className)}>
      {!!Icon && <Icon className={"h-4 w-4"} />}
      <Text.Label className={"w-full"} variant="cabron">
        {children}
      </Text.Label>
    </div>
  );
};

export default TextStyleInputContainer;
