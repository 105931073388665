import { cn } from "../../../../lib/utils";
import { Union } from "../../../../types";
import { Text, useTextStyles } from "../../../core/Text";
import * as Select from "@radix-ui/react-select";
import { cva, VariantProps } from "class-variance-authority";
import * as React from "react";

const useStyles = cva(
  [
    "bg-white",
    "px-4 h-12 flex items-center justify-between gap-3",
    // "border-b border-black",
    "last:border-none",
    "data-[disabled=false]:data-[state=unchecked]:data-[highlighted]:bg-ms-night-dark data-[disabled=false]:data-[state=unchecked]:hover:bg-ms-night-dark data-[disabled=false]:data-[state=unchecked]:data-[highlighted]:text-white data-[disabled=false]:data-[state=unchecked]:hover:text-white data-[disabled=false]:data-[state=unchecked]:hover:cursor-pointer transition ease-in-out duration-100",
    "data-[state=checked]:bg-ms-primary data-[state=checked]:text-white",
  ],
  {
    variants: {
      selected: {
        true: ["bg-ms-primary text-white"],
      },
    },
  },
);
type StyleProps = VariantProps<typeof useStyles>;

type SelectItemProps = Omit<React.ComponentProps<typeof Select.Item>, "value">;

export type Option = {
  label: string;
  value: string;

  icon?: HTMLOrSVGElement | string | JSX.Element;
};

type Props = {
  option: Option;
};

export type OptionProps = Union<StyleProps & Props & SelectItemProps>;

export const Option = React.forwardRef<HTMLDivElement, OptionProps>(
  ({ option, disabled = false, ...props }, forwardedRef) => {
    return (
      <Select.Item
        value={option.value}
        aria-label={option.label}
        className={cn(
          useTextStyles({
            variant: "copy",
            bold: props.selected,
          }),
          useStyles({ selected: props.selected }),
          "text-[15px] leading-[18px]",
          "relative leading-none",
        )}
        data-state={props.selected ? "checked" : "unchecked"}
        data-disabled={disabled}
        ref={forwardedRef}
        {...props}
      >
        {/* <span className={"inline-flex justify-center gap-2"}> */}
        <span className="block text-left">{option.label}</span>

        {!!option.icon && <span className="block">{option.icon}</span>}
        {/* </span> */}
      </Select.Item>
    );
  },
);
Option.displayName = "SelectBox.Option";

Option.containerStyles = useStyles;
