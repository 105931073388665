import { Option, OptionProps } from "./options/Option";
import { OptionsGroupedProps, OptionsProps } from "./options/options";

type SearchInput = string;

const standardize = (str: SearchInput): string =>
  str?.trim()?.toLowerCase()?.replace(/\W/gm, "") || "";
const tokenize = (str: SearchInput): string[] =>
  standardize(str).split(/\s/) || [];
const tokensHaveValue = (
  value: SearchInput,
  tokens: ReturnType<typeof tokenize>,
): boolean => tokens.some((str) => str.includes(value));

const fuzzySearch = (
  input: SearchInput | undefined,
  dataset: OptionProps["option"][],
): OptionProps["option"][] => {
  if (!input) {
    return dataset;
  }

  const searchInput = standardize(input);
  return dataset.filter((option) => {
    if (!option.value || !option.label) return false;
    const valueTokens = tokenize(option.value);
    const labelTokens = tokenize(option.label);

    return (
      tokensHaveValue(searchInput, valueTokens) ||
      tokensHaveValue(searchInput, labelTokens)
    );
  });
};

export const getOptionsFiltered = (
  filterValue: SearchInput | undefined,
  options: OptionsProps["options"],
): OptionsProps["options"] => {
  return fuzzySearch(filterValue, options);
};

export const getOptionGroupsFiltered = (
  filterValue: SearchInput,
  optionGroups: OptionsGroupedProps["groups"],
): OptionsGroupedProps["groups"] => {
  return optionGroups
    .map((g) => {
      const foundOptions = fuzzySearch(filterValue, g.options);
      if (!foundOptions.length) return false;

      return {
        ...g,
        options: foundOptions,
      };
    })
    .filter(Boolean) as OptionsGroupedProps["groups"];
};

export const getSelectedOptionFromGroup = (
  value: SearchInput,
  optionGroups: OptionsGroupedProps["groups"],
): Option | undefined => {
  let selectedOption: Option | undefined;
  for (const optionGroup of optionGroups) {
    const { options } = optionGroup;
    const foundOption = options.find((o) => o.value === value);
    if (foundOption) {
      selectedOption = foundOption;
      break;
    }
  }

  return selectedOption;
};
