import { cn } from "../../lib/utils";
import { IconProps, useIconStyles } from "./useIconStyles";

export const LoadingSpinnerIcon = ({ className, ...props }: IconProps) => (
  <svg
    className={cn("stroke-ms-primary", useIconStyles(props), className)}
    // height="100%"
    viewBox="0 0 32 32"
    // width="100%"
  >
    <circle
      cx="16"
      cy="16"
      fill="none"
      r="14"
      strokeWidth="4"
      style={{
        opacity: 0.2,
      }}
    ></circle>
    <circle
      cx="16"
      cy="16"
      fill="none"
      r="14"
      strokeWidth="4"
      style={{ strokeDasharray: 80, strokeDashoffset: 60 }}
    ></circle>
  </svg>
);
