import * as RadixPopover from "@radix-ui/react-popover";
import { Fragment, ReactNode, useEffect, useMemo, useState } from "react";
import { useEnvironmentContext } from "../../lib/environment";
import { Link } from "../core/Link";

type LanguageItem = {
  Icon: ReactNode;
  label: string;
  code: string;
};

export type LanguagePickerProps<TItems extends LanguageItem[]> = {
  currentPath: string;
  items: TItems;
  contentSide?: "top" | "bottom" | "left" | "right";
  currentLocale: TItems[number]["code"];
  dark?: boolean;
};

export const LanguagePicker = <Items extends LanguageItem[]>({
  currentPath,
  items,
  contentSide = "top",
  currentLocale,
  dark = false,
}: LanguagePickerProps<Items>) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [currentLocale]);

  const selectedLanguage: Items[number] = useMemo(() => {
    const selected = items.find(
      (lang: Items[number]) => lang.code === currentLocale,
    );

    if (!selected) return items[0];
    return selected;
  }, [currentLocale, items]);

  const memoizedItems: LanguageItem[] = useMemo(
    () => items.filter(({ code }) => code !== selectedLanguage.code),
    [selectedLanguage.code, items],
  );

  return (
    <RadixPopover.Root open={isOpen} onOpenChange={(val) => setIsOpen(val)}>
      <RadixPopover.Trigger
        className="group inline-flex items-center gap-2 rounded px-3 py-2 data-[dark=true]:hover:bg-white/10 group-data-[dark=false]:hover:bg-zinc-700/10"
        data-dark={dark}
      >
        <span className="block align-middle  group-data-[dark=true]:text-white">
          {selectedLanguage.label}
        </span>

        <div className="h-6 w-6 overflow-clip rounded-full border-2 border-black group-data-[dark=true]:border-white">
          {selectedLanguage.Icon}
        </div>
      </RadixPopover.Trigger>

      <RadixPopover.Portal>
        <RadixPopover.Content
          side={contentSide}
          sideOffset={8}
          align="end"
          className="data-[dark=true]:dark group z-iyf rounded-lg bg-white shadow-black drop-shadow-lg data-[dark=true]:bg-zinc-700"
          data-dark={dark}
        >
          <ul className="flex flex-col-reverse gap-2 overflow-clip rounded-lg">
            {memoizedItems.map(({ Icon, label, code }, index) => (
              <Fragment key={index}>
                <li>
                  <Link
                    className="inline-flex w-full items-center justify-between gap-2 px-3 py-2 hover:bg-zinc-700/10 group-data-[dark=true]:hover:bg-white/10"
                    href={currentPath}
                    locale={code}
                    onClick={() => setIsOpen(false)}
                  >
                    <span className="block align-middle group-data-[dark=true]:text-white">
                      {label}
                    </span>

                    <div className="h-6 w-6 overflow-clip rounded-full border-2 border-black object-cover object-center group-data-[dark=true]:border-white">
                      {Icon}
                    </div>
                  </Link>
                </li>
              </Fragment>
            ))}
          </ul>
          <RadixPopover.Arrow asChild>
            <svg
              className="block fill-white group-data-[dark=true]:fill-zinc-800"
              width="10"
              height="5"
              viewBox="0 0 30 10"
              preserveAspectRatio="none"
              // style="display: block;"
            >
              <polygon points="0,0 30,0 15,10" fill="inherit"></polygon>
            </svg>
          </RadixPopover.Arrow>
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
};
