import { cn } from "../../lib/utils";
import { useIconStyles, type IconProps } from "./useIconStyles";
import { HTMLAttributes } from "react";

export const HouseIcon = ({
  className,
  size,
}: IconProps<HTMLAttributes<HTMLOrSVGImageElement>>) => {
  return (
    <svg
      className={cn(useIconStyles({ size }), className)}
      viewBox="0 0 29 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M26.5539 9.84802L17.3364 1.76734C16.4914 1.01157 15.3975 0.59375 14.2639 0.59375C13.1302 0.59375 12.0364 1.01157 11.1914 1.76734L1.9739 9.84802C1.48593 10.2844 1.09654 10.8198 0.831651 11.4184C0.566758 12.0171 0.432442 12.6653 0.437646 13.3199V26.7468C0.437646 27.9691 0.923209 29.1413 1.78752 30.0056C2.65183 30.87 3.82408 31.3555 5.0464 31.3555H23.4814C24.7037 31.3555 25.876 30.87 26.7403 30.0056C27.6046 29.1413 28.0901 27.9691 28.0901 26.7468V13.3046C28.0932 12.6525 27.9578 12.0073 27.693 11.4114C27.4282 10.8156 27.0399 10.2827 26.5539 9.84802ZM17.3364 28.283H11.1914V20.6018C11.1914 20.1943 11.3532 19.8036 11.6414 19.5155C11.9295 19.2274 12.3202 19.0655 12.7276 19.0655H15.8001C16.2076 19.0655 16.5983 19.2274 16.8864 19.5155C17.1745 19.8036 17.3364 20.1943 17.3364 20.6018V28.283ZM25.0176 26.7468C25.0176 27.1542 24.8558 27.545 24.5677 27.8331C24.2796 28.1212 23.8888 28.283 23.4814 28.283H20.4089V20.6018C20.4089 19.3794 19.9233 18.2072 19.059 17.3429C18.1947 16.4786 17.0225 15.993 15.8001 15.993H12.7276C11.5053 15.993 10.3331 16.4786 9.46877 17.3429C8.60446 18.2072 8.1189 19.3794 8.1189 20.6018V28.283H5.0464C4.63896 28.283 4.24821 28.1212 3.9601 27.8331C3.672 27.545 3.51015 27.1542 3.51015 26.7468V13.3046C3.51042 13.0865 3.55714 12.8709 3.64721 12.6722C3.73727 12.4736 3.8686 12.2964 4.03247 12.1524L13.25 4.08708C13.5303 3.84079 13.8907 3.70496 14.2639 3.70496C14.6371 3.70496 14.9975 3.84079 15.2778 4.08708L24.4953 12.1524C24.6592 12.2964 24.7905 12.4736 24.8806 12.6722C24.9706 12.8709 25.0174 13.0865 25.0176 13.3046V26.7468Z" />
    </svg>
  );
};
