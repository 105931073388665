import { cn } from "../../lib/utils";
import { IconProps, useIconStyles } from "./useIconStyles";

export const Chevron = ({
  direction = "down",
  className,
  size,
}: IconProps & { direction?: "up" | "right" | "down" | "left" }) => {
  let rotation = "";
  switch (direction) {
    case "up":
      rotation = "rotate-180";
      break;
    case "right":
      rotation = "-rotate-90";
      break;
    case "left":
      rotation = "rotate-90";
      break;
  }
  return (
    <svg
      className={cn(
        useIconStyles({ size }),
        `transition-all w-4 ${rotation} fill-transparent stroke-ms-black`,
        className
      )}
      viewBox="0 0 18 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1.25L8.57253 8.75L16.1451 1.25"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
