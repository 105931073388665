import { cn } from "../../lib/utils";
import { cva, VariantProps } from "class-variance-authority";
import { useTextStyles } from "./Text";
import { useEnvironmentContext } from "../../lib/environment";
import { AnchorHTMLAttributes, ReactNode } from "react";

const useStyles = cva(["whitespace-nowrap"], {
  variants: {
    type: {
      text: "px-4 w-fit text-ms-primary hover:underline transition ease-in-out",
      flat: "px-4 text-black dark:text-white rounded transition hover:bg-ms-grey-dark/10 dark:hover:bg-white/10 hover:no-underline box-border",
      outlined:
        "px-4 w-fit border dark:border-white text-white rounded hover:black/5 dark:hover:bg-white/10 box-border",
      icon: "w-10 border border-ms-primary text-ms-primary fill-ms-primary rounded-full justify-center hover:border-ms-primary/60 hover:text-ms-primary/60 hover:fill-ms-primary/60",
      wrapper: "flex grow-0 w-fit pointer-events-auto whitespace-normal",
      none: "",
    },
  },
  compoundVariants: [
    {
      type: ["text", "flat", "outlined", "icon"],
      class:
        "inline-flex h-8 items-center md:justify-center transition ease-in-out text-sm font-light",
    },
  ],
  defaultVariants: {
    type: "none",
  },
});

export const useLinkStyles = useStyles;

type StyleProps = VariantProps<typeof useStyles>;

export type LinkProps = StyleProps & {
  href: string | Record<string, unknown>;
  className?: string;
  children?: ReactNode;
  isAnchor?: boolean;
};

type ElementProps<T> = T extends React.ComponentType<infer Props>
  ? Props extends object
    ? Props
    : never
  : never;

export const Link = <T extends {} = AnchorHTMLAttributes<HTMLAnchorElement>>({
  className,
  // href,
  children,
  type,
  isAnchor = false,
  ...props
}: T & LinkProps) => {
  const { Link } = useEnvironmentContext();

  if (isAnchor)
    return (
      <a
        className={cn(
          useTextStyles({ variant: "cabron" }),
          useStyles({ type }),
          className,
        )}
        {...props}
        href={props.href as string}
      >
        {children}
      </a>
    );

  return (
    <Link
      className={cn(
        useTextStyles({ variant: "cabron" }),
        useStyles({ type }),
        className,
      )}
      {...props}
    >
      <>{children}</>
    </Link>
  );
};
Link.displayName = "Link";
