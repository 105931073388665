import { cn } from "../../../lib/utils";
import { HTMLAttributes } from "react";
import { useTextStyles } from "../Text";

export const TableCell = ({
  className,
  ...props
}: HTMLAttributes<HTMLTableDataCellElement>) => (
  <td
    className={cn(
      "p-4 [&:has([role=checkbox])]:pr-0",
      useTextStyles({}),
      className,
    )}
    {...props}
  />
);
TableCell.displayName = "Table.Cell";
