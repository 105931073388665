import { makeApi, Zodios, type ZodiosOptions } from "@zodios/core";
import { ZodiosHooks } from "@zodios/react";
import { z } from "zod";

const createOrRefreshABearerToken_Body = z
  .object({
    grant_type: z.string(),
    client_id: z.number().int(),
    client_secret: z.string(),
    username: z.string().optional(),
    password: z.string().optional(),
    refresh_token: z.string().optional(),
    scope: z.string().optional(),
  })
  .passthrough();
const handleALoginRequestToTheApplication_Body = z
  .object({ email: z.string(), password: z.string() })
  .passthrough();
const handleARegistrationRequestForTheApplication_Body = z
  .object({
    type: z.string(),
    first_name: z.string(),
    last_name: z.string().optional(),
    email: z.string(),
    password: z.string(),
    password_confirmation: z.string(),
    phone: z.string(),
    "g-recaptcha-response": z.string(),
    title: z.string(),
    nonce: z.string(),
    idempotency_key: z.string(),
  })
  .passthrough();
const confirmToken = z.string();
const sendAResetLinkToTheGivenUser_Body = z
  .object({ email: z.string() })
  .passthrough();
const page = z.number().int().optional();
const filters = z.array(z.object({}).partial().passthrough()).optional();
const withs = z.array(z.string()).optional();
const filters_column = z.string().optional();
const filters_operator = z
  .enum([
    "=",
    "!=",
    "<",
    "<=",
    ">",
    ">=",
    "in",
    "not in",
    "between",
    "like",
    "not like",
    "ilike",
    "not ilike",
    "null",
    "not null",
  ])
  .optional();
const orderBys_direction = z.enum(["asc", "desc"]).optional();
const filters_ = z.object({}).partial().passthrough().optional();
const createDiscount_Body = z
  .object({
    code: z.string(),
    type: z.enum([
      "entireOrder",
      "privateOrder",
      "privateOrGroupOrder",
      "bookingPartnerMsFees",
      "instructorMsFees",
      "groupOrder",
      "equipment",
    ]),
    isRebook: z.boolean(),
    ownerType: z.literal("user").optional(),
    ownerId: z.number().int().optional(),
    usesRemaining: z.number().int().optional(),
    percent: z.number().optional(),
    valueAmount: z.number().int().optional(),
    valueCurrency: z.enum(["EUR", "CHF", "GBP"]).optional(),
    minRequiredValue: z.number().int().optional(),
    validAt: z.string().optional(),
    expiresAt: z.string().optional(),
    locale: z.string().optional(),
    withs: z.array(z.string()).optional(),
    withCounts: z.array(z.string()).optional(),
  })
  .passthrough();
const id = z.number().int();
const updateDiscount_Body = z
  .object({
    code: z.string(),
    type: z.enum([
      "entireOrder",
      "privateOrder",
      "privateOrGroupOrder",
      "bookingPartnerMsFees",
      "instructorMsFees",
      "groupOrder",
      "equipment",
    ]),
    isRebook: z.boolean(),
    ownerType: z.literal("user"),
    ownerId: z.number().int(),
    usesRemaining: z.number().int(),
    percent: z.number(),
    valueAmount: z.number().int(),
    valueCurrency: z.enum(["EUR", "CHF", "GBP"]),
    minRequiredValue: z.number().int(),
    validAt: z.string(),
    expiresAt: z.string(),
    locale: z.string(),
    withs: z.array(z.string()),
    withCounts: z.array(z.string()),
  })
  .partial()
  .passthrough();
const createEnquiry_Body = z
  .object({
    state: z.enum(["messagedinstructor", "searched"]),
    customerId: z.number().int().optional(),
    instructorId: z.number().int().optional(),
    resortId: z.number().int().optional(),
    sportId: z.number().int().optional(),
    abilityLevel: z
      .enum([
        "beginner",
        "intermediate",
        "advanced",
        "expert",
        "off_piste",
        "mixed",
      ])
      .optional(),
    ageGroup: z.enum(["children", "adults", "mixed"]).optional(),
    participants: z.number().int().optional(),
    language: z
      .enum([
        "en",
        "fr",
        "it",
        "de",
        "ru",
        "ar",
        "zh",
        "jp",
        "es",
        "pt",
        "sv",
        "no",
        "nl",
        "hi",
        "pl",
        "ro",
        "hu",
        "cs",
        "he",
        "da",
      ])
      .optional(),
    timeslots: z
      .array(
        z
          .object({
            start: z.string(),
            end: z.string(),
            isFullDay: z.boolean(),
          })
          .partial()
          .passthrough()
      )
      .optional(),
    isTimeFlexible: z.boolean().optional(),
    startsAt: z.string().optional(),
    endsAt: z.string().optional(),
    hours: z.number().int().optional(),
    searchFrom: z.string().optional(),
    searchTo: z.string().optional(),
    bookingId: z.number().int().optional(),
    isActive: z.boolean().optional(),
    fromEnquiryV2Id: z.number().int().optional(),
    snoozedUntil: z.string().optional(),
    details: z.string().optional(),
    message: z.string().optional(),
    locale: z.string().optional(),
    withs: z.array(z.string()).optional(),
    withCounts: z.array(z.string()).optional(),
  })
  .passthrough();
const currency = z.enum(["EUR", "CHF", "GBP"]);
const createInstructorProfile_Body = z
  .object({
    bio: z.string(),
    isActive: z.boolean(),
    seasonStart: z.string(),
    seasonEnd: z.string(),
    offeredEndorsements: z.array(z.string()),
    hobbies: z.array(z.string()),
    whatToExpect: z.object({}).partial().passthrough(),
    whatToExpectPerLanguage: z.object({}).partial().passthrough(),
    whatToExpectImages: z.object({}).partial().passthrough(),
    locale: z.string(),
    withs: z.array(z.string()),
    withCounts: z.array(z.string()),
  })
  .partial()
  .passthrough();
const createMessage_Body = z
  .object({
    content: z.string(),
    toUserId: z.number().int(),
    threadId: z.number().int().optional(),
    locale: z.string().optional(),
    withs: z.array(z.string()).optional(),
    withCounts: z.array(z.string()).optional(),
  })
  .passthrough();
const createOrder_Body = z
  .object({
    valueCurrency: z.enum(["EUR", "CHF", "GBP"]),
    paymentCurrency: z.enum(["EUR", "CHF", "GBP"]),
    expectedPaymentValue: z.number().int(),
    paymentMethodId: z.string().optional(),
    externalPaymentMethodId: z.string().optional(),
    paymentIntentId: z.string().optional(),
    customerId: z.number().int().optional(),
    privateOrders: z
      .array(
        z
          .object({
            instructorId: z.number().int(),
            resortId: z.number().int(),
            sportId: z.number().int(),
            slots: z
              .array(
                z
                  .object({
                    start: z.string(),
                    end: z.string(),
                    isFullDay: z.boolean(),
                  })
                  .passthrough()
              )
              .optional(),
            expectedPaymentValue: z.number().int(),
            paymentPercent: z.never().optional(),
            ageOfParticipants: z
              .enum(["children", "adults", "mixed"])
              .optional(),
            abilityLevel: z
              .enum([
                "beginner",
                "intermediate",
                "advanced",
                "expert",
                "off_piste",
                "mixed",
              ])
              .optional(),
            numberOfParticipants: z.number().int().optional(),
            language: z
              .enum([
                "en",
                "fr",
                "it",
                "de",
                "ru",
                "ar",
                "zh",
                "jp",
                "es",
                "pt",
                "sv",
                "no",
                "nl",
                "hi",
                "pl",
                "ro",
                "hu",
                "cs",
                "he",
                "da",
              ])
              .optional(),
          })
          .passthrough()
      )
      .optional(),
    groupOrders: z
      .array(
        z
          .object({
            start: z.string(),
            end: z.string(),
            resortId: z.number().int(),
            abilityLevel: z.enum([
              "any",
              "zero",
              "one",
              "two",
              "three",
              "four",
            ]),
            groupType: z.enum([
              "any",
              "adult",
              "children_single",
              "children_double",
              "children_three_hours",
              "supreme_performance_camps",
              "private_snowboard",
            ]),
            skiers: z
              .array(
                z
                  .object({
                    firstName: z.string(),
                    lastName: z.string(),
                    age: z.number().int(),
                    illnessDescription: z.string().optional(),
                  })
                  .passthrough()
              )
              .optional(),
            expectedPaymentValue: z.number().int(),
            paymentPercent: z.never().optional(),
          })
          .passthrough()
      )
      .optional(),
    equipment: z
      .array(
        z
          .object({
            resortId: z.number().int(),
            startDate: z.string(),
            duration: z.number().int(),
            packs: z
              .array(
                z
                  .object({
                    id: z.number().int(),
                    firstName: z.string(),
                    lastName: z.string(),
                    age: z.number().int(),
                    helmet: z.boolean(),
                  })
                  .passthrough()
              )
              .optional(),
            expectedPaymentValue: z.number().int(),
            paymentPercent: z.never().optional(),
          })
          .passthrough()
      )
      .optional(),
    externalCustomerEmail: z.string().optional(),
    discountCode: z.string().optional(),
    locale: z.string().optional(),
    withs: z.array(z.string()).optional(),
    withCounts: z.array(z.string()).optional(),
  })
  .passthrough();
const createPaymentMethod_Body = z
  .object({
    remoteId: z.string(),
    paymentProvider: z.enum(["stripe", "maisonsport"]),
    userId: z.number().int().optional(),
    locale: z.string().optional(),
    withs: z.array(z.string()).optional(),
    withCounts: z.array(z.string()).optional(),
  })
  .passthrough();
const updatePaymentMethod_Body = z
  .object({
    isDefault: z.boolean(),
    locale: z.string().optional(),
    withs: z.array(z.string()).optional(),
    withCounts: z.array(z.string()).optional(),
  })
  .passthrough();
const setupIntentforLaterPayment_Body = z
  .object({
    paymentProvider: z.enum(["stripe", "maisonsport"]),
    userId: z.number().int().optional(),
  })
  .passthrough();
const createPayment_Body = z
  .object({
    orderId: z.number().int(),
    paymentCurrency: z.enum(["EUR", "CHF", "GBP"]),
    expectedPaymentValue: z.number().int(),
    paymentMethodId: z.string().optional(),
    externalPaymentMethodId: z.string().optional(),
    paymentIntentId: z.string().optional(),
    actualCustomerEmail: z.string().optional(),
    locale: z.string().optional(),
    withs: z.array(z.string()).optional(),
    withCounts: z.array(z.string()).optional(),
  })
  .passthrough();
const createPayoutAccount_Body = z
  .object({
    userId: z.number().int(),
    name: z.string(),
    isPrimary: z.boolean(),
    currency: z.enum(["EUR", "CHF", "GBP"]),
    firstName: z.string(),
    lastName: z.string(),
    city: z.string(),
    postcode: z.string(),
    sortCode: z.string().optional(),
    accountNumber: z.string().optional(),
    iban: z.string().optional(),
    bic: z.string().optional(),
    locale: z.string().optional(),
    withs: z.array(z.string()).optional(),
    withCounts: z.array(z.string()).optional(),
  })
  .passthrough();
const getPricing_Body = z
  .object({
    paymentCurrency: z.enum(["EUR", "CHF", "GBP"]),
    customerId: z.number().int().optional(),
    privateOrders: z
      .array(
        z
          .object({
            instructorId: z.number().int(),
            resortId: z.number().int().optional(),
            sportId: z.number().int().optional(),
            slots: z
              .array(
                z
                  .object({
                    start: z.string(),
                    end: z.string(),
                    isFullDay: z.boolean(),
                  })
                  .passthrough()
              )
              .optional(),
            paymentPercent: z.never().optional(),
            ageOfParticipants: z
              .enum(["children", "adults", "mixed"])
              .optional(),
            abilityLevel: z
              .enum([
                "beginner",
                "intermediate",
                "advanced",
                "expert",
                "off_piste",
                "mixed",
              ])
              .optional(),
            numberOfParticipants: z.number().int().optional(),
            language: z
              .enum([
                "en",
                "fr",
                "it",
                "de",
                "ru",
                "ar",
                "zh",
                "jp",
                "es",
                "pt",
                "sv",
                "no",
                "nl",
                "hi",
                "pl",
                "ro",
                "hu",
                "cs",
                "he",
                "da",
              ])
              .optional(),
            slotsMustBeValid: z.boolean().optional(),
          })
          .passthrough()
      )
      .optional(),
    groupOrders: z
      .array(
        z
          .object({
            start: z.string(),
            end: z.string(),
            resortId: z.number().int(),
            abilityLevel: z.enum([
              "any",
              "zero",
              "one",
              "two",
              "three",
              "four",
            ]),
            groupType: z.enum([
              "any",
              "adult",
              "children_single",
              "children_double",
              "children_three_hours",
              "supreme_performance_camps",
              "private_snowboard",
            ]),
            skiers: z
              .array(
                z
                  .object({
                    firstName: z.string(),
                    lastName: z.string(),
                    age: z.number().int(),
                    illnessDescription: z.string(),
                  })
                  .partial()
                  .passthrough()
              )
              .optional(),
            paymentPercent: z.never().optional(),
            participants: z.string().optional(),
          })
          .passthrough()
      )
      .optional(),
    equipment: z
      .array(
        z
          .object({
            resortId: z.number().int(),
            startDate: z.string(),
            duration: z.number().int(),
            packs: z
              .array(
                z
                  .object({
                    id: z.number().int(),
                    firstName: z.string().optional(),
                    lastName: z.string().optional(),
                    age: z.number().int().optional(),
                    helmet: z.boolean(),
                  })
                  .passthrough()
              )
              .optional(),
            paymentPercent: z.never().optional(),
          })
          .passthrough()
      )
      .optional(),
    externalCustomerEmail: z.string().optional(),
    discountCode: z.string().optional(),
    locale: z.string().optional(),
    withs: z.array(z.string()).optional(),
    withCounts: z.array(z.string()).optional(),
  })
  .passthrough();
const createResort_Body = z
  .object({
    group: z.string().optional(),
    countryCode: z.enum([
      "FR",
      "GB",
      "CH",
      "IT",
      "US",
      "CA",
      "AU",
      "DE",
      "IS",
      "IE",
      "ES",
      "SE",
      "AT",
      "BE",
      "FI",
      "CZ",
      "DK",
      "NO",
      "NZ",
      "RU",
      "PT",
      "NL",
      "IM",
      "AF",
      "AX",
      "AL",
      "DZ",
      "AS",
      "AD",
      "AO",
      "AI",
      "AQ",
      "AG",
      "AR",
      "AM",
      "AW",
      "AZ",
      "BS",
      "BH",
      "BD",
      "BB",
      "BY",
      "BZ",
      "BJ",
      "BM",
      "BT",
      "BO",
      "BA",
      "BW",
      "BV",
      "BR",
      "IO",
      "BN",
      "BG",
      "BF",
      "BI",
      "KH",
      "CM",
      "CV",
      "KY",
      "CF",
      "TD",
      "CL",
      "CN",
      "CX",
      "CC",
      "CO",
      "KM",
      "CG",
      "CD",
      "CK",
      "CR",
      "CI",
      "HR",
      "CU",
      "CY",
      "DJ",
      "DM",
      "DO",
      "EC",
      "EG",
      "SV",
      "GQ",
      "ER",
      "EE",
      "ET",
      "FK",
      "FO",
      "FJ",
      "GF",
      "PF",
      "TF",
      "GA",
      "GM",
      "GE",
      "GH",
      "GI",
      "GR",
      "GL",
      "GD",
      "GP",
      "GU",
      "GT",
      "GG",
      "GN",
      "GW",
      "GY",
      "HT",
      "HM",
      "VA",
      "HN",
      "HK",
      "HU",
      "IN",
      "ID",
      "IR",
      "IQ",
      "IL",
      "JM",
      "JP",
      "JE",
      "JO",
      "KZ",
      "KE",
      "KI",
      "KP",
      "KR",
      "KW",
      "KG",
      "LA",
      "LV",
      "LB",
      "LS",
      "LR",
      "LY",
      "LI",
      "LT",
      "LU",
      "MO",
      "MK",
      "MG",
      "MW",
      "MY",
      "MV",
      "ML",
      "MT",
      "MH",
      "MQ",
      "MR",
      "MU",
      "YT",
      "MX",
      "FM",
      "MD",
      "MC",
      "MN",
      "ME",
      "MS",
      "MA",
      "MZ",
      "MM",
      "NA",
      "NR",
      "NP",
      "NC",
      "NI",
      "NE",
      "NG",
      "NU",
      "NF",
      "MP",
      "OM",
      "PK",
      "PW",
      "PS",
      "PA",
      "PG",
      "PY",
      "PE",
      "PH",
      "PN",
      "PL",
      "PR",
      "QA",
      "RE",
      "RO",
      "RW",
      "BL",
      "SH",
      "KN",
      "LC",
      "PM",
      "VC",
      "WS",
      "SM",
      "ST",
      "SA",
      "SN",
      "RS",
      "SC",
      "SL",
      "SG",
      "SK",
      "SI",
      "SB",
      "SO",
      "ZA",
      "GS",
      "LK",
      "SD",
      "SR",
      "SJ",
      "SZ",
      "SY",
      "TW",
      "TJ",
      "TZ",
      "TH",
      "TL",
      "TG",
      "TK",
      "TO",
      "TT",
      "TN",
      "TR",
      "TM",
      "TC",
      "TV",
      "UG",
      "UA",
      "AE",
      "UM",
      "UY",
      "UZ",
      "VU",
      "VE",
      "VN",
      "VG",
      "VI",
      "WF",
      "EH",
      "YE",
      "ZM",
      "ZW",
    ]),
    slug: z.string(),
    name: z.string(),
    locale: z.string().optional(),
    withs: z.array(z.string()).optional(),
    withCounts: z.array(z.string()).optional(),
  })
  .passthrough();
const updateResort_Body = z
  .object({
    group: z.string().optional(),
    name: z.string(),
    slug: z.string().optional(),
    locale: z.string().optional(),
    withs: z.array(z.string()).optional(),
    withCounts: z.array(z.string()).optional(),
  })
  .passthrough();
const createReview_Body = z
  .object({
    friendliness: z.enum([
      "0.5",
      "1",
      "1.5",
      "2",
      "2.5",
      "3",
      "3.5",
      "4",
      "4.5",
      "5",
    ]),
    timeKeeping: z.enum([
      "0.5",
      "1",
      "1.5",
      "2",
      "2.5",
      "3",
      "3.5",
      "4",
      "4.5",
      "5",
    ]),
    communication: z.enum([
      "0.5",
      "1",
      "1.5",
      "2",
      "2.5",
      "3",
      "3.5",
      "4",
      "4.5",
      "5",
    ]),
    technicalFeedback: z.enum([
      "0.5",
      "1",
      "1.5",
      "2",
      "2.5",
      "3",
      "3.5",
      "4",
      "4.5",
      "5",
    ]),
    bookingExperience: z.enum([
      "0.5",
      "1",
      "1.5",
      "2",
      "2.5",
      "3",
      "3.5",
      "4",
      "4.5",
      "5",
    ]),
    publicReview: z.string(),
    improvement: z.string().optional(),
    endorsements: z.array(z.string()).optional(),
    bookingId: z.number().int(),
    locale: z.string().optional(),
    withs: z.array(z.string()).optional(),
    withCounts: z.array(z.string()).optional(),
  })
  .passthrough();
const updateReview_Body = z
  .object({
    friendliness: z.enum([
      "0.5",
      "1",
      "1.5",
      "2",
      "2.5",
      "3",
      "3.5",
      "4",
      "4.5",
      "5",
    ]),
    timeKeeping: z.enum([
      "0.5",
      "1",
      "1.5",
      "2",
      "2.5",
      "3",
      "3.5",
      "4",
      "4.5",
      "5",
    ]),
    communication: z.enum([
      "0.5",
      "1",
      "1.5",
      "2",
      "2.5",
      "3",
      "3.5",
      "4",
      "4.5",
      "5",
    ]),
    technicalFeedback: z.enum([
      "0.5",
      "1",
      "1.5",
      "2",
      "2.5",
      "3",
      "3.5",
      "4",
      "4.5",
      "5",
    ]),
    bookingExperience: z.enum([
      "0.5",
      "1",
      "1.5",
      "2",
      "2.5",
      "3",
      "3.5",
      "4",
      "4.5",
      "5",
    ]),
    publicReview: z.string(),
    improvement: z.string().optional(),
    endorsements: z.array(z.string()).optional(),
    locale: z.string().optional(),
    withs: z.array(z.string()).optional(),
    withCounts: z.array(z.string()).optional(),
  })
  .passthrough();
const updateUser_Body = z
  .object({
    firstName: z.string(),
    lastName: z.string(),
    dateOfBirth: z.string(),
    gender: z.enum(["m", "f"]),
    phone: z.string(),
    nationality: z.string(),
    countryOfResidence: z.string(),
    howDidYouFindUs: z.string(),
    email: z.string(),
    oldPassword: z.string(),
    newPassword: z.string(),
    newPassword_confirmation: z.string(),
    locale: z.string(),
    withs: z.array(z.string()),
    withCounts: z.array(z.string()),
  })
  .partial()
  .passthrough();

const schemas = {
  createOrRefreshABearerToken_Body,
  handleALoginRequestToTheApplication_Body,
  handleARegistrationRequestForTheApplication_Body,
  confirmToken,
  sendAResetLinkToTheGivenUser_Body,
  page,
  filters,
  withs,
  filters_column,
  filters_operator,
  orderBys_direction,
  filters_,
  createDiscount_Body,
  id,
  updateDiscount_Body,
  createEnquiry_Body,
  currency,
  createInstructorProfile_Body,
  createMessage_Body,
  createOrder_Body,
  createPaymentMethod_Body,
  updatePaymentMethod_Body,
  setupIntentforLaterPayment_Body,
  createPayment_Body,
  createPayoutAccount_Body,
  getPricing_Body,
  createResort_Body,
  updateResort_Body,
  createReview_Body,
  updateReview_Body,
  updateUser_Body,
};

const endpoints = makeApi([
  {
    method: "post",
    path: "/api/v1/auth/confirm-email/:confirmToken",
    alias: "emailConfirmationRoute",
    requestFormat: "json",
    parameters: [
      {
        name: "confirmToken",
        type: "Path",
        schema: confirmToken,
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/api/v1/auth/login",
    alias: "handleALoginRequestToTheApplication",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: handleALoginRequestToTheApplication_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/api/v1/auth/logout",
    alias: "logTheUserOutOfTheApplication",
    requestFormat: "json",
    response: z.void(),
  },
  {
    method: "post",
    path: "/api/v1/auth/register",
    alias: "handleARegistrationRequestForTheApplication",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: handleARegistrationRequestForTheApplication_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/api/v1/auth/reset-password/email",
    alias: "sendAResetLinkToTheGivenUser",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: sendAResetLinkToTheGivenUser_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/api/v1/auth/reset-password/reset",
    alias: "resetTheGivenUsersPassword",
    requestFormat: "json",
    response: z.void(),
  },
  {
    method: "get",
    path: "/api/v1/blog-posts",
    alias: "listBlogPosts",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: page,
      },
      {
        name: "perPage",
        type: "Query",
        schema: page,
      },
      {
        name: "filters",
        type: "Query",
        schema: filters,
      },
      {
        name: "orderBys",
        type: "Query",
        schema: filters,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "filters[].operator",
        type: "Query",
        schema: filters_operator,
      },
      {
        name: "filters[].value",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].direction",
        type: "Query",
        schema: orderBys_direction,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[]",
        type: "Query",
        schema: filters_,
      },
      {
        name: "orderBys[]",
        type: "Query",
        schema: filters_,
      },
    ],
    response: z
      .object({
        data: z.array(z.array(z.any())),
        from: z.number().int(),
        to: z.number().int(),
        total: z.number().int(),
        perPage: z.number().int(),
        page: z.number().int(),
        lastPage: z.number().int(),
      })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 422,
        schema: z
          .object({
            message: z.string(),
            errors: z
              .object({
                "filters.0.column": z.array(z.string()),
                "filters.0.operator": z.array(z.string()),
                "orderBys.0.column": z.array(z.string()),
                "orderBys.0.direction": z.array(z.string()),
              })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/api/v1/csrf",
    alias: "setCSRFTokenIntoTheSession",
    requestFormat: "json",
    response: z.void(),
  },
  {
    method: "get",
    path: "/api/v1/discounts",
    alias: "listDiscounts",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: page,
      },
      {
        name: "perPage",
        type: "Query",
        schema: page,
      },
      {
        name: "filters",
        type: "Query",
        schema: filters,
      },
      {
        name: "orderBys",
        type: "Query",
        schema: filters,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "filters[].operator",
        type: "Query",
        schema: filters_operator,
      },
      {
        name: "filters[].value",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].direction",
        type: "Query",
        schema: orderBys_direction,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[]",
        type: "Query",
        schema: filters_,
      },
      {
        name: "orderBys[]",
        type: "Query",
        schema: filters_,
      },
    ],
    response: z
      .object({
        data: z.array(
          z
            .object({
              id: z.number().int(),
              code: z.string(),
              type: z.string(),
              isRebook: z.boolean(),
              ownerType: z.string(),
              ownerId: z.string(),
              usesRemaining: z.string(),
              percent: z.number().int(),
              valueAmount: z.string(),
              valueCurrency: z.string(),
              minRequiredValue: z.string(),
              validAt: z.string(),
              expiresAt: z.string(),
            })
            .partial()
            .passthrough()
        ),
        from: z.number().int(),
        to: z.number().int(),
        total: z.number().int(),
        perPage: z.number().int(),
        page: z.number().int(),
        lastPage: z.number().int(),
      })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 422,
        schema: z
          .object({
            message: z.string(),
            errors: z
              .object({
                "filters.0.column": z.array(z.string()),
                "filters.0.operator": z.array(z.string()),
                "orderBys.0.column": z.array(z.string()),
                "orderBys.0.direction": z.array(z.string()),
              })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/api/v1/discounts",
    alias: "createDiscount",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: createDiscount_Body,
      },
    ],
    response: z
      .object({
        data: z
          .object({
            id: z.number().int(),
            code: z.string(),
            type: z.string(),
            isRebook: z.boolean(),
            ownerType: z.string(),
            ownerId: z.string(),
            usesRemaining: z.string(),
            percent: z.number().int(),
            valueAmount: z.string(),
            valueCurrency: z.string(),
            minRequiredValue: z.string(),
            validAt: z.string(),
            expiresAt: z.string(),
          })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/v1/discounts/:id",
    alias: "getDiscount",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: id,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "locale",
        type: "Query",
        schema: filters_column,
      },
    ],
    response: z
      .object({
        data: z
          .object({
            id: z.number().int(),
            code: z.string(),
            type: z.string(),
            isRebook: z.boolean(),
            ownerType: z.string(),
            ownerId: z.string(),
            usesRemaining: z.string(),
            percent: z.number().int(),
            valueAmount: z.string(),
            valueCurrency: z.string(),
            minRequiredValue: z.string(),
            validAt: z.string(),
            expiresAt: z.string(),
          })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 403,
        schema: z
          .object({
            message: z.string(),
            exception: z.string(),
            file: z.string(),
            line: z.number().int(),
            trace: z.array(
              z
                .object({
                  file: z.string(),
                  line: z.number().int(),
                  function: z.string(),
                  class: z.string(),
                  type: z.string(),
                })
                .partial()
                .passthrough()
            ),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "put",
    path: "/api/v1/discounts/:id",
    alias: "updateDiscount",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: updateDiscount_Body.optional(),
      },
      {
        name: "id",
        type: "Path",
        schema: id,
      },
    ],
    response: z
      .object({
        data: z
          .object({
            id: z.number().int(),
            code: z.string(),
            type: z.string(),
            isRebook: z.boolean(),
            ownerType: z.string(),
            ownerId: z.string(),
            usesRemaining: z.string(),
            percent: z.number().int(),
            valueAmount: z.string(),
            valueCurrency: z.string(),
            minRequiredValue: z.string(),
            validAt: z.string(),
            expiresAt: z.string(),
          })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/v1/discounts/:id",
    alias: "deleteDiscount",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: id,
      },
    ],
    response: z.void(),
  },
  {
    method: "get",
    path: "/api/v1/discounts/route-key/:routeKey",
    alias: "getDiscountByRouteKey",
    requestFormat: "json",
    parameters: [
      {
        name: "routeKey",
        type: "Path",
        schema: id,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "locale",
        type: "Query",
        schema: filters_column,
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 404,
        schema: z
          .object({
            message: z.string(),
            exception: z.string(),
            file: z.string(),
            line: z.number().int(),
            trace: z.array(
              z
                .object({
                  file: z.string(),
                  line: z.number().int(),
                  function: z.string(),
                  class: z.string(),
                  type: z.string(),
                })
                .partial()
                .passthrough()
            ),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/api/v1/enquiries",
    alias: "createEnquiry",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: createEnquiry_Body,
      },
    ],
    response: z
      .object({
        data: z
          .object({
            id: z.number().int(),
            state: z.string(),
            customerId: z.number().int(),
            instructorId: z.string(),
            resortId: z.string(),
            sportId: z.string(),
            abilityLevel: z.string(),
            ageGroup: z.string(),
            participants: z.string(),
            language: z.string(),
            timeslots: z.string(),
            isTimeFlexible: z.string(),
            startsAt: z.string(),
            endsAt: z.string(),
            hours: z.string(),
            searchFrom: z.string(),
            searchTo: z.string(),
            messageThreadId: z.string(),
            bookingId: z.string(),
            createdAt: z.string(),
            updatedAt: z.string(),
            deletedAt: z.string(),
            isActive: z.boolean(),
            fromEnquiryV2Id: z.string(),
            snoozedUntil: z.string(),
            details: z.string(),
            customer: z.array(z.any()),
            bookingValue: z.string(),
            endDate: z.string(),
            routeKey: z.string(),
            searchParams: z.array(z.any()),
            startDate: z.string(),
            instructor: z.string(),
          })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/v1/equipment",
    alias: "listEquipment",
    requestFormat: "json",
    parameters: [
      {
        name: "resortId",
        type: "Query",
        schema: id,
      },
      {
        name: "startDate",
        type: "Query",
        schema: confirmToken,
      },
      {
        name: "currency",
        type: "Query",
        schema: currency,
      },
    ],
    response: z.array(z.object({}).partial().passthrough()),
  },
  {
    method: "post",
    path: "/api/v1/instructor-profiles",
    alias: "createInstructorProfile",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: createInstructorProfile_Body.optional(),
      },
    ],
    response: z
      .object({
        data: z
          .object({
            id: z.number().int(),
            bio: z.string(),
            season: z
              .object({ start: z.string(), end: z.string() })
              .partial()
              .passthrough(),
            cancellationPolicy: z.string(),
            rating: z.string(),
            yearsExperience: z.string(),
            intro: z.string(),
            responseTime: z.string(),
            skills: z.string(),
            expect: z.string(),
            hasAward: z.boolean(),
            isBookable: z.boolean(),
            price: z.number().int(),
            currency: z
              .object({ code: z.string(), symbol: z.string() })
              .partial()
              .passthrough(),
            responseTimeForHumans: z.string(),
            routeKey: z.string(),
            isVerified: z.boolean(),
            mainCertification: z.array(z.any()),
            sportsBios: z.array(z.any()),
            upcomingBookingWithUser: z.boolean(),
            lowestHourlyRate: z
              .object({
                amount: z.number().int(),
                currency: z
                  .object({ code: z.string(), symbol: z.string() })
                  .partial()
                  .passthrough(),
                text: z.string(),
              })
              .partial()
              .passthrough(),
            offeredEndorsements: z.array(z.any()),
            hobbies: z.array(z.any()),
            hasTrophy: z.boolean(),
            resortsCount: z.string(),
            reviewsCount: z.string(),
            whatToExpect: z.array(z.any()),
            whatToExpectImages: z
              .object({
                snowboarding: z.string(),
                skiing: z.string(),
                cross_country_skiing: z.string(),
                ski_touring: z.string(),
                telemark: z.string(),
                off_piste_skiing: z.string(),
                off_piste_snowboarding: z.string(),
                sit_ski: z.string(),
                visually_impaired: z.string(),
                adaptive_snowboarding: z.string(),
                three_track_skiing: z.string(),
                adult_group_lessons: z.string(),
                childrens_group_lessons: z.string(),
              })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/v1/instructor-profiles/:id",
    alias: "updateInstructorProfile",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: createInstructorProfile_Body.optional(),
      },
      {
        name: "id",
        type: "Path",
        schema: id,
      },
    ],
    response: z
      .object({
        data: z
          .object({
            id: z.number().int(),
            bio: z.string(),
            season: z
              .object({ start: z.string(), end: z.string() })
              .partial()
              .passthrough(),
            cancellationPolicy: z.string(),
            rating: z.string(),
            yearsExperience: z.string(),
            intro: z.string(),
            responseTime: z.string(),
            skills: z.string(),
            expect: z.string(),
            hasAward: z.boolean(),
            isBookable: z.boolean(),
            price: z.number().int(),
            currency: z
              .object({ code: z.string(), symbol: z.string() })
              .partial()
              .passthrough(),
            responseTimeForHumans: z.string(),
            routeKey: z.string(),
            isVerified: z.boolean(),
            mainCertification: z.array(z.any()),
            sportsBios: z.array(z.any()),
            upcomingBookingWithUser: z.boolean(),
            lowestHourlyRate: z
              .object({
                amount: z.number().int(),
                currency: z
                  .object({ code: z.string(), symbol: z.string() })
                  .partial()
                  .passthrough(),
                text: z.string(),
              })
              .partial()
              .passthrough(),
            offeredEndorsements: z.array(z.any()),
            hobbies: z.array(z.any()),
            hasTrophy: z.boolean(),
            resortsCount: z.string(),
            reviewsCount: z.string(),
            whatToExpect: z.array(z.any()),
            whatToExpectImages: z
              .object({
                snowboarding: z.string(),
                skiing: z.string(),
                cross_country_skiing: z.string(),
                ski_touring: z.string(),
                telemark: z.string(),
                off_piste_skiing: z.string(),
                off_piste_snowboarding: z.string(),
                sit_ski: z.string(),
                visually_impaired: z.string(),
                adaptive_snowboarding: z.string(),
                three_track_skiing: z.string(),
                adult_group_lessons: z.string(),
                childrens_group_lessons: z.string(),
              })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/v1/instructor-slots",
    alias: "listInstructorSlots",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: page,
      },
      {
        name: "perPage",
        type: "Query",
        schema: page,
      },
      {
        name: "filters",
        type: "Query",
        schema: filters,
      },
      {
        name: "orderBys",
        type: "Query",
        schema: filters,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "filters[].operator",
        type: "Query",
        schema: filters_operator,
      },
      {
        name: "filters[].value",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].direction",
        type: "Query",
        schema: orderBys_direction,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[]",
        type: "Query",
        schema: filters_,
      },
      {
        name: "orderBys[]",
        type: "Query",
        schema: filters_,
      },
    ],
    response: z
      .object({
        data: z.array(
          z
            .object({
              id: z.string(),
              instructorProfileId: z.number().int(),
              bookingRuleId: z.string(),
              calendarDate: z.string(),
              start: z.string(),
              end: z.string(),
              type: z.string(),
              isAvailable: z.boolean(),
              notAvailableReason: z.string(),
              resortIds: z.array(z.any()),
              sportIds: z.array(z.any()),
              bookingRuleEnd: z.string(),
              profile: z.string(),
              price: z
                .object({
                  amount: z.number().int(),
                  currency: z
                    .object({ code: z.string(), symbol: z.string() })
                    .partial()
                    .passthrough(),
                  text: z.string(),
                })
                .partial()
                .passthrough(),
              pricePerInterval: z
                .object({
                  amount: z.number().int(),
                  currency: z
                    .object({ code: z.string(), symbol: z.string() })
                    .partial()
                    .passthrough(),
                  text: z.string(),
                })
                .partial()
                .passthrough(),
              intervals: z.number().int(),
              discount: z.string(),
            })
            .partial()
            .passthrough()
        ),
        from: z.number().int(),
        to: z.number().int(),
        total: z.number().int(),
        perPage: z.number().int(),
        page: z.number().int(),
        lastPage: z.number().int(),
      })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 422,
        schema: z
          .object({
            message: z.string(),
            errors: z
              .object({
                "filters.0.column": z.array(z.string()),
                "filters.0.operator": z.array(z.string()),
                "orderBys.0.column": z.array(z.string()),
                "orderBys.0.direction": z.array(z.string()),
              })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/api/v1/locale/:lang",
    alias: "setTheLocaleInTheSessionandOnTheUserInstanceIfLoggedIn",
    requestFormat: "json",
    parameters: [
      {
        name: "lang",
        type: "Path",
        schema: confirmToken,
      },
    ],
    response: z.void(),
  },
  {
    method: "get",
    path: "/api/v1/messages",
    alias: "listMessages",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: page,
      },
      {
        name: "perPage",
        type: "Query",
        schema: page,
      },
      {
        name: "filters",
        type: "Query",
        schema: filters,
      },
      {
        name: "orderBys",
        type: "Query",
        schema: filters,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "filters[].operator",
        type: "Query",
        schema: filters_operator,
      },
      {
        name: "filters[].value",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].direction",
        type: "Query",
        schema: orderBys_direction,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[]",
        type: "Query",
        schema: filters_,
      },
      {
        name: "orderBys[]",
        type: "Query",
        schema: filters_,
      },
    ],
    response: z
      .object({
        data: z.array(
          z
            .object({ id: z.number().int(), content: z.string() })
            .partial()
            .passthrough()
        ),
        from: z.number().int(),
        to: z.number().int(),
        total: z.number().int(),
        perPage: z.number().int(),
        page: z.number().int(),
        lastPage: z.number().int(),
      })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 401,
        schema: z
          .object({
            errors: z
              .object({ unauthenticated: z.string() })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/api/v1/messages",
    alias: "createMessage",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: createMessage_Body,
      },
    ],
    response: z
      .object({
        data: z
          .object({ id: z.number().int(), content: z.string() })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/v1/messages/:id",
    alias: "getMessage",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: id,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "locale",
        type: "Query",
        schema: filters_column,
      },
    ],
    response: z
      .object({
        data: z
          .object({ id: z.number().int(), content: z.string() })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 401,
        schema: z
          .object({
            errors: z
              .object({ unauthenticated: z.string() })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/api/v1/messages/route-key/:routeKey",
    alias: "getMessageByRouteKey",
    requestFormat: "json",
    parameters: [
      {
        name: "routeKey",
        type: "Path",
        schema: id,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "locale",
        type: "Query",
        schema: filters_column,
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        schema: z
          .object({
            errors: z
              .object({ unauthenticated: z.string() })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/api/v1/orders",
    alias: "listOrders",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: page,
      },
      {
        name: "perPage",
        type: "Query",
        schema: page,
      },
      {
        name: "filters",
        type: "Query",
        schema: filters,
      },
      {
        name: "orderBys",
        type: "Query",
        schema: filters,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "filters[].operator",
        type: "Query",
        schema: filters_operator,
      },
      {
        name: "filters[].value",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].direction",
        type: "Query",
        schema: orderBys_direction,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[]",
        type: "Query",
        schema: filters_,
      },
      {
        name: "orderBys[]",
        type: "Query",
        schema: filters_,
      },
    ],
    response: z
      .object({
        data: z.array(
          z
            .object({
              id: z.number().int(),
              equipment: z.array(z.any()),
              privateOrders: z.array(z.any()),
              groupOrders: z.array(z.any()),
              amountDue: z
                .object({
                  amount: z.number().int(),
                  currency: z
                    .object({ code: z.string(), symbol: z.string() })
                    .partial()
                    .passthrough(),
                  text: z.string(),
                })
                .partial()
                .passthrough(),
              totalPaid: z
                .object({
                  amount: z.number().int(),
                  currency: z
                    .object({ code: z.string(), symbol: z.string() })
                    .partial()
                    .passthrough(),
                  text: z.string(),
                })
                .partial()
                .passthrough(),
              totalValue: z
                .object({
                  amount: z.number().int(),
                  currency: z
                    .object({ code: z.string(), symbol: z.string() })
                    .partial()
                    .passthrough(),
                  text: z.string(),
                })
                .partial()
                .passthrough(),
              discountCode: z.string(),
              discountPercent: z.number().int(),
              discountValueAmount: z
                .object({
                  amount: z.number().int(),
                  currency: z
                    .object({ code: z.string(), symbol: z.string() })
                    .partial()
                    .passthrough(),
                  text: z.string(),
                })
                .partial()
                .passthrough(),
              discountPaymentAmount: z
                .object({
                  amount: z.number().int(),
                  currency: z
                    .object({ code: z.string(), symbol: z.string() })
                    .partial()
                    .passthrough(),
                  text: z.string(),
                })
                .partial()
                .passthrough(),
              createdAt: z.string(),
              updatedAt: z.string(),
              deletedAt: z.string(),
            })
            .partial()
            .passthrough()
        ),
        from: z.number().int(),
        to: z.number().int(),
        total: z.number().int(),
        perPage: z.number().int(),
        page: z.number().int(),
        lastPage: z.number().int(),
      })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 401,
        schema: z
          .object({
            errors: z
              .object({ unauthenticated: z.string() })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/api/v1/orders",
    alias: "createOrder",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: createOrder_Body,
      },
    ],
    response: z
      .object({
        data: z
          .object({
            id: z.number().int(),
            equipment: z.array(z.any()),
            privateOrders: z.array(z.any()),
            groupOrders: z.array(z.any()),
            amountDue: z
              .object({
                amount: z.number().int(),
                currency: z
                  .object({ code: z.string(), symbol: z.string() })
                  .partial()
                  .passthrough(),
                text: z.string(),
              })
              .partial()
              .passthrough(),
            totalPaid: z
              .object({
                amount: z.number().int(),
                currency: z
                  .object({ code: z.string(), symbol: z.string() })
                  .partial()
                  .passthrough(),
                text: z.string(),
              })
              .partial()
              .passthrough(),
            totalValue: z
              .object({
                amount: z.number().int(),
                currency: z
                  .object({ code: z.string(), symbol: z.string() })
                  .partial()
                  .passthrough(),
                text: z.string(),
              })
              .partial()
              .passthrough(),
            discountCode: z.string(),
            discountPercent: z.number().int(),
            discountValueAmount: z
              .object({
                amount: z.number().int(),
                currency: z
                  .object({ code: z.string(), symbol: z.string() })
                  .partial()
                  .passthrough(),
                text: z.string(),
              })
              .partial()
              .passthrough(),
            discountPaymentAmount: z
              .object({
                amount: z.number().int(),
                currency: z
                  .object({ code: z.string(), symbol: z.string() })
                  .partial()
                  .passthrough(),
                text: z.string(),
              })
              .partial()
              .passthrough(),
            createdAt: z.string(),
            updatedAt: z.string(),
            deletedAt: z.string(),
          })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/v1/orders/:id",
    alias: "getOrder",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: id,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "locale",
        type: "Query",
        schema: filters_column,
      },
    ],
    response: z
      .object({
        data: z
          .object({
            id: z.number().int(),
            equipment: z.array(z.any()),
            privateOrders: z.array(z.any()),
            groupOrders: z.array(z.any()),
            amountDue: z
              .object({
                amount: z.number().int(),
                currency: z
                  .object({ code: z.string(), symbol: z.string() })
                  .partial()
                  .passthrough(),
                text: z.string(),
              })
              .partial()
              .passthrough(),
            totalPaid: z
              .object({
                amount: z.number().int(),
                currency: z
                  .object({ code: z.string(), symbol: z.string() })
                  .partial()
                  .passthrough(),
                text: z.string(),
              })
              .partial()
              .passthrough(),
            totalValue: z
              .object({
                amount: z.number().int(),
                currency: z
                  .object({ code: z.string(), symbol: z.string() })
                  .partial()
                  .passthrough(),
                text: z.string(),
              })
              .partial()
              .passthrough(),
            discountCode: z.string(),
            discountPercent: z.number().int(),
            discountValueAmount: z
              .object({
                amount: z.number().int(),
                currency: z
                  .object({ code: z.string(), symbol: z.string() })
                  .partial()
                  .passthrough(),
                text: z.string(),
              })
              .partial()
              .passthrough(),
            discountPaymentAmount: z
              .object({
                amount: z.number().int(),
                currency: z
                  .object({ code: z.string(), symbol: z.string() })
                  .partial()
                  .passthrough(),
                text: z.string(),
              })
              .partial()
              .passthrough(),
            createdAt: z.string(),
            updatedAt: z.string(),
            deletedAt: z.string(),
          })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 401,
        schema: z
          .object({
            errors: z
              .object({ unauthenticated: z.string() })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "delete",
    path: "/api/v1/orders/:id",
    alias: "deleteOrder",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: id,
      },
    ],
    response: z.void(),
  },
  {
    method: "get",
    path: "/api/v1/orders/external/:routeKey",
    alias: "unauthedGetOrder",
    requestFormat: "json",
    parameters: [
      {
        name: "routeKey",
        type: "Path",
        schema: confirmToken,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "locale",
        type: "Query",
        schema: filters_column,
      },
    ],
    response: z
      .object({
        data: z
          .object({
            id: z.number().int(),
            equipment: z.array(z.any()),
            privateOrders: z.array(z.any()),
            groupOrders: z.array(z.any()),
            amountDue: z
              .object({
                amount: z.number().int(),
                currency: z
                  .object({ code: z.string(), symbol: z.string() })
                  .partial()
                  .passthrough(),
                text: z.string(),
              })
              .partial()
              .passthrough(),
            totalPaid: z
              .object({
                amount: z.number().int(),
                currency: z
                  .object({ code: z.string(), symbol: z.string() })
                  .partial()
                  .passthrough(),
                text: z.string(),
              })
              .partial()
              .passthrough(),
            totalValue: z
              .object({
                amount: z.number().int(),
                currency: z
                  .object({ code: z.string(), symbol: z.string() })
                  .partial()
                  .passthrough(),
                text: z.string(),
              })
              .partial()
              .passthrough(),
            discountCode: z.string(),
            discountPercent: z.number().int(),
            discountValueAmount: z
              .object({
                amount: z.number().int(),
                currency: z
                  .object({ code: z.string(), symbol: z.string() })
                  .partial()
                  .passthrough(),
                text: z.string(),
              })
              .partial()
              .passthrough(),
            discountPaymentAmount: z
              .object({
                amount: z.number().int(),
                currency: z
                  .object({ code: z.string(), symbol: z.string() })
                  .partial()
                  .passthrough(),
                text: z.string(),
              })
              .partial()
              .passthrough(),
            createdAt: z.string(),
            updatedAt: z.string(),
            deletedAt: z.string(),
          })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 404,
        schema: z
          .object({
            message: z.string(),
            exception: z.string(),
            file: z.string(),
            line: z.number().int(),
            trace: z.array(
              z
                .object({
                  file: z.string(),
                  line: z.number().int(),
                  function: z.string(),
                  class: z.string(),
                  type: z.string(),
                })
                .partial()
                .passthrough()
            ),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/api/v1/orders/route-key/:routeKey",
    alias: "getOrderByRouteKey",
    requestFormat: "json",
    parameters: [
      {
        name: "routeKey",
        type: "Path",
        schema: id,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "locale",
        type: "Query",
        schema: filters_column,
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        schema: z
          .object({
            errors: z
              .object({ unauthenticated: z.string() })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/api/v1/payment-methods",
    alias: "listPaymentMethods",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: page,
      },
      {
        name: "perPage",
        type: "Query",
        schema: page,
      },
      {
        name: "filters",
        type: "Query",
        schema: filters,
      },
      {
        name: "orderBys",
        type: "Query",
        schema: filters,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "filters[].operator",
        type: "Query",
        schema: filters_operator,
      },
      {
        name: "filters[].value",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].direction",
        type: "Query",
        schema: orderBys_direction,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[]",
        type: "Query",
        schema: filters_,
      },
      {
        name: "orderBys[]",
        type: "Query",
        schema: filters_,
      },
    ],
    response: z
      .object({
        data: z.array(
          z
            .object({
              id: z.string(),
              isDefault: z.boolean(),
              paymentProvider: z.string(),
              remoteId: z.string(),
              billableId: z.string(),
              type: z.string(),
              data: z
                .object({
                  last4: z.string(),
                  provider: z.string(),
                  expiryMonth: z.number().int(),
                  expiryYear: z.number().int(),
                })
                .partial()
                .passthrough(),
              createdAt: z.string(),
              updatedAt: z.string(),
              deletedAt: z.string(),
            })
            .partial()
            .passthrough()
        ),
        from: z.number().int(),
        to: z.number().int(),
        total: z.number().int(),
        perPage: z.number().int(),
        page: z.number().int(),
        lastPage: z.number().int(),
      })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 401,
        schema: z
          .object({
            errors: z
              .object({ unauthenticated: z.string() })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/api/v1/payment-methods",
    alias: "createPaymentMethod",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: createPaymentMethod_Body,
      },
    ],
    response: z
      .object({
        id: z.string(),
        isDefault: z.boolean(),
        paymentProvider: z.string(),
        remoteId: z.string(),
        billableId: z.string(),
        type: z.string(),
        data: z
          .object({
            last4: z.string(),
            provider: z.string(),
            expiryMonth: z.number().int(),
            expiryYear: z.number().int(),
          })
          .partial()
          .passthrough(),
        createdAt: z.string(),
        updatedAt: z.string(),
        deletedAt: z.string(),
      })
      .partial()
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/v1/payment-methods/:id",
    alias: "getPaymentMethod",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: confirmToken,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "locale",
        type: "Query",
        schema: filters_column,
      },
    ],
    response: z
      .object({
        id: z.string(),
        isDefault: z.boolean(),
        paymentProvider: z.string(),
        remoteId: z.string(),
        billableId: z.string(),
        type: z.string(),
        data: z
          .object({
            last4: z.string(),
            provider: z.string(),
            expiryMonth: z.number().int(),
            expiryYear: z.number().int(),
          })
          .partial()
          .passthrough(),
        createdAt: z.string(),
        updatedAt: z.string(),
        deletedAt: z.string(),
      })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 401,
        schema: z
          .object({
            errors: z
              .object({ unauthenticated: z.string() })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "put",
    path: "/api/v1/payment-methods/:id",
    alias: "updatePaymentMethod",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: updatePaymentMethod_Body,
      },
      {
        name: "id",
        type: "Path",
        schema: confirmToken,
      },
    ],
    response: z
      .object({
        id: z.string(),
        isDefault: z.boolean(),
        paymentProvider: z.string(),
        remoteId: z.string(),
        billableId: z.string(),
        type: z.string(),
        data: z
          .object({
            last4: z.string(),
            provider: z.string(),
            expiryMonth: z.number().int(),
            expiryYear: z.number().int(),
          })
          .partial()
          .passthrough(),
        createdAt: z.string(),
        updatedAt: z.string(),
        deletedAt: z.string(),
      })
      .partial()
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/v1/payment-methods/:id",
    alias: "deletePaymentMethod",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: confirmToken,
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/api/v1/payment-methods/intent",
    alias: "setupIntentforLaterPayment",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: setupIntentforLaterPayment_Body,
      },
    ],
    response: z.object({ clientSecret: z.string() }).partial().passthrough(),
  },
  {
    method: "get",
    path: "/api/v1/payment-methods/route-key/:routeKey",
    alias: "getPaymentMethodByRouteKey",
    requestFormat: "json",
    parameters: [
      {
        name: "routeKey",
        type: "Path",
        schema: id,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "locale",
        type: "Query",
        schema: filters_column,
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        schema: z
          .object({
            errors: z
              .object({ unauthenticated: z.string() })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/api/v1/payments",
    alias: "createPayment",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: createPayment_Body,
      },
    ],
    response: z
      .object({
        data: z
          .object({
            id: z.string(),
            amount: z
              .object({
                amount: z.number().int(),
                currency: z
                  .object({ code: z.string(), symbol: z.string() })
                  .partial()
                  .passthrough(),
                text: z.string(),
              })
              .partial()
              .passthrough(),
            currencyCode: z.string(),
            paymentMethodId: z.string(),
            type: z.string(),
            description: z.string(),
            remoteId: z.string(),
            parentId: z.string(),
            payableType: z.string(),
            payableId: z.number().int(),
            createdAt: z.string(),
            updatedAt: z.string(),
            state: z.string(),
            actualAmount: z
              .object({
                amount: z.number().int(),
                currency: z
                  .object({ code: z.string(), symbol: z.string() })
                  .partial()
                  .passthrough(),
                text: z.string(),
              })
              .partial()
              .passthrough(),
            actualCurrencyCode: z.string(),
          })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/v1/payout-accounts",
    alias: "listPayoutAccounts",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: page,
      },
      {
        name: "perPage",
        type: "Query",
        schema: page,
      },
      {
        name: "filters",
        type: "Query",
        schema: filters,
      },
      {
        name: "orderBys",
        type: "Query",
        schema: filters,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "filters[].operator",
        type: "Query",
        schema: filters_operator,
      },
      {
        name: "filters[].value",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].direction",
        type: "Query",
        schema: orderBys_direction,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[]",
        type: "Query",
        schema: filters_,
      },
      {
        name: "orderBys[]",
        type: "Query",
        schema: filters_,
      },
    ],
    response: z
      .object({
        data: z.array(
          z
            .object({
              id: z.number().int(),
              userId: z.number().int(),
              name: z.string(),
              isPrimary: z.boolean(),
              service: z.string(),
              externalId: z.number().int(),
              currency: z.string(),
              firstName: z.string(),
              lastName: z.string(),
              postcode: z.string(),
              city: z.string(),
              accountData: z
                .object({
                  user: z.array(z.any()),
                  currency: z
                    .object({ code: z.string(), symbol: z.string() })
                    .partial()
                    .passthrough(),
                  firstName: z.string(),
                  lastName: z.string(),
                  city: z.string(),
                  postcode: z.string(),
                  name: z.string(),
                  isPrimary: z.boolean(),
                  isActive: z.boolean(),
                })
                .partial()
                .passthrough(),
            })
            .partial()
            .passthrough()
        ),
        from: z.number().int(),
        to: z.number().int(),
        total: z.number().int(),
        perPage: z.number().int(),
        page: z.number().int(),
        lastPage: z.number().int(),
      })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 401,
        schema: z
          .object({
            errors: z
              .object({ unauthenticated: z.string() })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/api/v1/payout-accounts",
    alias: "createPayoutAccount",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: createPayoutAccount_Body,
      },
    ],
    response: z
      .object({
        data: z
          .object({
            id: z.number().int(),
            userId: z.number().int(),
            name: z.string(),
            isPrimary: z.boolean(),
            service: z.string(),
            externalId: z.number().int(),
            currency: z.string(),
            firstName: z.string(),
            lastName: z.string(),
            postcode: z.string(),
            city: z.string(),
            accountData: z
              .object({
                user: z.array(z.any()),
                currency: z
                  .object({ code: z.string(), symbol: z.string() })
                  .partial()
                  .passthrough(),
                firstName: z.string(),
                lastName: z.string(),
                city: z.string(),
                postcode: z.string(),
                name: z.string(),
                isPrimary: z.boolean(),
                isActive: z.boolean(),
              })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/v1/payout-accounts/:id",
    alias: "getPayoutAccount",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: id,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "locale",
        type: "Query",
        schema: filters_column,
      },
    ],
    response: z
      .object({
        data: z
          .object({
            id: z.number().int(),
            userId: z.number().int(),
            name: z.string(),
            isPrimary: z.boolean(),
            service: z.string(),
            externalId: z.number().int(),
            currency: z.string(),
            firstName: z.string(),
            lastName: z.string(),
            postcode: z.string(),
            city: z.string(),
            accountData: z
              .object({
                user: z.array(z.any()),
                currency: z
                  .object({ code: z.string(), symbol: z.string() })
                  .partial()
                  .passthrough(),
                firstName: z.string(),
                lastName: z.string(),
                city: z.string(),
                postcode: z.string(),
                name: z.string(),
                isPrimary: z.boolean(),
                isActive: z.boolean(),
              })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 404,
        schema: z
          .object({
            message: z.string(),
            exception: z.string(),
            file: z.string(),
            line: z.number().int(),
            trace: z.array(
              z
                .object({
                  file: z.string(),
                  line: z.number().int(),
                  function: z.string(),
                  class: z.string(),
                  type: z.string(),
                })
                .partial()
                .passthrough()
            ),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "delete",
    path: "/api/v1/payout-accounts/:id",
    alias: "deletePayoutAccount",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: id,
      },
    ],
    response: z.void(),
  },
  {
    method: "get",
    path: "/api/v1/payout-accounts/route-key/:routeKey",
    alias: "getPayoutAccountByRouteKey",
    requestFormat: "json",
    parameters: [
      {
        name: "routeKey",
        type: "Path",
        schema: id,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "locale",
        type: "Query",
        schema: filters_column,
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        schema: z
          .object({
            errors: z
              .object({ unauthenticated: z.string() })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/api/v1/payouts",
    alias: "listPayouts",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: page,
      },
      {
        name: "perPage",
        type: "Query",
        schema: page,
      },
      {
        name: "filters",
        type: "Query",
        schema: filters,
      },
      {
        name: "orderBys",
        type: "Query",
        schema: filters,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "filters[].operator",
        type: "Query",
        schema: filters_operator,
      },
      {
        name: "filters[].value",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].direction",
        type: "Query",
        schema: orderBys_direction,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[]",
        type: "Query",
        schema: filters_,
      },
      {
        name: "orderBys[]",
        type: "Query",
        schema: filters_,
      },
    ],
    response: z
      .object({
        data: z.array(
          z
            .object({
              id: z.number().int(),
              reference: z.string(),
              amount: z
                .object({
                  amount: z.number().int(),
                  currency: z
                    .object({ code: z.string(), symbol: z.string() })
                    .partial()
                    .passthrough(),
                  text: z.string(),
                })
                .partial()
                .passthrough(),
              transactionUuid: z.string(),
              externalId: z.number().int(),
              state: z.string(),
            })
            .partial()
            .passthrough()
        ),
        from: z.number().int(),
        to: z.number().int(),
        total: z.number().int(),
        perPage: z.number().int(),
        page: z.number().int(),
        lastPage: z.number().int(),
      })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 401,
        schema: z
          .object({
            errors: z
              .object({ unauthenticated: z.string() })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/api/v1/payouts/:id",
    alias: "getPayout",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: id,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "locale",
        type: "Query",
        schema: filters_column,
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 404,
        schema: z
          .object({
            message: z.string(),
            exception: z.string(),
            file: z.string(),
            line: z.number().int(),
            trace: z.array(
              z
                .object({
                  file: z.string(),
                  line: z.number().int(),
                  function: z.string(),
                  class: z.string(),
                  type: z.string(),
                })
                .partial()
                .passthrough()
            ),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/api/v1/payouts/route-key/:routeKey",
    alias: "getPayoutByRouteKey",
    requestFormat: "json",
    parameters: [
      {
        name: "routeKey",
        type: "Path",
        schema: id,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "locale",
        type: "Query",
        schema: filters_column,
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        schema: z
          .object({
            errors: z
              .object({ unauthenticated: z.string() })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/api/v1/pricing",
    alias: "getPricing",
    immutable: true,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: getPricing_Body,
      },
    ],
    response: z
      .object({
        calculation: z
          .object({
            currency: z
              .object({ code: z.string(), symbol: z.string() })
              .partial()
              .passthrough(),
            valueNoDiscount: z
              .object({
                amount: z.number().int(),
                currency: z
                  .object({ code: z.string(), symbol: z.string() })
                  .partial()
                  .passthrough(),
                text: z.string(),
              })
              .partial()
              .passthrough(),
            valueWithDiscount: z
              .object({
                amount: z.number().int(),
                currency: z
                  .object({ code: z.string(), symbol: z.string() })
                  .partial()
                  .passthrough(),
                text: z.string(),
              })
              .partial()
              .passthrough(),
            valueOfDiscount: z.string(),
            items: z.array(
              z
                .object({
                  description: z.string(),
                  type: z.string(),
                  valueNoDiscount: z
                    .object({
                      amount: z.number().int(),
                      currency: z
                        .object({ code: z.string(), symbol: z.string() })
                        .partial()
                        .passthrough(),
                      text: z.string(),
                    })
                    .partial()
                    .passthrough(),
                  valueWithDiscount: z
                    .object({
                      amount: z.number().int(),
                      currency: z
                        .object({ code: z.string(), symbol: z.string() })
                        .partial()
                        .passthrough(),
                      text: z.string(),
                    })
                    .partial()
                    .passthrough(),
                  valueOfDiscount: z.string(),
                  discount: z.string(),
                  subItems: z.array(
                    z
                      .object({
                        description: z.string(),
                        type: z.string(),
                        valueNoDiscount: z
                          .object({
                            amount: z.number().int(),
                            currency: z
                              .object({ code: z.string(), symbol: z.string() })
                              .partial()
                              .passthrough(),
                            text: z.string(),
                          })
                          .partial()
                          .passthrough(),
                        valueWithDiscount: z
                          .object({
                            amount: z.number().int(),
                            currency: z
                              .object({ code: z.string(), symbol: z.string() })
                              .partial()
                              .passthrough(),
                            text: z.string(),
                          })
                          .partial()
                          .passthrough(),
                        valueOfDiscount: z.string(),
                        discount: z.string(),
                        subItems: z.array(z.any()),
                        data: z
                          .object({
                            firstName: z.string(),
                            lastName: z.string(),
                            age: z.number().int(),
                            illnessDescription: z.string(),
                          })
                          .partial()
                          .passthrough(),
                      })
                      .partial()
                      .passthrough()
                  ),
                })
                .partial()
                .passthrough()
            ),
          })
          .partial()
          .passthrough(),
        groupOrders: z.array(
          z
            .object({
              groupPricing: z
                .object({
                  supremeResortId: z.number().int(),
                  meetingPointUrl: z.string(),
                  lessons: z.array(
                    z
                      .object({
                        availability: z.array(
                          z
                            .object({
                              abilityLevel: z.string(),
                              availableSpots: z.number().int(),
                              period: z
                                .object({ start: z.string(), end: z.string() })
                                .partial()
                                .passthrough(),
                            })
                            .partial()
                            .passthrough()
                        ),
                        fromFirstDayPrice: z
                          .object({
                            amount: z.number().int(),
                            currency: z
                              .object({ code: z.string() })
                              .partial()
                              .passthrough(),
                          })
                          .partial()
                          .passthrough(),
                        fromSecondDayPrice: z
                          .object({
                            amount: z.number().int(),
                            currency: z
                              .object({ code: z.string() })
                              .partial()
                              .passthrough(),
                          })
                          .partial()
                          .passthrough(),
                        maxSpots: z.number().int(),
                        groupType: z.string(),
                        gallery: z.array(z.string()),
                      })
                      .partial()
                      .passthrough()
                  ),
                  instructors: z.array(
                    z
                      .object({
                        id: z.number().int(),
                        name: z.string(),
                        avatar: z.string(),
                      })
                      .partial()
                      .passthrough()
                  ),
                  availableInstructors: z.array(
                    z
                      .object({
                        id: z.number().int(),
                        name: z.string(),
                        avatar: z.string(),
                      })
                      .partial()
                      .passthrough()
                  ),
                })
                .partial()
                .passthrough(),
            })
            .partial()
            .passthrough()
        ),
      })
      .partial()
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/v1/resorts",
    alias: "listResorts",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: page,
      },
      {
        name: "perPage",
        type: "Query",
        schema: page,
      },
      {
        name: "filters",
        type: "Query",
        schema: filters,
      },
      {
        name: "orderBys",
        type: "Query",
        schema: filters,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "filters[].operator",
        type: "Query",
        schema: filters_operator,
      },
      {
        name: "filters[].value",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].direction",
        type: "Query",
        schema: orderBys_direction,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[]",
        type: "Query",
        schema: filters_,
      },
      {
        name: "orderBys[]",
        type: "Query",
        schema: filters_,
      },
    ],
    response: z
      .object({
        data: z.array(
          z
            .object({
              id: z.number().int(),
              name: z.string(),
              slug: z.string(),
              countryCode: z.string(),
              currency: z
                .object({ code: z.string(), symbol: z.string() })
                .partial()
                .passthrough(),
              schoolRates: z.string(),
              instructorsCount: z.string(),
            })
            .partial()
            .passthrough()
        ),
        from: z.number().int(),
        to: z.number().int(),
        total: z.number().int(),
        perPage: z.number().int(),
        page: z.number().int(),
        lastPage: z.number().int(),
      })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 422,
        schema: z
          .object({
            message: z.string(),
            errors: z
              .object({
                "filters.0.column": z.array(z.string()),
                "filters.0.operator": z.array(z.string()),
                "orderBys.0.column": z.array(z.string()),
                "orderBys.0.direction": z.array(z.string()),
              })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/api/v1/resorts",
    alias: "createResort",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: createResort_Body,
      },
    ],
    response: z
      .object({
        data: z
          .object({
            id: z.number().int(),
            name: z.string(),
            slug: z.string(),
            countryCode: z.string(),
            currency: z
              .object({ code: z.string(), symbol: z.string() })
              .partial()
              .passthrough(),
            schoolRates: z.string(),
            instructorsCount: z.string(),
          })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/v1/resorts/:id",
    alias: "getResort",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: id,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "locale",
        type: "Query",
        schema: filters_column,
      },
    ],
    response: z
      .object({
        data: z
          .object({
            id: z.number().int(),
            name: z.string(),
            slug: z.string(),
            countryCode: z.string(),
            currency: z
              .object({ code: z.string(), symbol: z.string() })
              .partial()
              .passthrough(),
            schoolRates: z.string(),
            instructorsCount: z.string(),
          })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/v1/resorts/:id",
    alias: "updateResort",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: updateResort_Body,
      },
      {
        name: "id",
        type: "Path",
        schema: id,
      },
    ],
    response: z
      .object({
        data: z
          .object({
            id: z.number().int(),
            name: z.string(),
            slug: z.string(),
            countryCode: z.string(),
            currency: z
              .object({ code: z.string(), symbol: z.string() })
              .partial()
              .passthrough(),
            schoolRates: z.string(),
            instructorsCount: z.string(),
          })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/v1/resorts/:id",
    alias: "deleteResort",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: id,
      },
    ],
    response: z.void(),
  },
  {
    method: "get",
    path: "/api/v1/resorts/route-key/:routeKey",
    alias: "getResortByRouteKey",
    requestFormat: "json",
    parameters: [
      {
        name: "routeKey",
        type: "Path",
        schema: id,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "locale",
        type: "Query",
        schema: filters_column,
      },
    ],
    response: z
      .object({
        id: z.number().int(),
        name: z.string(),
        slug: z.string(),
        countryCode: z.string(),
        currency: z
          .object({ code: z.string(), symbol: z.string() })
          .partial()
          .passthrough(),
        schoolRates: z.string(),
        instructorsCount: z.string(),
      })
      .partial()
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/v1/reviews",
    alias: "listReviews",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: page,
      },
      {
        name: "perPage",
        type: "Query",
        schema: page,
      },
      {
        name: "filters",
        type: "Query",
        schema: filters,
      },
      {
        name: "orderBys",
        type: "Query",
        schema: filters,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "filters[].operator",
        type: "Query",
        schema: filters_operator,
      },
      {
        name: "filters[].value",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].direction",
        type: "Query",
        schema: orderBys_direction,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[]",
        type: "Query",
        schema: filters_,
      },
      {
        name: "orderBys[]",
        type: "Query",
        schema: filters_,
      },
    ],
    response: z
      .object({
        data: z.array(
          z
            .object({
              id: z.number().int(),
              publicReview: z.string(),
              stars: z.number().int(),
              friendliness: z.number().int(),
              timeKeeping: z.number().int(),
              communication: z.number().int(),
              technicalFeedback: z.number().int(),
              bookingExperience: z.number().int(),
              improvement: z.string(),
              endorsements: z.array(z.any()),
              instructorProfileId: z.number().int(),
              userId: z.number().int(),
              bookingId: z.number().int(),
              details: z
                .object({
                  customerName: z.string(),
                  instructorRouteKey: z.number().int(),
                  instructorName: z.string(),
                  instructorAvatar: z.string(),
                  hours: z.number().int(),
                  date: z.string(),
                  numberOfParticipants: z.number().int(),
                  resortSlug: z.string(),
                  sportSlug: z.string(),
                })
                .partial()
                .passthrough(),
              createdAt: z.string(),
              updatedAt: z.string(),
              deletedAt: z.string(),
            })
            .partial()
            .passthrough()
        ),
        from: z.number().int(),
        to: z.number().int(),
        total: z.number().int(),
        perPage: z.number().int(),
        page: z.number().int(),
        lastPage: z.number().int(),
      })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 422,
        schema: z
          .object({
            message: z.string(),
            errors: z
              .object({
                "filters.0.column": z.array(z.string()),
                "filters.0.operator": z.array(z.string()),
                "orderBys.0.column": z.array(z.string()),
                "orderBys.0.direction": z.array(z.string()),
              })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/api/v1/reviews",
    alias: "createReview",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: createReview_Body,
      },
    ],
    response: z
      .object({
        data: z
          .object({
            id: z.number().int(),
            publicReview: z.string(),
            stars: z.number().int(),
            friendliness: z.number().int(),
            timeKeeping: z.number().int(),
            communication: z.number().int(),
            technicalFeedback: z.number().int(),
            bookingExperience: z.number().int(),
            improvement: z.string(),
            endorsements: z.array(z.any()),
            instructorProfileId: z.number().int(),
            userId: z.number().int(),
            bookingId: z.number().int(),
            details: z
              .object({
                customerName: z.string(),
                instructorRouteKey: z.number().int(),
                instructorName: z.string(),
                instructorAvatar: z.string(),
                hours: z.number().int(),
                date: z.string(),
                numberOfParticipants: z.number().int(),
                resortSlug: z.string(),
                sportSlug: z.string(),
              })
              .partial()
              .passthrough(),
            createdAt: z.string(),
            updatedAt: z.string(),
            deletedAt: z.string(),
          })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/v1/reviews/:id",
    alias: "getReview",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: id,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "locale",
        type: "Query",
        schema: filters_column,
      },
    ],
    response: z
      .object({
        data: z
          .object({
            id: z.number().int(),
            publicReview: z.string(),
            stars: z.number().int(),
            friendliness: z.number().int(),
            timeKeeping: z.number().int(),
            communication: z.number().int(),
            technicalFeedback: z.number().int(),
            bookingExperience: z.number().int(),
            improvement: z.string(),
            endorsements: z.array(z.any()),
            instructorProfileId: z.number().int(),
            userId: z.number().int(),
            bookingId: z.number().int(),
            details: z
              .object({
                customerName: z.string(),
                instructorRouteKey: z.number().int(),
                instructorName: z.string(),
                instructorAvatar: z.string(),
                hours: z.number().int(),
                date: z.string(),
                numberOfParticipants: z.number().int(),
                resortSlug: z.string(),
                sportSlug: z.string(),
              })
              .partial()
              .passthrough(),
            createdAt: z.string(),
            updatedAt: z.string(),
            deletedAt: z.string(),
          })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 404,
        schema: z
          .object({
            message: z.string(),
            exception: z.string(),
            file: z.string(),
            line: z.number().int(),
            trace: z.array(
              z
                .object({
                  file: z.string(),
                  line: z.number().int(),
                  function: z.string(),
                  class: z.string(),
                  type: z.string(),
                })
                .partial()
                .passthrough()
            ),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "put",
    path: "/api/v1/reviews/:id",
    alias: "updateReview",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: updateReview_Body,
      },
      {
        name: "id",
        type: "Path",
        schema: id,
      },
    ],
    response: z
      .object({
        data: z
          .object({
            id: z.number().int(),
            publicReview: z.string(),
            stars: z.number().int(),
            friendliness: z.number().int(),
            timeKeeping: z.number().int(),
            communication: z.number().int(),
            technicalFeedback: z.number().int(),
            bookingExperience: z.number().int(),
            improvement: z.string(),
            endorsements: z.array(z.any()),
            instructorProfileId: z.number().int(),
            userId: z.number().int(),
            bookingId: z.number().int(),
            details: z
              .object({
                customerName: z.string(),
                instructorRouteKey: z.number().int(),
                instructorName: z.string(),
                instructorAvatar: z.string(),
                hours: z.number().int(),
                date: z.string(),
                numberOfParticipants: z.number().int(),
                resortSlug: z.string(),
                sportSlug: z.string(),
              })
              .partial()
              .passthrough(),
            createdAt: z.string(),
            updatedAt: z.string(),
            deletedAt: z.string(),
          })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/v1/reviews/:id",
    alias: "deleteReview",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: id,
      },
    ],
    response: z.void(),
  },
  {
    method: "get",
    path: "/api/v1/reviews/route-key/:routeKey",
    alias: "getReviewByRouteKey",
    requestFormat: "json",
    parameters: [
      {
        name: "routeKey",
        type: "Path",
        schema: id,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "locale",
        type: "Query",
        schema: filters_column,
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 404,
        schema: z
          .object({
            message: z.string(),
            exception: z.string(),
            file: z.string(),
            line: z.number().int(),
            trace: z.array(
              z
                .object({
                  file: z.string(),
                  line: z.number().int(),
                  function: z.string(),
                  class: z.string(),
                  type: z.string(),
                })
                .partial()
                .passthrough()
            ),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/api/v1/sports",
    alias: "listSports",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: page,
      },
      {
        name: "perPage",
        type: "Query",
        schema: page,
      },
      {
        name: "filters",
        type: "Query",
        schema: filters,
      },
      {
        name: "orderBys",
        type: "Query",
        schema: filters,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "filters[].operator",
        type: "Query",
        schema: filters_operator,
      },
      {
        name: "filters[].value",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].direction",
        type: "Query",
        schema: orderBys_direction,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[]",
        type: "Query",
        schema: filters_,
      },
      {
        name: "orderBys[]",
        type: "Query",
        schema: filters_,
      },
    ],
    response: z
      .object({
        data: z.array(
          z
            .object({
              id: z.number().int(),
              name: z.string(),
              slug: z.string(),
            })
            .partial()
            .passthrough()
        ),
        from: z.number().int(),
        to: z.number().int(),
        total: z.number().int(),
        perPage: z.number().int(),
        page: z.number().int(),
        lastPage: z.number().int(),
      })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 422,
        schema: z
          .object({
            message: z.string(),
            errors: z
              .object({
                "filters.0.column": z.array(z.string()),
                "filters.0.operator": z.array(z.string()),
                "orderBys.0.column": z.array(z.string()),
                "orderBys.0.direction": z.array(z.string()),
              })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/api/v1/sports/:id",
    alias: "getSport",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: id,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "locale",
        type: "Query",
        schema: filters_column,
      },
    ],
    response: z
      .object({
        data: z
          .object({ id: z.number().int(), name: z.string(), slug: z.string() })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/v1/sports/route-key/:routeKey",
    alias: "getSportByRouteKey",
    requestFormat: "json",
    parameters: [
      {
        name: "routeKey",
        type: "Path",
        schema: id,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "locale",
        type: "Query",
        schema: filters_column,
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 404,
        schema: z
          .object({
            message: z.string(),
            exception: z.string(),
            file: z.string(),
            line: z.number().int(),
            trace: z.array(
              z
                .object({
                  file: z.string(),
                  line: z.number().int(),
                  function: z.string(),
                  class: z.string(),
                  type: z.string(),
                })
                .partial()
                .passthrough()
            ),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/api/v1/users",
    alias: "listUsers",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: page,
      },
      {
        name: "perPage",
        type: "Query",
        schema: page,
      },
      {
        name: "filters",
        type: "Query",
        schema: filters,
      },
      {
        name: "orderBys",
        type: "Query",
        schema: filters,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "filters[].operator",
        type: "Query",
        schema: filters_operator,
      },
      {
        name: "filters[].value",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].column",
        type: "Query",
        schema: filters_column,
      },
      {
        name: "orderBys[].direction",
        type: "Query",
        schema: orderBys_direction,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "filters[]",
        type: "Query",
        schema: filters_,
      },
      {
        name: "orderBys[]",
        type: "Query",
        schema: filters_,
      },
    ],
    response: z
      .object({
        data: z.array(z.array(z.any())),
        from: z.number().int(),
        to: z.number().int(),
        total: z.number().int(),
        perPage: z.number().int(),
        page: z.number().int(),
        lastPage: z.number().int(),
      })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 422,
        schema: z
          .object({
            message: z.string(),
            errors: z
              .object({
                "filters.0.column": z.array(z.string()),
                "filters.0.operator": z.array(z.string()),
                "orderBys.0.column": z.array(z.string()),
                "orderBys.0.direction": z.array(z.string()),
              })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/api/v1/users/:id",
    alias: "getUser",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: id,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "locale",
        type: "Query",
        schema: filters_column,
      },
    ],
    response: z
      .object({ data: z.array(z.any()) })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 404,
        schema: z
          .object({
            message: z.string(),
            exception: z.string(),
            file: z.string(),
            line: z.number().int(),
            trace: z.array(
              z
                .object({
                  file: z.string(),
                  line: z.number().int(),
                  function: z.string(),
                  class: z.string(),
                  type: z.string(),
                })
                .partial()
                .passthrough()
            ),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "put",
    path: "/api/v1/users/:id",
    alias: "updateUser",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: updateUser_Body.optional(),
      },
      {
        name: "id",
        type: "Path",
        schema: id,
      },
    ],
    response: z
      .object({ data: z.array(z.any()) })
      .partial()
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/v1/users/instructor-route-key/:routeKey",
    alias: "getUserByInstructorRouteKey",
    requestFormat: "json",
    parameters: [
      {
        name: "routeKey",
        type: "Path",
        schema: id,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "locale",
        type: "Query",
        schema: filters_column,
      },
    ],
    response: z
      .object({ data: z.array(z.any()) })
      .partial()
      .passthrough(),
    errors: [
      {
        status: 404,
        schema: z
          .object({
            message: z.string(),
            exception: z.string(),
            file: z.string(),
            line: z.number().int(),
            trace: z.array(
              z
                .object({
                  file: z.string(),
                  line: z.number().int(),
                  function: z.string(),
                  class: z.string(),
                  type: z.string(),
                })
                .partial()
                .passthrough()
            ),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/api/v1/users/route-key/:routeKey",
    alias: "getUserByRouteKey",
    requestFormat: "json",
    parameters: [
      {
        name: "routeKey",
        type: "Path",
        schema: id,
      },
      {
        name: "withs",
        type: "Query",
        schema: withs,
      },
      {
        name: "withCounts",
        type: "Query",
        schema: withs,
      },
      {
        name: "locale",
        type: "Query",
        schema: filters_column,
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 404,
        schema: z
          .object({
            message: z.string(),
            exception: z.string(),
            file: z.string(),
            line: z.number().int(),
            trace: z.array(
              z
                .object({
                  file: z.string(),
                  line: z.number().int(),
                  function: z.string(),
                  class: z.string(),
                  type: z.string(),
                })
                .partial()
                .passthrough()
            ),
          })
          .partial()
          .passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/oauth/token",
    alias: "createOrRefreshABearerToken",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: createOrRefreshABearerToken_Body,
      },
    ],
    response: z
      .object({
        token_type: z.string(),
        expires_in: z.string(),
        access_token: z.string(),
        refresh_token: z.string(),
      })
      .partial()
      .passthrough(),
  },
]);

export type EndpointDefinitions = typeof endpoints;

export const endpointDefinitions = [...endpoints] as const;

export type EndpointDefinition = EndpointDefinitions[number];
export type EndpointPath = EndpointDefinitions[number]["path"];
export type EndpointMethod = EndpointDefinitions[number]["method"];

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  const zodios = new Zodios<EndpointDefinitions>(baseUrl, endpoints, options);

  const zodiosHooks = new ZodiosHooks<EndpointDefinitions>("ms", zodios, {
    shouldAbortOnUnmount: true,
  });

  return { api: zodiosHooks, zodios };
}
