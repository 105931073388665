import { Union } from "../../types";
import HighFive from "./HighFive";
import WavingTeam from "./WavingTeam";
import Pondering from "./Pondering";
import GuyHoldingSkis from "./GuyHoldingSkis";
import DesktopMan from "./DesktopMan";
import { HTMLAttributes } from "react";

const illustrations = {
  WavingTeam,
  HighFive,
  Pondering,
  GuyHoldingSkis,
  DesktopMan,
} as const;

type illustrationName = keyof typeof illustrations;
type IllustrationsProps = Union<
  {
    illustration: illustrationName;
  } & Pick<HTMLAttributes<HTMLOrSVGImageElement>, "className">
>;
export const Illustrations = ({
  illustration,
  className,
}: IllustrationsProps): JSX.Element => {
  const Illustration = illustrations[illustration];

  if (Illustration) {
    return <Illustration className={className} />;
  }

  return <></>;
};
Illustrations.displayName = "Illustrations";
