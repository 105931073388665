import { cn } from "../../../lib/utils";
import { HTMLDivAttributes, HTMLSelectAttributes, Union } from "../../../types";
import { Text, TextLabelProps } from "../../core/Text";
import { ErrorList, Props as ErrorListProps } from "../shared/ErrorList";
import {
  useStyles as useTextStyleInputUseStyles,
  Props as TextStyleInputContainerProps,
} from "../shared/TextStyleInputContainer";
import FilterField from "./FilterField";
import { Option, OptionProps } from "./options/Option";
import { OptionGroup } from "./options/OptionGroup";
import { Options, OptionsProps, OptionsGroupedProps } from "./options/options";
import * as Select from "@radix-ui/react-select";
import { cva, VariantProps } from "class-variance-authority";
import * as React from "react";
import { Chevron } from "../../icons";
import { useMemo } from "react";

const Label = ({ children, ...styleProps }: TextLabelProps) => {
  return <Text.Label {...styleProps}>{children}</Text.Label>;
};

const useStyles = cva([
  "appearance-none box-border h-12",
  "bg-transparent",
  "flex",
  "py-3",
  "text-xs text-black font-light",
  "disabled:text-ms-grey-dark disabled:cursor-not-allowed",
  "focus:outline-none",
]);
type StyleProps = VariantProps<typeof useStyles>;
export type SelectBoxProps = Union<
  StyleProps &
    Pick<HTMLSelectAttributes, "className" | "aria-label"> &
    Pick<ErrorListProps, "errors"> &
    Pick<TextStyleInputContainerProps, "Icon"> &
    Pick<OptionsProps, "value" | "searchable" | "filterFieldI18n"> &
    Partial<Pick<OptionsProps, "options">> &
    Partial<
      Pick<OptionsGroupedProps, "groups"> & {
        optionsPosition?: OptionsProps["position"];
        triggerClassName: HTMLDivAttributes["className"];

        label?: string;
        "data-testid"?: string;
        placeholder?: string;
        required?: boolean;
        disabled?: boolean;
        error?: boolean;
        hideErrors?: boolean;
        onSelect: (option: OptionProps["option"]) => void;
        onOpenChange: (
          open: boolean,
          triggerRef: HTMLButtonElement | null,
        ) => void;
        portalContainerId?: string;
      }
    >
>;
export const SelectBox = React.forwardRef<HTMLButtonElement, SelectBoxProps>(
  (
    {
      optionsPosition,
      triggerClassName,
      className,
      value,
      placeholder,
      onSelect,
      onOpenChange,
      label,
      error: _error,
      errors,
      disabled,
      options,
      groups,
      searchable,
      filterFieldI18n,
      hideErrors = false,
      Icon,
      portalContainerId,
      "data-testid": dataTestId,
      ...styleProps
    },
    forwardedRef,
  ) => {
    const [focussed, setFocussed] = React.useState<boolean>(false);

    React.useEffect(() => {
      return () => {
        setFocussed(false);
      };
    }, []);

    const hasErrorMessages = !!errors || !!errors?.length;

    const placeholderOption: OptionProps["option"] = {
      label: placeholder ?? "Please select an option",
      value: "",
    };

    const findSelectedOption = React.useCallback(
      (value: string): OptionProps["option"] => {
        if (!!options) {
          return options.find((o) => o.value === value) ?? placeholderOption;
        }

        if (!!groups) {
          let rtn: OptionProps["option"] = placeholderOption;
          for (const group of groups) {
            const { options: os } = group;
            const found = os.find((o) => o.value === value);
            if (found) {
              rtn = found;
              break;
            }
          }
          return rtn;
        }

        return placeholderOption;
      },
      [options, groups, placeholderOption],
    );

    const handleSelectChange: Select.SelectProps["onValueChange"] =
      React.useCallback(
        (value: string) => {
          if (!value) return;

          const option = findSelectedOption(value);
          onSelect && onSelect(option);
        },
        [findSelectedOption, onSelect],
      );

    const handleOnOpenChanged: Select.SelectProps["onOpenChange"] =
      React.useCallback(
        (open) => {
          setFocussed(open);
          onOpenChange && onOpenChange(open, forwardedRef?.current);
        },
        [setFocussed, onOpenChange, forwardedRef],
      );

    const selectedItemLabel = findSelectedOption(value).label;

    const optionsAndGroups = useMemo(
      () =>
        !!options ? (
          <Options
            options={options}
            value={value}
            searchable={searchable}
            filterFieldI18n={filterFieldI18n}
            open={focussed}
            position={optionsPosition}
            portalContainerId={portalContainerId}
            onPointerDownOutside={() => {
              setFocussed(false);
            }}
          />
        ) : !!groups ? (
          <Options.Grouped
            groups={groups}
            value={value}
            searchable={searchable}
            filterFieldI18n={filterFieldI18n}
            open={focussed}
            position={optionsPosition}
            portalContainerId={portalContainerId}
            onPointerDownOutside={() => {
              setFocussed(false);
            }}
          />
        ) : null,
      [
        options,
        value,
        searchable,
        filterFieldI18n,
        focussed,
        optionsPosition,
        portalContainerId,
        groups,
      ],
    );

    const SelectTrigger: typeof Select.Trigger = Select.Trigger;
    const SelectTriggerClasses = useTextStyleInputUseStyles({
      disabled,
      focussed,
      error: _error || hasErrorMessages,
      className: [
        "justify-between font-normal",
        selectedItemLabel === placeholderOption.label
          ? "text-ms-placeholder-grey"
          : "",
        focussed ? "drop-shadow-none" : "",
        "data-[state=open]:border-ms-primary",
        triggerClassName,
        "group",
      ],
    });

    return (
      <>
        <Label
          className={cn("relative flex flex-col gap-2", className)}
          variant="cabron"
          disabled={disabled}
          data-testid={dataTestId}
          {...styleProps}
        >
          {label && (
            <Text
              className="max-w-full truncate font-medium text-gray-500"
              variant="cabron"
              as="span"
            >
              {label}
              {styleProps.required && (
                <span className={"ml-1 text-ms-error"}>*</span>
              )}
            </Text>
          )}

          <Select.Root
            open={focussed}
            value={value}
            disabled={disabled}
            onOpenChange={handleOnOpenChanged}
            onValueChange={handleSelectChange}
          >
            <SelectTrigger
              className={cn(SelectTriggerClasses, "relative")}
              aria-label={label}
              ref={forwardedRef}
              data-testid="select-trigger"
            >
              <div className="relative flex w-full max-w-full items-center justify-items-start gap-2">
                {Icon && (
                  <Select.Icon className={" inline-block "}>
                    <Icon className={" h-4 w-4"} />
                  </Select.Icon>
                )}

                <Text
                  className="pointer-events-none bottom-0 left-0 right-5  top-0 flex flex-1 overflow-hidden text-left align-middle"
                  variant="cabron"
                  as="span"
                >
                  <span className="inline-block w-min max-w-full truncate">
                    {selectedItemLabel || placeholder}
                  </span>
                </Text>

                <Select.Icon
                  className={"flex w-auto items-center justify-self-end"}
                >
                  <Chevron
                    className={
                      "h-2 w-2 fill-none stroke-black transition ease-in-out group-data-[state=open]:rotate-180"
                    }
                  />
                </Select.Icon>
              </div>
            </SelectTrigger>

            {optionsAndGroups}
          </Select.Root>
        </Label>
        {!hideErrors && <ErrorList errors={errors} />}
      </>
    );
  },
);
SelectBox.displayName = "SelectBox";

SelectBox.FilterField = FilterField;
SelectBox.Options = Options;
SelectBox.Option = Option;
SelectBox.OptionGroup = OptionGroup;
