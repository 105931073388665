import { Resort } from "./pages/instructor-profile/types";
import { SelectBoxProps } from "./components";
import { popularResortSlugs } from "./constants/popularResorts";
import { DependencyList, useCallback, useEffect, useState } from "react";

const resortToOption = ({
  input,
  setValue,
}: {
  input: Resort;
  setValue?: (resort: Resort) => string;
}): SelectBoxProps["options"][number] => ({
  label: input.name,
  value: setValue ? setValue(input) : String(input.id),
});
export const getResortsAsSelectBoxOptions = ({
  resorts,
  displayAsGroups,
  setValue,
}: {
  resorts: Resort[];
  displayAsGroups?: boolean;
} & Pick<Parameters<typeof resortToOption>[0], "setValue">):
  | SelectBoxProps["options"]
  | SelectBoxProps["groups"] => {
  if (!resorts || !resorts.length) return [];

  if (displayAsGroups) {
    let popularResorts: Resort[] = [];
    let commonResorts: Resort[] = [];
    resorts.forEach((r) => {
      if (popularResortSlugs.includes(r.slug)) popularResorts.push(r);
      else commonResorts.push(r);
    });

    return [
      {
        label: "SelectBox.optgroup.popular_resorts",
        options: popularResorts.map((r) =>
          resortToOption({ input: r, setValue }),
        ),
      },
      {
        label: "---------------",
        options: commonResorts.map((r) =>
          resortToOption({ input: r, setValue }),
        ),
      },
    ] as SelectBoxProps["groups"];
  }

  return resorts.map((r) =>
    resortToOption({ input: r, setValue }),
  ) as SelectBoxProps["options"];
};

/**
 * @name sportIsPrivateBookable
 * @description Out sport list has an amalgamation of disciplines that transcend different business uses.
 * This function confirms weather or not a sport is indeed, valid for a lesson with a private instructor.
 * @param sportSlug string
 * @returns boolean
 * */
export const sportIsPrivateBookable = (sportSlug: string): boolean => {
  return (
    sportSlug !== "adult_group_lessons" &&
    sportSlug !== "childrens_group_lessons"
  );
};

export function useDebounceValue<T>(value: T, delay: number = 250): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}
