import {
  Dispatch,
  Fragment,
  HTMLAttributes,
  LinkHTMLAttributes,
  SetStateAction,
  StrictMode,
  Suspense,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { render } from "react-dom";
import "core-js/proposals/array-grouping-v2";
import "ui/styles.css";
import "./index.css";
import {
  Await,
  createBrowserRouter,
  createRoutesFromChildren,
  defer,
  LoaderFunctionArgs,
  matchRoutes,
  RouterProvider,
  useAsyncValue,
  useLoaderData,
  useLocation,
  useNavigate,
  useNavigationType,
  useRevalidator,
  useRouteError,
  useSearchParams,
  redirect,
} from "react-router-dom";
import {
  Button,
  Card,
  Checkbox,
  Illustrations,
  LanguagePicker,
  SelectBox,
  Table,
  Text,
  TextInput,
  TextInputContainer,
} from "ui";
import {
  CalendarIcon,
  CreditCardIcon,
  HouseIcon,
  LoadingSpinnerIcon,
  LocationDropperIcon,
  PadlockIcon,
  PaymentProviderIcon,
  ProfileIcon,
} from "ui/icons";
import * as Sentry from "@sentry/react";

import { createApi } from "api";
import { Controller, useForm } from "react-hook-form";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  StripeElementChangeEvent,
  StripeElementLocale,
  StripeElementsOptions,
  loadStripe,
} from "@stripe/stripe-js";
import {
  TCountryCode,
  getCountryData,
  getCountryDataList,
} from "countries-list";
import { I18nextProvider, Trans, useTranslation } from "react-i18next";

import i18n from "./i18n.ts";
import { LoadingSpinner } from "./components/LoadingSpinner.tsx";
import { EnvironmentContext } from "ui/environment";

const stripeOptions: StripeElementsOptions = {
  // fonts: [
  //   {
  //     family: "figtree",
  //     src: `url(/static/fonts/figtree/Figtree-Regular.ttf)`,
  //     weight: "400",
  //     display: "fallback",
  //     style: "normal",
  //   },
  // ],
  appearance: {
    rules: {
      ".Input": {
        fontFamily: "figtree",
        color: "black",
      },
      ".Input::placeholder": {
        fontFamily: "figtree",
        color: "#9ca3af",
      },
    },
  },
};

const stripePromise = loadStripe(
  `${import.meta.env.VITE_APP_STRIPE_PUBLISHED_KEY}`
);

const { zodios } = createApi(import.meta.env.VITE_APP_BACKEND_URL, {
  axiosConfig: {
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${"OGqKSwdypOf2fpfsWc2XtDXh4aO2aEjjOqYUmc4N"}`,
    },
  },
});

const NotFoundPage = () => {
  const { t } = useTranslation<unknown>();

  return (
    <main className="flex justify-center p-6 fixed top-0 left-0 right-0 bottom-0 bg-stone-100">
      <div className="flex gap-4 flex-col md:max-w-2xl max-w-sm w-full pt-16">
        <Text variant="title1">{t("ErrorPage.Heading")}</Text>
        <Text
          variant="sub1"
          as="p"
        >
          {t("NotFoundPage.Description")}
        </Text>
      </div>
    </main>
  );
};

export const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);
  const { t } = useTranslation<unknown>();

  return (
    <main className="flex justify-center p-6 fixed top-0 left-0 right-0 bottom-0 bg-stone-100">
      <div className="flex gap-4 flex-col md:max-w-2xl max-w-sm w-full pt-16">
        <Text variant="title1">{t("ErrorPage.Heading")}</Text>
        <Text
          variant="sub1"
          as="p"
        >
          {t("ErrorPage.Description")}
        </Text>
        <p>
          <i>{t("ErrorPage.Cta")}</i>
        </p>
      </div>
    </main>
  );
};

type CountryOption = {
  value: string;
  label: string;
};

const sortOptionAlphabetically = (
  a: CountryOption,
  b: CountryOption
): number => {
  if (a.label < b.label) return -1;
  if (a.label > b.label) return 1;
  return 0;
};

const priorityCountries = ["GB", "US", "FR", "DE", "NL", "ES", "IT", "CH"];

const counryDataList = getCountryDataList();

const countryOptions = [
  ...priorityCountries
    // .filter((country) => priorityCountries.includes(country.iso2))
    .map((code) => {
      const { iso2, native } = getCountryData(code as TCountryCode);

      return {
        value: iso2,
        label: native,
      };
    }),
  // .sort(sortOptionAlphabetically),
  ...counryDataList
    .filter((country) => !priorityCountries.includes(country.iso2))
    .map((country) => ({ value: country.iso2, label: country.native }))
    .sort(sortOptionAlphabetically),
];

const StripeProgressContext = createContext<{
  progress: boolean[];
  updateProgress: Dispatch<SetStateAction<boolean[]>>;
} | null>(null);

function useStripeStatus(progressIndex: number = 0) {
  const [focused, setFocussed] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const { progress, updateProgress } = useContext(StripeProgressContext)!;

  const onChange = useCallback(
    (e: StripeElementChangeEvent) => {
      if (e.error) {
        setErrors([e.error.message]);
      }
      if (errors.length > 0 && !e.error) setErrors([]);

      if (e.complete)
        updateProgress((progress) =>
          progress.map((val, index) => (index === progressIndex ? true : val))
        );
      else if (!e.complete && progress.at(progressIndex) === true)
        updateProgress((progress) =>
          progress.map((val, index) => (index === progressIndex ? false : val))
        );
    },
    [setErrors, errors.length, progress, updateProgress, progressIndex]
  );

  const onFocus = useCallback(() => setFocussed(true), [setFocussed]);
  const onBlur = useCallback(() => setFocussed(false), [setFocussed]);

  return {
    focused,
    errors,
    onChange,
    onFocus,
    onBlur,
  };
}

const CardNumberInput = ({ disabled }: { disabled: boolean }) => {
  const { focused, errors, ...elementProps } = useStripeStatus();

  return (
    <>
      <TextInputContainer
        Icon={CreditCardIcon}
        errors={errors}
        focussed={focused}
        disabled={disabled}
      >
        <CardNumberElement
          options={{
            disabled,
            placeholder: "0000 0000 0000 0000",
            style: {
              base: {
                lineHeight: "3.5rem",
                // fontFamily: "figtree",
                fontWeight: 400,
                // fontFamily: "figtree",
                fontSize: "16px",
                "::placeholder": {
                  color: "#9ca3af",
                },
              },
            },
          }}
          {...elementProps}
        />
      </TextInputContainer>
    </>
  );
};

const CardExpiryInput = ({ disabled }: { disabled: boolean }) => {
  const { focused, errors, ...elementProps } = useStripeStatus(1);
  return (
    <>
      <TextInputContainer
        outmostClassName="basis-3/5"
        Icon={CalendarIcon}
        errors={errors}
        focussed={focused}
        disabled={disabled}
      >
        <CardExpiryElement
          options={{
            disabled,
            style: {
              base: {
                lineHeight: "3.5rem",
                // fontFamily: "figtree",
                fontSize: "16px",
                color: "#3d464d",
                fontWeight: 400,
                // "::placeholder": ""
                "::placeholder": {
                  color: "#9ca3af",
                },
              },
            },
          }}
          {...elementProps}
        />
      </TextInputContainer>
    </>
  );
};

const CardCvcInput = ({ disabled }: { disabled: boolean }) => {
  const { focused, errors, ...elementProps } = useStripeStatus(2);
  return (
    <>
      <TextInputContainer
        outmostClassName="basis-2/5"
        Icon={PadlockIcon}
        errors={errors}
        focussed={focused}
        disabled={disabled}
      >
        <CardCvcElement
          options={{
            disabled,
            style: {
              base: {
                lineHeight: "3.5rem",
                // fontFamily: "figtree",
                fontSize: "16px",
                fontWeight: 400,
                color: "rgb(30, 30, 30)",
                "::placeholder": {
                  color: "#9ca3af",
                },
              },
            },
          }}
          {...elementProps}
        />
      </TextInputContainer>
    </>
  );
};

function getSubtotalString(currencyChar: string, value: number) {
  if (!value) return "";
  return currencyChar + (value / 100).toFixed(2);
}

type OrderExpectedPaymentValue = {
  expectedPaymentValue: number;
};

const sendPayment = async (
  payload:
    | {
        paymentIntentId: string;
        orderId: number;
        paymentCurrency: string;
        expectedPaymentValue: number;
      }
    | {
        externalPaymentMethodId: string;
        orderId: number;
        paymentCurrency: string;
        expectedPaymentValue: number;
        groupOrders?: Record<number, OrderExpectedPaymentValue>;
        privateOrders?: Record<number, OrderExpectedPaymentValue>;
        equipmentOrders?: Record<number, OrderExpectedPaymentValue>;
      }
) => {
  try {
    // await zodios.setCSRFTokenIntoTheSession();

    console.log("sendPayment inputtt:", payload);

    const response = await zodios.createPayment({ ...payload });

    if (response?.errors && response?.errors?.length > 0) {
      return {
        data: undefined,
        error: response?.errors
          ?.map((err) => ({
            message: err?.message,
            nextAction: err?.extensions?.context?.next_action,
          }))
          ?.at(0),
      };
    }

    return { data: response, error: undefined };
  } catch (error) {
    return { data: undefined, error: error?.response?.data ?? {} };
  }
};

export const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const { t } = useTranslation<unknown>();

  const orderData = useAsyncValue();

  const [elementProgress, setElementProgress] = useState<boolean[]>(
    new Array(3).fill(false)
  );

  const stripeIsComplete = useMemo(
    () => elementProgress.every((val) => val === true),
    [elementProgress]
  );

  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<Response | null>(null);

  const {
    control,
    watch,
    formState: { isValid },
    register,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      // paymentCurrency: "GBP",
      name: "",
      address: {
        country: "",
        postal_code: "",
      },
      agreeToTerms: false,
    },
    mode: "onChange",
  });

  const handleError = useCallback(
    (error) => {
      setIsProcessing(false);
      console.error(error);
    },
    [setIsProcessing]
  );

  const handleServerResponse = async ({
    data,
    error,
  }: {
    data?: unknown;
    error?: unknown;
  }) => {
    console.log("handleServerResponse:", { data, error });
    if (error) {
      const { nextAction, clientSecret } = error?.context ?? {};

      console.log("error.extensions.context:", { nextAction, clientSecret });

      if (nextAction?.type === "use_stripe_sdk" && clientSecret) {
        const { paymentIntent, error: stripeError } =
          await stripe!.handleNextAction({
            clientSecret,
          });

        if (stripeError) {
          throw stripeError;
        }

        const groupOrderItem = orderData?.groupOrders?.at(0);
        const privateOrderItem = orderData?.privateOrders?.at(0);

        const response = await sendPayment({
          paymentIntentId: paymentIntent!.id,
          paymentCurrency:
            groupOrderItem?.resort?.currency?.code ??
            privateOrderItem?.resort?.currency?.code,
          orderId: orderData.id,
          expectedPaymentValue: orderData?.amountDue?.amount,
        });
        await handleServerResponse(response);
      } else throw error;
    } else {
      return;
    }
  };

  const navigate = useNavigate();

  const onSubmit = handleSubmit(async (formValues) => {
    const createdAtDate = new Date(orderData.createdAt! as string);

    const expirationDate = new Date(
      createdAtDate.getTime() + 1000 * 60 * 60 * 72
    );

    if (Date.now() >= expirationDate.getTime())
      return setError(
        new Response("Payment link has expired", {
          status: 400,
          statusText: "Payment link has expired",
        })
      );

    if (!stripe || !elements || !isValid || isProcessing || !stripeIsComplete) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    // await new Promise((resolve) => setTimeout(resolve, 3000));
    try {
      const { error: elementsError } = await elements.submit();
      if (elementsError) {
        throw elementsError;
      }

      // const { agreeToTerms, ...billing_details } = formValues;
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement)!,
        billing_details: {
          name: formValues.name,
          address: formValues.address,
        },
      });

      if (error) {
        throw error;
      }

      const groupOrderItem = orderData?.groupOrders?.at(0);
      const privateOrderItem = orderData?.privateOrders?.at(0);

      const response = await sendPayment({
        externalPaymentMethodId: paymentMethod.id,
        paymentCurrency:
          groupOrderItem?.resort?.currency?.code ??
          privateOrderItem?.resort?.currency?.code,
        orderId: orderData.id,
        expectedPaymentValue: orderData?.amountDue?.amount,
      });
      await handleServerResponse(response);

      const currencyChar =
        orderData?.groupOrders?.at(0)?.resort?.currency?.symbol ??
        orderData?.privateOrders?.at(0)?.resort?.currency?.symbol;

      totals = {
        group: getSubtotalString(
          currencyChar,
          orderData?.groupOrders?.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue?.amountDue?.amount,
            0
          )
        ),
        private: getSubtotalString(
          currencyChar,
          orderData?.privateOrders?.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue?.amountDue?.amount,
            0
          )
        ),
        final: getSubtotalString(currencyChar, orderData?.amountDue?.amount),
      };
      transactionDetails = {
        date: new Date(),
        brand: paymentMethod?.card?.brand,
        last4: paymentMethod?.card?.last4,
      };

      navigate("/confirmed", {
        replace: true,
      });
    } catch (error) {
      handleError(error);
    }
  });

  const { onChange: nameOnChange, ...nameRegister } = register("name", {
    required: true,
  });

  const { onChange: countryOnChange, ...countryCodeRegister } = register(
    "address.country",
    { required: true }
  );
  const { onChange: postalCodeOnChange, ...postalCodeRegister } = register(
    "address.postal_code",
    {
      required: true,
    }
  );

  if (error) throw error;

  return (
    <>
      <form
        className="flex flex-col gap-6"
        onSubmit={onSubmit}
      >
        {/* <SelectBox
          options={[
            { label: t("PaymentFormInput.PaymentCurrency[GBP]"), value: "GBP" },
            { label: t("PaymentFormInput.PaymentCurrency[EUR]"), value: "EUR" },
            { label: t("PaymentFormInput.PaymentCurrency[CHF]"), value: "CHF" },
          ]}
          Icon={
            watch("paymentCurrency") === "GBP"
              ? CurrencyIcon.Pound
              : CurrencyIcon.Euro
          }
          placeholder={t("PaymentFormInput.PaymentCurrency.Placeholder")!}
          value={watch("paymentCurrency")}
          onSelect={({ value }) => setValue("paymentCurrency", value)}
          disabled={isProcessing}
        /> */}

        <div className="flex flex-col gap-2">
          <TextInput
            Icon={ProfileIcon}
            className="gap-1"
            autocomplete="off"
            placeholder={t("PaymentFormInput.Name.Placeholder")!}
            disabled={isProcessing}
            onChange={(value) =>
              nameOnChange({
                target: {
                  value,
                },
              })
            }
            {...nameRegister}
          />

          <div className="flex gap-2">
            <SelectBox
              options={countryOptions}
              Icon={LocationDropperIcon}
              className="basis-3/5"
              placeholder={t("PaymentFormInput.Country.Placeholder")!}
              value={watch("address.country")}
              onSelect={({ value }) => setValue("address.country", value)}
              disabled={isProcessing}
              {...countryCodeRegister}
            />

            <TextInput
              Icon={HouseIcon}
              outmostClassName="basis-2/5"
              placeholder={t("PaymentFormInput.PostalCode.Placeholder")!}
              disabled={isProcessing}
              onChange={(value) =>
                postalCodeOnChange({
                  target: {
                    value,
                  },
                })
              }
              {...postalCodeRegister}
            />
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <StripeProgressContext.Provider
            value={{
              progress: elementProgress,
              updateProgress: setElementProgress,
            }}
          >
            <CardNumberInput disabled={isProcessing} />

            <div className="flex gap-2">
              <CardExpiryInput disabled={isProcessing} />

              <CardCvcInput disabled={isProcessing} />
            </div>
          </StripeProgressContext.Provider>
        </div>

        <Controller
          control={control}
          name="agreeToTerms"
          rules={{ required: true }}
          render={({ field }) => (
            <Checkbox
              label={
                <Trans
                  i18nKey="PaymentFormInput.Terms.Label"
                  components={[
                    <a
                      className="text-ms-primary hover:text-ms-primary/75 "
                      href={`https://maisonsport.com/${i18n.language}/terms`}
                      target="_blank"
                    />,
                    <a
                      className="text-ms-primary hover:text-ms-primary/75"
                      href={`https://maisonsport.com/${i18n.language}/terms/cancellation-policies`}
                      target="_blank"
                    />,
                  ]}
                />
              }
              className="font-normal"
              checked={field.value}
              onChange={(checked) => field.onChange(checked)}
              disabled={isProcessing}
            />
          )}
        />

        <Button
          className="w-full"
          disabled={!stripe || !isValid || isProcessing || !stripeIsComplete}
          type="submit"
          onClick={onSubmit}
          onSubmit={onSubmit}
        >
          {isProcessing && (
            <LoadingSpinnerIcon className=" inline-block h-auto mr-3 mb-[4px] stroke-white animate-spin" />
          )}
          {t("PaymentForm.Submit")}
        </Button>
      </form>
    </>
  );
};

function getStartToEndString(start: string, end: string) {
  try {
    const { i18n } = useTranslation<unknown>();

    const startDate = new Date(start);
    const endDate = new Date(end);

    return (
      startDate.toLocaleTimeString(i18n.language, {
        hour: "numeric",
        minute: "2-digit",
      }) +
      " - " +
      endDate.toLocaleTimeString(i18n.language, {
        hour: "numeric",
        minute: "2-digit",
      }) +
      " " +
      startDate.toLocaleDateString(i18n.language, {
        month: "short",
        day: "numeric",
      }) +
      (startDate.toDateString() !== endDate.toDateString()
        ? " - " +
          endDate.toLocaleDateString(i18n.language, {
            month: "short",
            day: "numeric",
          })
        : "")
    );
  } catch (error) {
    return "";
  }
}

export const GroupLessonSummary = () => {
  const orderData = useAsyncValue() as TPaymentPageLoaderData;
  const { t } = useTranslation<unknown>();

  const groupLessonSubtotal = useMemo(() => {
    if (orderData.groupOrders?.length === 0) return "";

    const currencyChar =
      orderData.groupOrders?.at(0)?.amountDue?.currency?.symbol;
    const sum = orderData.groupOrders?.reduce(
      (accumulator: number, currentValue: unknown) =>
        accumulator + currentValue.amountDue.amount,
      0
    );

    return currencyChar + (sum / 100).toFixed(2);
  }, []);

  if (orderData.groupOrders?.length === 0) return null;

  return (
    <>
      <Card.Root className="bg-white">
        <Card.Header className="gap-0">
          <Card.Title
            className="text-left"
            variant="title3"
          >
            {t("PaymentSummary.Group.Heading")}
          </Card.Title>
          <Card.Subtitle className="text-left">
            <Text
              className=" text-lg"
              variant="cabron"
            >
              {t("PaymentSummary.Group.Subheading")}
            </Text>
          </Card.Subtitle>
        </Card.Header>
        <Card.Content>
          <ul className="flex flex-col gap-3 text-lg">
            {orderData?.groupOrders?.map((orderItem, index) => (
              <Fragment key={index}>
                <li>
                  <Text
                    className="font-bold block"
                    variant="cabron"
                  >
                    {t(`Supreme.GroupType[${orderItem?.groupType}]`)} -{" "}
                    {t(`Supreme.AbilityLevel[${orderItem?.abilityLevel}]`)}
                  </Text>
                  <div className="flex justify-between">
                    <Text
                      className="font-normal"
                      variant="cabron"
                    >
                      {getStartToEndString(orderItem?.start, orderItem?.end)}
                    </Text>

                    <Text
                      className=" align-text-bottom"
                      variant="cabron"
                    >
                      {orderItem?.amountDue?.text}
                    </Text>
                  </div>
                </li>
              </Fragment>
            ))}

            <li className="flex justify-between pt-4">
              <Text
                className="font-bold"
                variant="cabron"
              >
                {t("PaymentSummary.Subtotal")}
              </Text>
              <Text
                className="font-bold"
                variant="cabron"
              >
                {groupLessonSubtotal}
              </Text>
            </li>
          </ul>
        </Card.Content>
      </Card.Root>
    </>
  );
};

const PrivateLessonSummary = () => {
  const orderData = useAsyncValue() as TPaymentPageLoaderData;
  const { t, i18n } = useTranslation<unknown>();

  const currencyChar = useMemo(
    () => orderData?.privateOrders?.at(0)?.resort?.currency?.symbol,
    []
  );

  const slotsByDay = useMemo(() => {
    if (orderData.privateOrders?.length === 0) return [];

    return Object.entries(
      Object.groupBy(
        orderData.privateOrders?.at(0).slots,
        (item) => item.calendarDate
      )
    );
  }, [i18n.language]);

  if (
    orderData.privateOrders?.length === 0 ||
    orderData.privateOrders?.at(0).instructorProfileId === 0
  )
    return null;

  return (
    <Card.Root className="bg-white">
      <Card.Header className="gap-0">
        <Card.Title
          className="text-left"
          variant="title3"
        >
          {t("PaymentSummary.Private.Heading")}
        </Card.Title>
        <Card.Subtitle className="text-left">
          <Text
            className=" text-lg"
            variant="cabron"
          >
            {orderData.privateOrders?.at(0)?.sport?.name}
          </Text>
        </Card.Subtitle>
      </Card.Header>
      <Card.Content>
        <ul className="flex flex-col gap-3 text-lg">
          {slotsByDay.map(([date, slots], index) => (
            <Fragment key={index}>
              <li>
                <Text
                  className="font-bold block"
                  variant="cabron"
                >
                  {new Date(date).toLocaleDateString(i18n.language, {
                    month: "short",
                    day: "numeric",
                  })}
                </Text>
                <ul>
                  {slots.map((slot, index) => (
                    <Fragment key={index}>
                      <li className="flex justify-between">
                        <Text
                          className="font-normal"
                          variant="cabron"
                        >
                          {slot.isFullDay
                            ? t("PaymentSummary.Private.IsFullDay")
                            : getStartToEndString(
                                slot?.startDatetime,
                                slot?.endDatetime
                              )}
                        </Text>

                        <Text
                          className=" align-text-bottom"
                          variant="cabron"
                        >
                          {currencyChar}
                          {(slot?.price / 100).toFixed(2)}
                        </Text>
                      </li>
                    </Fragment>
                  ))}
                </ul>
              </li>
            </Fragment>
          ))}

          <li className="flex justify-between">
            <Text variant="cabron">
              {t("PaymentSummary.Private.MaisonSportFees")}
            </Text>
            <Text variant="cabron">
              {orderData.privateOrders?.at(0).customerMsFees.text}
            </Text>
          </li>

          <li className="flex justify-between">
            <Text variant="cabron">
              {t("PaymentSummary.Private.TransactionFees")}
            </Text>
            <Text variant="cabron">
              {orderData.privateOrders?.at(0).customerPaymentFees.text}
            </Text>
          </li>

          <li className="flex justify-between pt-4">
            <Text
              className="font-bold"
              variant="cabron"
            >
              {t("PaymentSummary.Subtotal")}
            </Text>
            <Text
              className="font-bold"
              variant="cabron"
            >
              {orderData.privateOrders?.at(0).amountDue.text}
            </Text>
          </li>
        </ul>
      </Card.Content>
    </Card.Root>
  );
};

export const PaymentSuccessfulStatus = () => {
  return <></>;
};

function expirationDateString(createdAt: string) {
  if (!createdAt) return "";
  const createdAtDate = new Date(createdAt! as string);

  const expirationDate = new Date(
    createdAtDate.getTime() + 1000 * 60 * 60 * 72
  );

  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    expirationDate.toLocaleDateString(i18n.language, {
      timeZone: currentTimeZone,
    }) +
    " " +
    expirationDate.toLocaleTimeString(i18n.language, {
      timeZone: currentTimeZone,
    })
  );
}

let totals: {
  group: string;
  private: string;
  final: string;
} | null = null;

let transactionDetails: {
  date: Date;
  brand: string;
  last4: string;
} | null = null;

const ConfirmationPage = () => {
  const { t, i18n } = useTranslation<unknown>();

  return (
    <main className="flex justify-center p-6 fixed md:overflow-clip overflow-y-scroll top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-ms-success-60 to-stone-100">
      <div className="flex flex-col gap-10 lg:flex-row w-full  md:max-w-screen-xl pt-12">
        <div className="flex flex-col gap-10 xl:basis-3/5 lg:basis-1/2 relative lg:min-h-[32rem] md:max-h-[65vh] items-center">
          {/* <div className="flex flex-1" /> */}

          <Illustrations
            illustration={"HighFive"}
            className={"w-[28rem] block"}
          />

          <Text variant="title1">{t("ConfirmationPage.Heading")}</Text>
          <Text
            className="text-2xl"
            as="p"
          >
            {t("ConfirmationPage.Subheading")}
          </Text>
        </div>

        <div className="xl:basis-2/5 lg:basis-1/2 relative lg:pt-24">
          <Card.Root className="shadow-lg flex sticky left-0 right-0 top-0 bg-white h-min">
            <Card.Header className="pt-10">
              <Card.Title
                className="text-left"
                variant="title3"
                // as="h3"
              >
                {t("ConfirmationSummary.Heading")}
              </Card.Title>
              {/* <Card.Subtitle className="text-left"></Card.Subtitle> */}
            </Card.Header>
            <Card.Content>
              <ul className="flex flex-col gap-3">
                {totals?.group && (
                  <li className="flex justify-between">
                    <Text variant="cabron">
                      {t("ConfirmationSummary.GroupTotal")}
                    </Text>
                    <Text variant="cabron">{totals?.group}</Text>
                  </li>
                )}

                {totals?.private && (
                  <li className="flex justify-between">
                    <Text variant="cabron">
                      {t("ConfirmationSummary.PrivateTotal")}
                    </Text>
                    <Text variant="cabron">{totals?.private}</Text>
                  </li>
                )}

                {totals?.final && (
                  <li className="flex justify-between pt-6">
                    <Text
                      className="font-bold"
                      variant="cabron"
                    >
                      {t("ConfirmationSummary.Total")}
                    </Text>
                    <Text variant="cabron">{totals?.final}</Text>
                  </li>
                )}
              </ul>
            </Card.Content>

            <Card.Header className="pt-0">
              <Card.Title
                className="text-left"
                variant="title3"
                // as="h3"
              >
                {t("ConfirmationSummary.Transaction.Heading")}
              </Card.Title>
              {/* <Card.Subtitle className="text-left"></Card.Subtitle> */}
            </Card.Header>
            <Card.Content>
              <Table>
                <Table.Header>
                  <Table.Row className="text-base! font-normal!">
                    <Table.Head className={"pl-0"}>
                      {t("ConfirmationSummary.Transaction.Date")}
                    </Table.Head>
                    <Table.Head>
                      {t("ConfirmationSummary.Transaction.Amount")}
                    </Table.Head>
                    <Table.Head>
                      {t("ConfirmationSummary.Transaction.Card")}
                    </Table.Head>
                    <Table.Head className="text-right">
                      {t("ConfirmationSummary.Transaction.Status")}
                    </Table.Head>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell className={"pl-0"}>
                      {transactionDetails?.date?.toLocaleDateString(
                        i18n.language,
                        {
                          month: "short",
                          day: "numeric",
                        }
                      ) ?? ""}
                    </Table.Cell>
                    <Table.Cell>{totals?.final}</Table.Cell>
                    <Table.Cell className={"flex items-center gap-2"}>
                      <PaymentProviderIcon
                        className="h-8"
                        provider={transactionDetails?.brand ?? ""}
                      />
                      {t("ConfirmationSummary.CardEndingIn", {
                        last4: transactionDetails?.last4,
                      })}
                    </Table.Cell>
                    <Table.Cell className="text-right text-ms-success">
                      {t("ConfirmationSummary.TransactionStatus[paid]")}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Card.Content>
          </Card.Root>
        </div>
      </div>
    </main>
  );
};

function confirmationPageLoader() {
  if (transactionDetails === null || totals === null) {
    return redirect("/404");
  }

  return new Response("Ok", { status: 200 });
}

const languages = [
  // {
  //     img: '',
  //     language: 'text.general.any',
  //     code: '',
  // },
  {
    img: "https://flagicons.lipis.dev/flags/1x1/nl.svg",
    language: "languages.nl",
    code: "nl",
  },
  {
    img: "https://flagicons.lipis.dev/flags/1x1/de.svg",
    language: "languages.de",
    code: "de",
  },
  {
    img: "https://flagicons.lipis.dev/flags/1x1/it.svg",
    language: "languages.it",
    code: "it",
  },
  {
    img: "https://flagicons.lipis.dev/flags/1x1/fr.svg",
    language: "languages.fr",
    code: "fr",
  },
  {
    img: "https://flagicons.lipis.dev/flags/1x1/gb.svg",
    language: "languages.en",
    code: "en",
  },
];

const languageFlagUrls = {
  en: "https://flagicons.lipis.dev/flags/1x1/gb.svg",
  fr: "https://flagicons.lipis.dev/flags/1x1/gb.svg",
  it: "https://flagicons.lipis.dev/flags/1x1/it.svg",
  de: "https://flagicons.lipis.dev/flags/1x1/de.svg",
  nl: "https://flagicons.lipis.dev/flags/1x1/nl.svg",
};

export const PaymentPage = () => {
  const data = useLoaderData() as TPaymentPageLoaderData;
  const { t, i18n } = useTranslation<unknown>();
  const location = useLocation();

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Await
        resolve={data?.orderData}
        errorElement={<ErrorPage />}
      >
        {(orderData) => {
          const createdAtDate = new Date(orderData.createdAt! as string);

          const expirationDate = new Date(
            createdAtDate.getTime() + 1000 * 60 * 60 * 72
          );

          if (Date.now() >= expirationDate.getTime())
            throw new Response("Payment link has expired", {
              status: 400,
              statusText: "Payment link has expired",
            });

          return (
            <main className="flex justify-center p-6 fixed md:overflow-clip overflow-y-scroll top-0 left-0 right-0 bottom-0 bg-stone-100 ">
              <div className="md:max-w-screen-xl w-full pt-6 h-fit">
                <div className="flex justify-between items-center">
                  <Text
                    className="pb-4"
                    variant="title1"
                  >
                    {t("Page.Heading")}
                  </Text>

                  <LanguagePicker
                    items={languages
                      .filter((lang) => Boolean(lang.code))
                      .map(({ code, img }) => ({
                        label: t(`LanguageLabel[${code}]`),
                        Icon: (
                          <img
                            className="h-full w-auto"
                            src={img}
                            height={20}
                            width={20}
                            alt={code}
                          />
                        ),
                        code,
                      }))}
                    currentLocale={i18n.language}
                    currentPath={location.pathname}
                  />
                </div>

                <Text
                  className="pb-4 font-normal"
                  variant="sub1"
                  as="p"
                >
                  {t("Page.Subheading")}{" "}
                  {expirationDateString(orderData.createdAt)}
                </Text>

                <div className="flex flex-col gap-10 md:flex-row ">
                  <div className="flex flex-col gap-5 xl:basis-3/5 md:basis-1/2 relative lg:min-h-[32rem] lg:max-h-[65vh] md:overflow-y-scroll">
                    <GroupLessonSummary />

                    <PrivateLessonSummary />

                    <div className="md:sticky bottom-0 left-0 right-0 md:pt-12 pt-6 bg-gradient-to-b from-transparent to-stone-100 to-80%  flex flex-col font-bold text-xl">
                      <div className="flex justify-between px-6 pt-6">
                        <Text variant="cabron">
                          {t("PaymentSummary.FinalTotal")}
                        </Text>

                        <Text variant="cabron">{orderData.amountDue.text}</Text>
                      </div>
                    </div>
                  </div>

                  <div className="xl:basis-2/5 md:basis-1/2 relative">
                    <Card.Root className="shadow-lg flex sticky left-0 right-0 top-0 bg-white h-min">
                      <Card.Header className="pt-10">
                        <Card.Title
                          className="text-left"
                          variant="title2"
                          as="h2"
                        >
                          {t("PaymentForm.Heading")}
                        </Card.Title>
                        <Card.Subtitle className="text-left">
                          {/* {t("PaymentForm.Subheading")} */}
                        </Card.Subtitle>
                      </Card.Header>
                      <Card.Content>
                        <Elements
                          stripe={stripePromise}
                          options={{
                            ...stripeOptions,
                            locale: i18n.language as StripeElementLocale,
                          }}
                        >
                          <PaymentForm />
                        </Elements>
                      </Card.Content>
                    </Card.Root>

                    <div className="px-6 py-2 flex justify-end text-sm items-center leading-none">
                      {t("PaymentForm.PoweredByStripe")}

                      <a
                        href="https://stripe.com"
                        target="_blank"
                      >
                        <img
                          // class="Picture__image "
                          className="inline w-auto h-7"
                          src="https://images.ctfassets.net/fzn2n1nzq965/6XFEUA9FzMBMphYdcUab19/37a1e07201366a351f7956560ccac09d/Stripe_wordmark_-_slate.svg?q=80&amp;w=28"
                          alt=""
                          width="468"
                          height="223"
                          loading="lazy"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          );
        }}
      </Await>
    </Suspense>
  );
};

function paymentPageLoader({ request }: LoaderFunctionArgs) {
  const currentUrl = new URL(request.url);
  const orderId = currentUrl.searchParams.get("orderId");
  const locale = currentUrl.searchParams.get("locale");

  if (!orderId) return redirect("/404");

  try {
    const orderData = zodios.unauthedGetOrder({
      params: { routeKey: orderId },
      queries: {
        // withs: ["groupOrders.details"],
        locale,
      },
    });

    return defer({ orderId: +orderId, orderData });
  } catch (error) {
    if (error instanceof Response) throw error;
    throw new Response("Not found", { status: 404, statusText: "Not found" });
  }
}

type TPaymentPageLoaderData = ReturnType<typeof paymentPageLoader>;

Sentry.init({
  dsn: "https://afef6457e886b5dc3c225bd0ae9c2a16@o4505283314057216.ingest.sentry.io/4506070518923264",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    // import.meta.env.DEV ? null : new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate:
    import.meta.env.VITE_APP_SENTRY_ENV === "production" ? 0.5 : 0, // Capture 100% of the transactions
  environment: import.meta.env.VITE_APP_SENTRY_ENV,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    element: <PaymentPage />,
    path: "/",
    errorElement: <ErrorPage />,
    loader: paymentPageLoader,
  },
  {
    element: <ConfirmationPage />,
    path: "/confirmed",
    errorElement: <ErrorPage />,
    loader: confirmationPageLoader,
  },
  {
    path: "404",
    element: <NotFoundPage />,
  },
  {
    path: "*",
    loader: () => redirect("/404"),
  },
]);

const Link = ({
  locale,
  href,
  onClick,
  ...props
}: LinkHTMLAttributes<HTMLAnchorElement> & { locale?: string }) => {
  const { i18n } = useTranslation<unknown>();
  const location = useLocation();
  const [_, setSearchParams] = useSearchParams();
  const revalidator = useRevalidator();

  const handleClick = () => {
    if (!locale) return;

    console.log(
      location.search.replace(/locale=\w+(&|$)/g, `locale=${locale}`) ||
        `locale=${locale}`
    );

    i18n.changeLanguage(locale);

    // console.log(searchParams.has(locale));
    setSearchParams(
      (params) => {
        params.set("locale", locale);
        return params;
      },
      {
        replace: true,
      }
    );

    // revalidator.revalidate();
  };

  return (
    <button
      // href={parsedHref}
      onClick={handleClick}
      {...(props as HTMLAttributes<HTMLButtonElement>)}
    />
  );
};

const App = () => {
  const { t } = useTranslation<unknown>();

  return (
    <EnvironmentContext.Provider value={{ Link, t }}>
      <RouterProvider router={router} />
    </EnvironmentContext.Provider>
  );
};

render(
  <StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </StrictMode>,
  document.getElementById("root")
);
