import { cva, VariantProps } from "class-variance-authority";
import { HTMLAttributes } from "react";

export const useIconStyles = cva([], {
  variants: {
    size: {
      mini: "h-2 w-2",
      xs: "h-3 w-3",
      sm: "h-4 w-4",
      md: "h-6 w-6",
      lg: "h-8 w-8",
      xl: "h-16 w-16",
      "2xl": "h-20 w-20",
      "3xl": "h-28 w-28",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

export type IconVariantProps = VariantProps<typeof useIconStyles>;

export type IconProps<T = HTMLAttributes<HTMLOrSVGImageElement>> =
  IconVariantProps & T;
