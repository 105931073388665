import { createContext, FC, ReactNode, useContext } from "react";
import { I18n, TFunction } from "next-i18next";
import { LinkProps } from "../components/core/Link";

export const EnvironmentContext = createContext<{
  Link: FC<
    {
      className?: string;
      href: string | Record<string, unknown>;
      children: ReactNode;
    } & LinkProps & {}
  >;
  t: TFunction;
  i18n: I18n;
  Image: FC<
    {
      className?: string;
      src: string;
      alt: string;
    } & {}
  >;
}>({
  Link: () => null,
  t: (key) => key,
  i18n: {},
  Image: () => null,
});

export const useEnvironmentContext = () => {
  const context = useContext(EnvironmentContext);

  if (!context)
    throw Error(
      "Must be a child of EnvironmentProvider: Define Provider at root of application.",
    );

  return context;
};
