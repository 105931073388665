import { cn } from "../../../lib/utils";
import { HTMLTableSectionAttributes } from "../../../types";

export const TableCaption = ({
  className,
  ...props
}: HTMLTableSectionAttributes) => (
  <caption
    className={cn("mt-4 text-sm text-muted-foreground", className)}
    {...props}
  />
);
TableCaption.displayName = "Table.Caption";
