import { cn } from "../../lib/utils";
import { HTMLInputAttributes, Union } from "../../types";
import { Text, TextLabelProps } from "../core/Text";
import { CheckIcon } from "../icons";
import { ErrorList, Props as ErrorListProps } from "./shared/ErrorList";
import * as RadixCheckbox from "@radix-ui/react-checkbox";
import { cva, VariantProps } from "class-variance-authority";

const Label = ({ children }: TextLabelProps) => {
  return (
    <Text.Label
      variant={"cabron"}
      className={"flex flex-row items-center gap-2"}
    >
      {children}
    </Text.Label>
  );
};

const checkboxSize = "w-4 h-4";
const checkboxCheckSize = "w-3 h-3";
const useStyles = cva(
  [
    "min-w-[1rem] min-h-[1rem] w-4 h-4 aspect-square",
    "appearance-none rounded-sm border border-black",
    "disabled:bg-ms-grey disabled:pointer-none disabled:opacity-75",
  ],
  {
    variants: {
      error: {
        true: ["border-ms-error", "drop-shadow"],
      },
      checked: {
        true: ["bg-ms-success"],
      },
    },
  },
);
type StyleProps = VariantProps<typeof useStyles>;
export type CheckboxProps = Union<
  StyleProps &
    Pick<HTMLInputAttributes, "className"> & {
      label: ReactNode;
      checked?: boolean;
      required?: boolean;
      disabled?: boolean;
      onChange?: (checked: boolean) => void;
    } & Pick<ErrorListProps, "errors">
>;
export const Checkbox = ({
  className,
  checked,
  onChange,
  label,
  error: _error,
  errors,
  disabled,
  ...styleProps
}: CheckboxProps) => {
  const hasErrorMessages = !!errors || !!errors?.length;
  return (
    <div className={"flex flex-col gap-2"}>
      <Text.Label
        variant={"cabron"}
        className={cn(
          "flex min-h-[1rem] flex-row items-start gap-2",
          className,
        )}
        disabled={disabled}
      >
        <RadixCheckbox.Root
          className={cn(
            useStyles({
              ...styleProps,
              error: _error || hasErrorMessages,
              checked,
            }),
            className,
          )}
          checked={checked}
          onCheckedChange={onChange}
          disabled={disabled}
        >
          <RadixCheckbox.Indicator>
            <CheckIcon className={cn(checkboxCheckSize, "fill-white")} />
          </RadixCheckbox.Indicator>
        </RadixCheckbox.Root>
        {label}
      </Text.Label>
      <ErrorList errors={errors} />
    </div>
  );
};
Checkbox.displayName = "Checkbox";
