import { useIconStyles, type IconProps } from "../../useIconStyles";
import { HTMLAttributes } from "react";
import { cn } from "../../../../lib/utils";

const Amex = ({
  className,
  size,
}: IconProps<HTMLAttributes<HTMLOrSVGImageElement>>) => {
  return (
    <svg
      className={cn(useIconStyles({ size }), className)}
      viewBox="0 0 256 256"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>American Express</title>
      <path
        d="M0,0 L0,256 L256,256 L256,215.552 L220.672,215.552 L207.616,200.27904 L194.048,215.552 L93.696,215.552 L93.696,134.23104 L60.58496,134.23104 L101.97504,40.53504 L142.24896,40.53504 L151.97696,61.78304 L151.97696,40.53504 L201.984,40.53504 L210.34496,62.80704 L218.53696,40.53504 L256,40.53504 L256,0 L0,0 Z M227.072,53.76 L213.92896,88.40704 L210.432,97.79712 L206.848,88.40704 L193.62304,53.76 L164.69504,53.76 L164.69504,122.03008 L182.10304,122.03008 L182.10304,77.57312 L182.016,68.608 L185.43104,77.57312 L202.07104,122.03008 L218.624,122.03008 L235.35104,77.57312 L238.592,68.69504 L238.592,122.03008 L256,122.03008 L256,53.76 L227.072,53.76 Z M111.36,53.76 L81.152,122.03008 L100.94592,122.03008 L106.24,108.88704 L139.35104,108.88704 L144.64,122.03008 L164.69504,122.03008 L134.656,53.76 L111.36,53.76 Z M119.37792,76.88704 L122.79296,68.35712 L126.208,76.88704 L133.28896,94.12096 L112.29696,94.12096 L119.37792,76.88704 Z M228.43904,134.31808 L207.872,156.416 L187.392,134.31808 L164.69504,134.31808 L107.68896,134.31808 L107.68896,202.32704 L164.69504,202.32704 L164.69504,187.56608 L125.09696,187.56608 L125.09696,175.70304 L163.92704,175.70304 L163.92704,161.02912 L125.09696,161.02912 L125.09696,149.07904 L164.69504,149.07904 L164.69504,134.31808 L196.52096,168.448 L164.69504,202.32704 L186.71104,202.32704 L207.44704,180.14208 L228.096,202.32704 L250.70592,202.32704 L218.79296,168.192 L250.70592,134.31808 L228.43904,134.31808 Z M236.71296,168.11008 L256,187.73504 L256,148.736 L236.71296,168.11008 Z"
        fill="inherit"
      ></path>
    </svg>
  );
};

export default Amex;
