import { useEnvironmentContext } from "../../../lib/environment";

const ErrMsg = ({ children }: { children: string }) => (
  <li className={"text-xs text-ms-error"}>{children}</li>
);

export type Props = {
  errors?: string | string[];
};
export const ErrorList = ({ errors }: Props) => {
  const { t } = useEnvironmentContext();
  const _errors: string[] = (Array.isArray(errors) ? errors : [errors!]).filter(
    Boolean,
  );

  if (!_errors.length) return null;

  return (
    <ul className={"ml-4 list-disc text-left"}>
      {_errors.map((eM, i) => (
        <ErrMsg key={`${eM}_${i}`}>{t(eM)}</ErrMsg>
      ))}
    </ul>
  );
};
